import { Controller } from "@hotwired/stimulus";

const MAX_BREADCRUMBS = 3;

/**
 * Controller will handle truncating breadcrumbs when the number exceeds the MAX_BREADCRUMBS threshold.
 * To use, add the data-controller="breadcrumbs" and data-breadcrumbs-target="breadcrumb" attributes to the
 * element that contains the elements with breadcrumb-item class.
 */
export default class extends Controller {
  static targets = ["breadcrumb"];
  static values = {
    maxBreadcrumbs: {
      type: Number,
      default: MAX_BREADCRUMBS,
    },
    itemSelector: {
      type: String,
      default: ".breadcrumb-item",
    },
  };

  get breadcrumbCount() {
    return this.breadcrumbTarget.querySelectorAll(this.itemSelectorValue).length;
  }

  connect() {
    if (this.breadcrumbCount > this.maxBreadcrumbsValue) {
      const bcHideElements = this.breadcrumbTarget.querySelectorAll(".bc-hide");
      const bcDropdownElements = this.breadcrumbTarget.querySelectorAll(".bc-dropdown");

      bcHideElements.forEach(function (element) {
        element.style.display = "none";
      });

      bcDropdownElements.forEach(function (element) {
        element.style.display = "flex";
      });
    } else {
      const bcHideElements = this.breadcrumbTarget.querySelectorAll(".bc-hide");
      const bcDropdownElements = this.breadcrumbTarget.querySelectorAll(".bc-dropdown");

      bcHideElements.forEach(function (element) {
        element.style.display = "flex";
      });

      bcDropdownElements.forEach(function (element) {
        element.style.display = "none";
      });
    }
  }
}
