import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["container"];

  static values = {
    dismissUrl: String,
  };

  async close() {
    if (this.hasDismissUrlValue) {
      await fetch(this.dismissUrlValue, {
        method: "PUT",
        credentials: "same-origin",
        headers: { "X-CSRF-Token": Rails.csrfToken() },
      });
    }

    this.containerTarget.remove();
  }
}
