import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader", "content", "frame"];

  frameTargetConnected(e) {
    let observer = new MutationObserver(this.frameMutated.bind(this));
    observer.observe(e, {
      attributes: true,
      childList: false,
      characterData: false,
    });
  }

  frameMutated() {
    if (this.frameTarget.hasAttribute("busy")) {
      if (this.hasContentTarget) {
        this.contentTargets.forEach((e) => e.classList.add("loading"));
      }
      if (this.hasLoaderTarget) {
        this.loaderTargets.forEach((e) => e.classList.remove("d-none"));
      }
    } else {
      if (this.hasLoaderTarget) {
        this.loaderTargets.forEach((e) => e.classList.add("d-none"));
      }
      if (this.hasContentTarget) {
        this.contentTargets.forEach((e) => e.classList.remove("loading"));
      }
    }
  }
}
