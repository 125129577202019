import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "container", "input"];
  static outlets = ["previewable-form"];
  static values = { type: String };

  get dateBinSelectEl() {
    return document.querySelector("select[name='report[date_bucket]']");
  }
  get isCumulative() {
    return this.dateBinSelectEl.value === "cumulative";
  }

  toggle(event) {
    const toggleToBarFromCumulative =
      event.currentTarget.dataset.type === "bar" && this.isCumulative;

    this.typeValue = event.currentTarget.dataset.type;

    this.updateQueryParams(toggleToBarFromCumulative);
    this.updateInput(toggleToBarFromCumulative);
  }

  updateInput(toggleToBarFromCumulative) {
    if (this.hasInputTarget) {
      this.inputTarget.value = this.typeValue;
    }

    if (toggleToBarFromCumulative) {
      this.dateBinSelectEl.value = "day";
    }
    if (this.hasPreviewableFormOutlet) {
      this.previewableFormOutlet.preview();
    }
  }

  updateQueryParams(toggleToBarFromCumulative) {
    const url = new URL(window.location.href);
    url.searchParams.set("report[chart_type]", this.typeValue);

    if (toggleToBarFromCumulative) {
      url.searchParams.set("report[date_bucket]", "day");
    }
    window.history.replaceState({}, "", url.toString());
  }
}
