import { ProviderServiceRule } from "./provider-service-rule";

export class CategoryRule extends ProviderServiceRule {
  renderSelectServiceControl() {
    super.renderSelectServiceControl("category");
  }

  fetchValues() {
    return super.fetchValues(
      this.reportUrl("categories", { provider: this.selectedProvider, service: this.dataService })
    );
  }
}
