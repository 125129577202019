import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output"];

  static values = {
    hidden: String,
  };

  initialize() {
    this.toggle();
  }

  toggle(event) {
    this.outputTargets.forEach((t) => {
      if (this.matched()) {
        t.classList.add("d-none");
      } else {
        t.classList.remove("d-none");
      }
    });
  }

  matched() {
    return (
      this.inputTarget.value.toLowerCase() == this.hiddenValue.toLowerCase()
    );
  }
}
