/// @ts-check
import * as helpers from "./helpers";

export class FilterSet extends HTMLElement {
  static get observedAttributes() {
    return ["position"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this._editable = true;
    this._locked = false;
    this._allocationHidden = false;
    this._forAccount = false;
  }

  get data() {
    return this._data;
  }

  get key() {
    let _key = `${this.selectedProvider}|${this.allocation || "1.0"}`;
    if (this.sharedFilterToken) {
      return _key + `|${this.sharedFilterToken}`;
    } else {
      return _key;
    }
  }

  get forAccount() {
    return this._forAccount;
  }

  set forAccount(value) {
    this._forAccount = value;
  }

  get allocationHidden() {
    return this._allocationHidden;
  }

  set allocationHidden(value) {
    this._allocationHidden = value;
  }

  get editable() {
    return this._editable;
  }

  set editable(value) {
    this._editable = value;
  }

  get locked() {
    return this._locked;
  }

  set locked(value) {
    this._locked = value;
  }

  get sharedFilter() {
    return this._sharedFilter;
  }

  set sharedFilter(value) {
    this._sharedFilter = value;
  }

  get sharedFilterToken() {
    if (this.sharedFilter) {
      return this.sharedFilter.token;
    }
  }

  set data(value) {
    this._data = value;
    this.render();
  }

  get availableProviders() {
    return this.filterSetRoot.availableProviders;
  }

  get attributeValues() {
    return this.filterSetRoot.attributeValues[this.selectedProvider];
  }

  get position() {
    return this.getAttribute("position");
  }

  set position(val) {
    this.setAttribute("position", val);
  }

  connectedCallback() {
    helpers.attachSiteStyles(this);
  }

  tagNameForRuleData(ruleData) {
    return {
      AttributeRule: "attribute-rule",
      ProviderRule: "provider-rule",
      TagRule: "tag-rule",
      CategoryRule: "category-rule",
      AllocationRule: "allocation-rule",
      SubCategoryRule: "sub-category-rule",
      UntaggedRule: "untagged-rule",
      BooleanRule: "boolean-rule",
      ResourceIdRule: "resource-id-rule",
    }[ruleData.type];
  }

  createAttributeRule(ruleData, position) {
    let attributeRule = document.createElement(this.tagNameForRuleData(ruleData));

    if (position) {
      attributeRule.setAttribute("position", position);
    }

    attributeRule.filterSetRoot = this.filterSetRoot;
    attributeRule.filterSet = this;
    attributeRule.data = ruleData;

    return attributeRule;
  }

  attributesAvailable() {
    if (!this.attributeValues) return [];

    let attributes = Object.keys(this.attributeValues);

    return attributes.filter((attr) => {
      return !this._data.criteria.find((criterium) => {
        if (["AttributeRule", "UntaggedRule", "BooleanRule"].find((t) => t == criterium.type)) {
          return criterium.attribute === attr;
        }
      });
    });
  }

  attributeChangedCallback(name, _oldValue, newValue) {
    if (!this.sharedFilter && this.headerElement && name === "position") {
      this.headerElement.innerText = newValue === "0" ? "All Costs" : "Or Costs";
    }
  }

  render() {
    new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((el) => {
          $(el).find('[data-toggle="tooltip"]').tooltip();
        });
      });
    }).observe(this.shadowRoot, { childList: true, subtree: true });

    let filterSetHeading = this.constructFilterSetHeading();
    this.shadowRoot.appendChild(filterSetHeading);

    this.contentsDiv = this.constructContents();
    this.shadowRoot.appendChild(this.contentsDiv);
  }

  constructFilterSetHeading() {
    let headingRow = document.createElement("div");
    headingRow.classList.add("d-flex", "justify-content-between", "align-items-center", "mb-2");

    this.headerElement = document.createElement("h6");
    this.headerElement.classList.add("filter-set--section-header");
    if (this.getAttribute("position") === "0") {
      this.headerElement.innerText = "All Costs";
    } else if (this.sharedFilter) {
      let sharedFilterLabel = document.createElement("div");
      sharedFilterLabel.classList.add(
        "badge",
        "badge-light",
        "d-flex",
        "align-items-center",
        "m-0"
      );
      sharedFilterLabel.innerHTML = `<span class="material-icons-outlined md-small mr-1">filter_list</span>${this.sharedFilter.title}`;
      this.headerElement.appendChild(sharedFilterLabel);
    } else {
      this.headerElement.innerText = "Or Costs";
    }

    headingRow.appendChild(this.headerElement);

    let filterSetOptionsDropdown = document.createElement("div");
    filterSetOptionsDropdown.classList.add("dropdown");
    filterSetOptionsDropdown.innerHTML = `<button id="${helpers.uniqueId()}" class="btn btn-link btn-sm ml-2" aria-expanded="false" aria-label="filter set options"><span class="material-icons-outlined icon" aria-hidden="true">more_horiz</span></button>`;
    this.filterSetOptionsTrigger = filterSetOptionsDropdown.firstElementChild;
    this.filterSetOptionsTrigger.addEventListener("click", this.toggleOptions.bind(this));
    this.filterSetOptionsDropdownContent = document.createElement("ul");
    this.filterSetOptionsDropdownContent.classList.add("dropdown-menu");
    this.filterSetOptionsDropdownContent.setAttribute(
      "aria-labelledby",
      this.filterSetOptionsTrigger.id
    );
    this.filterSetOptionsDropdownContent.innerHTML = `
            <li><a href="#" class="dropdown-item d-flex align-items-center"><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">delete</span> Delete</a></li>
        `;
    filterSetOptionsDropdown.appendChild(this.filterSetOptionsDropdownContent);

    if (this.editable) {
      headingRow.appendChild(filterSetOptionsDropdown);
    }

    let deleteFilterOptionTrigger =
      this.filterSetOptionsDropdownContent.lastElementChild.firstElementChild;
    deleteFilterOptionTrigger.addEventListener("click", this.deleteSelf.bind(this));

    return headingRow;
  }

  constructContents() {
    let contentsDiv = document.createElement("div");
    this.rulesDiv = document.createElement("div");
    this.allocationDiv = document.createElement("div");
    contentsDiv.append(this.rulesDiv);
    contentsDiv.append(this.allocationDiv);

    this.renderProviderRule(this.selectedProvider);

    for (let ruleData of this._data.criteria) {
      let rule = this.createAttributeRule(ruleData, this.rulesDiv.children.length);
      this.rulesDiv.appendChild(rule);
    }

    let dropdownContainer = document.createElement("div");
    dropdownContainer.classList.add("dropdown", "d-flex");
    contentsDiv.appendChild(dropdownContainer);

    let addRuleButton = document.createElement("button");
    addRuleButton.type = "button";
    addRuleButton.id = `add-rule-${helpers.uniqueId()}`;
    addRuleButton.classList.add("btn", "btn-tertiary", "btn-xs");
    addRuleButton.ariaExpanded = "false";
    addRuleButton.innerHTML = "New Rule";
    addRuleButton.addEventListener("click", this.handleAddRuleClick.bind(this));
    this.addRuleButton = addRuleButton;

    let addAllocationButton = document.createElement("button");
    addAllocationButton.type = "button";
    addAllocationButton.id = `add-allocation-${helpers.uniqueId()}`;
    addAllocationButton.classList.add("btn", "btn-tertiary", "btn-xs", "ml-2");

    addAllocationButton.ariaExpanded = "false";
    addAllocationButton.innerHTML = "Add Cost Allocation";
    addAllocationButton.addEventListener("click", this.handleAddAllocationClick.bind(this));
    this.addAllocationButton = addAllocationButton;
    this.renderAllocationRule();

    this.allocationInfo = document.createElement("div");
    this.allocationInfo.classList.add(
      "d-flex",
      "align-items-center",
      "ml-2",
      "color-text-tertiary"
    );
    this.allocationInfo.innerHTML =
      "<span class='material-icons-outlined md-small' data-toggle='tooltip' data-placement='top' title='Allocate a portion of shared costs by setting a showback percentage.'>info</span>";

    if (this.allocationHidden) {
      this.addAllocationButton.classList.add("hidden-from-dom");
      this.allocationInfo.classList.add("hidden-from-dom");
    }

    if (this.editable) {
      dropdownContainer.appendChild(addRuleButton);
      dropdownContainer.appendChild(addAllocationButton);
      dropdownContainer.appendChild(this.allocationInfo);
    }

    this.dropDownMenu = document.createElement("ul");
    this.dropDownMenu.classList.add("dropdown-menu");
    this.dropDownMenu.setAttribute("aria-labelledby", addRuleButton.id);
    this.renderDropdownMenuContents();
    dropdownContainer.appendChild(this.dropDownMenu);

    this.ownerDocument.addEventListener("click", this.hideDropdowns.bind(this));
    this.addEventListener("click", this.hideDropdowns.bind(this));
    this.addEventListener("ruleneedsdeletion", this.removeRule.bind(this));

    return contentsDiv;
  }

  clearSelf() {
    let provider = this.selectedProvider;
    this._data.criteria = [];
    let newContent = this.constructContents();
    this.contentsDiv.innerHTML = "";
    this.contentsDiv.appendChild(newContent);
    this.dispatchEvent(helpers.filtersChangedEvent());
  }

  deleteSelf(event) {
    event.preventDefault();
    this.filterSetRoot.removeFilter(this);
  }

  /** @param event {Event} */
  removeRule(event) {
    event.stopPropagation();
    let rule = event.composedPath()[0];
    this._data.criteria = this._data.criteria.filter((d) => d !== rule._data);
    rule.remove();
    this.renderDropdownMenuContents();
    for (let position = 0; position < this.rulesDiv.childElementCount; position++) {
      this.rulesDiv.children[position].setAttribute("position", position.toString());
    }
    this.dispatchEvent(helpers.filtersChangedEvent());
  }

  renderDropdownMenuContents() {
    this.dropDownMenu.innerHTML = "";

    this.attributesAvailable()
      .map((attribute) => {
        let listElement = document.createElement("li");
        let dropdownItem = document.createElement("a");
        dropdownItem.classList.add("dropdown-item", "d-flex", "align-items-center");
        dropdownItem.dataset.attributeName = attribute;
        dropdownItem.href = "#";
        dropdownItem.onclick = this.addRule.bind(this);
        dropdownItem.innerHTML = helpers.labelForAttribute(attribute, this.selectedProvider);
        listElement.appendChild(dropdownItem);
        return listElement;
      })
      .sort((a, b) => {
        return a.lastElementChild.textContent.localeCompare(b.lastElementChild.textContent);
      })
      .forEach((i) => this.dropDownMenu.appendChild(i));
  }

  hideDropdown() {
    this.addRuleButton.ariaExpanded = "false";
    this.dropDownMenu.style.display = "none";
  }

  showDropdown(event) {
    this.dispatchEvent(helpers.filtersChangedEvent("ruleexpanded"));
    this.addRuleButton.ariaExpanded = "true";
    this.dropDownMenu.style.display = "block";
  }

  handleAddRuleClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.addRuleButton.ariaExpanded === "true") {
      this.hideDropdown();
    } else {
      this.showDropdown();
    }
  }

  handleAddAllocationClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideDropdown();
    this.showAllocation();
  }

  showAllocation() {
    this.allocationDiv.style.display = "block";
    this.addAllocationButton.disabled = true;
  }

  hideAllocation() {
    this.allocationDiv.style.display = "none";
    this.addAllocationButton.disabled = false;
  }

  hideOptions() {
    this.filterSetOptionsTrigger.ariaExpanded = "false";
    this.filterSetOptionsDropdownContent.style.display = "none";
  }

  showOptions() {
    this.filterSetOptionsTrigger.ariaExpanded = "true";
    this.filterSetOptionsDropdownContent.style.display = "block";
  }

  toggleOptions(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.filterSetOptionsTrigger.ariaExpanded === "true") {
      this.hideOptions();
    } else {
      this.showOptions();
    }
  }

  hideDropdowns(event) {
    if (!this.dropDownMenu.contains(event.target)) {
      this.hideDropdown();
      this.hideOptions();
    }
  }

  renderProviderRule(defaultProvider) {
    if (this.availableProviders.length <= 1) {
      return;
    }

    let provider = this.availableProviders.find((p) => p.value == defaultProvider);
    let data = {
      type: "ProviderRule",
      attribute: "provider",
      comparator: "=",
      value: provider,
    };
    this.rulesDiv.appendChild(this.createAttributeRule(data, this.rulesDiv.children.length));
  }

  renderAllocationRule() {
    let allocation = this.allocation || 1.0;

    let data = {
      type: "AllocationRule",
      attribute: "allocation",
      value: allocation,
    };
    let element = this.createAttributeRule(data);
    this.allocationDiv.appendChild(element);
    if (allocation == 1) {
      this.hideAllocation();
    } else {
      this.showAllocation();
    }
  }

  addRule(event) {
    event.preventDefault();

    let data;
    let item = event.target;
    let attributeName = item.closest(".dropdown-item").dataset.attributeName;
    this.hideDropdowns(event);

    if (attributeName == "tag_name") {
      data = {
        type: "TagRule",
        comparator: "=",
        tag_name: null,
        tag_value: null,
      };
    } else if (attributeName == "untagged") {
      data = {
        type: "UntaggedRule",
        attribute: "untagged",
        comparator: "=",
        value: null,
      };
    } else if (attributeName == "category") {
      data = {
        type: "CategoryRule",
        attribute: "category",
        comparator: "=",
        service: null,
        value: null,
      };
    } else if (attributeName == "sub_category") {
      data = {
        type: "SubCategoryRule",
        attribute: "sub_category",
        comparator: "=",
        service: null,
        category: null,
        value: null,
      };
    } else if (attributeName == "marketplace") {
      data = {
        type: "BooleanRule",
        attribute: "marketplace",
        comparator: "=",
        service: null,
        category: null,
        value: null,
      };
    } else if (attributeName == "resource_id") {
      data = {
        type: "ResourceIdRule",
        attribute: "resource_id",
        comparator: "=",
        service: null,
        value: null,
      };
    } else {
      data = {
        type: "AttributeRule",
        attribute: attributeName,
        comparator: "=",
        value: null,
      };
    }

    this._data.criteria.push(data);
    this.rulesDiv.appendChild(this.createAttributeRule(data, this.rulesDiv.children.length));

    this.renderDropdownMenuContents();
    this.dispatchEvent(helpers.filtersChangedEvent());
  }
}
