import { Controller } from "@hotwired/stimulus";
import csrf from "../helpers/csrf";

export default class extends Controller {
  static targets = ["content", "preview"];
  static values = { previewUrl: String };

  bold(e) {
    this.wrapSelection("**");
  }

  italic() {
    this.wrapSelection("_");
  }

  strikethrough() {
    this.wrapSelection("~~");
  }

  listBulleted() {
    var selected = this.contentTarget.value.substring(
      this.selectionRange.start,
      this.selectionRange.end
    );
    this.insertContent("\n* " + selected.replace(/\n/g, "\n* "));
  }

  listNumbered() {
    var selected = this.contentTarget.value.substring(
      this.selectionRange.start,
      this.selectionRange.end
    );
    this.insertContent("\n1. " + selected.replace(/\n/g, "\n1. "));
  }

  quote() {
    var selected = this.contentTarget.value.substring(
      this.selectionRange.start,
      this.selectionRange.end
    );
    this.insertContent(">" + selected);
  }

  clearPreview() {
    this.previewTarget.innerHTML = "";
  }

  scaleHeight(e) {
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  preview() {
    var requestBody = new FormData();
    requestBody.append("content", this.contentTarget.value);
    let params = { method: "POST", body: requestBody, headers: csrf.headers };

    fetch(this.previewUrlValue, params)
      .then((r) => r.text())
      .then((r) => {
        this.previewTarget.innerHTML = r;
      });
  }

  // private

  get selectionRange() {
    return this.getInputSelection(this.contentTarget);
  }

  wrapSelection(wrapper) {
    const len = this.contentTarget.value.length;
    const newContent = `${wrapper}${this.contentTarget.value.substring(
      this.selectionRange.start,
      this.selectionRange.end
    )}${wrapper}`;
    this.contentTarget.value = `${this.contentTarget.value.substring(
      0,
      this.selectionRange.start
    )}${newContent}${this.contentTarget.value.substring(
      this.selectionRange.end,
      len
    )}`;
  }

  insertContent(selected) {
    const len = this.contentTarget.value.length;
    this.contentTarget.value =
      this.contentTarget.value.substring(0, this.selectionRange.start) +
      selected +
      this.contentTarget.value.substring(this.selectionRange.end, len);
  }

  getInputSelection(el) {
    var start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn't return what we want
        // in those cases
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end,
    };
  }
}
