import * as helpers from "./helpers";

export class BaseRule extends HTMLElement {
  static get observedAttributes() {
    return ["position"];
  }

  get dataValues() {
    return this._data[this.dataValueProp];
  }

  set dataValues(values) {
    return (this._data[this.dataValueProp] = values);
  }

  get attribute() {
    return this._data.attribute;
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  set data(d) {
    this._data = d;
    this.normalizeValue();
    this.render();
  }

  get attributeValues() {
    return this.filterSet.attributeValues;
  }

  get selectedProvider() {
    return this.filterSet.selectedProvider;
  }

  get loaderMarkup() {
    return this.filterSetRoot.loaderMarkup;
  }

  normalizeValue() {
    throw new Error("abstract method invoked.");
  }

  normalizeValue() {
    let v = this._data[this.dataValueProp];
    this._data[this.dataValueProp] = Array.isArray(v) ? v : v ? [v] : [];
  }

  connectedCallback() {
    helpers.attachSiteStyles(this);
  }

  get position() {
    return this.getAttribute("position");
  }

  render() {
    if (this.container) {
      this.container.remove();
    }
    this.container = document.createElement("div");
    this.container.classList.add("rule-row");

    this.renderPreface();
    this.renderLeftHandSide();
    this.renderComparator();
    this.renderRightHandSide();

    if (this.filterSet.editable) {
      this.renderRemoveRuleControl();
    } else {
      // This class disables pointer events for the row.
      this.container.classList.add("uneditable");
    }

    // We close the drop downs if there's a click anywhere in the whole page
    this.ownerDocument.addEventListener("click", this.closeDropdowns.bind(this));
    this.ownerDocument.addEventListener("ruleexpanded", this.closeDropdowns.bind(this));
    this.shadowRoot.appendChild(this.container);
    this.shadowRoot.addEventListener("click", this.updateComparatorOrValue.bind(this));
  }

  get dataComparator() {
    return this._data.comparator;
  }

  set dataComparator(c) {
    return (this._data.comparator = c);
  }

  get comparatorLabel() {
    if (this.dataComparator === "=") {
      return "is";
    } else if (this.dataComparator === "!=") {
      return "is not";
    } else if (this.dataComparator === "~=") {
      return "contains";
    } else if (this.dataComparator === "!~=") {
      return "does not contain";
    } else if (this.dataComparator === "^") {
      return "starts with";
    } else if (this.dataComparator === "$") {
      return "ends with";
    }
  }

  clearSelected() {
    this.dataValues = [];

    while (this.valuesContainer.firstElementChild) {
      this.valuesContainer.firstElementChild.remove();
    }

    this.updateNewValueControl();
  }

  toggleValueInput() {
    if (["~=", "!~=", "^", "$"].includes(this.dataComparator)) {
      this.addValueDropdown.classList.add("d-none");
      this.addValueInput.classList.remove("d-none");
      this.valueInputBtn.classList.remove("d-none");
    } else {
      this.addValueDropdown.classList.remove("d-none");
      this.addValueInput.classList.add("d-none");
      this.valueInputBtn.classList.add("d-none");
    }
  }

  renderComparator() {
    let comparatorDropdownContainer = document.createElement("div");
    comparatorDropdownContainer.classList.add("dropdown");
    this.comparatorSelector = document.createElement("button");
    this.comparatorSelector.ariaExpanded = "false";
    this.comparatorSelector.classList.add("btn", "btn-outline", "ml-2", "btn-sm");
    this.comparatorSelector.innerText = this.comparatorLabel;
    this.comparatorSelector.addEventListener("click", this.toggleComparatorDropdown.bind(this));
    comparatorDropdownContainer.appendChild(this.comparatorSelector);
    this.comparatorDropdown = document.createElement("ul");
    this.comparatorDropdown.classList.add("dropdown-menu");
    this.updateComparatorDropOptions();
    comparatorDropdownContainer.appendChild(this.comparatorDropdown);
    this.container.appendChild(comparatorDropdownContainer);
  }

  // "where" or "and"
  renderPreface() {
    this.prefaceElement = document.createElement("span");
    this.prefaceElement.classList.add("rule-label");
    this.prefaceElement.innerText = this.position === this.positionIsFirst() ? "where" : "and";
    this.container.appendChild(this.prefaceElement);
  }

  attributeChangedCallback(name, _oldValue, newValue) {
    if (this.prefaceElement && name === "position") {
      this.prefaceElement.innerText = newValue === this.positionIsFirst() ? "where" : "and";
    }
  }

  positionIsFirst() {
    return this.filterSet.availableProviders.length > 1 ? "1" : "0";
  }

  renderRightHandSide() {
    this.valuesContainer = document.createElement("div");
    this.valuesContainer.classList.add("pill-section");
    this.container.appendChild(this.valuesContainer);
    for (let value of this.dataValues) {
      this.createValuePill(value);
    }

    if (this.filterSet.editable) {
      this.createNewValueControl();
    }
    this.updateNewValueInputElement();
  }

  createValuePill(value) {
    let valuePill = document.createElement("span");
    valuePill.tabIndex = "0";
    valuePill.classList.add("btn", "btn-outline", "btn-pill--deletable", "ml-2", "btn-sm");
    let valuePillTruncated = document.createElement("span");
    valuePillTruncated.classList.add("pill--truncate");
    valuePillTruncated.title = this.labelFor(value);
    valuePillTruncated.innerText = this.labelFor(value);
    valuePill.appendChild(valuePillTruncated);

    if (this.filterSet.editable) {
      let deleter = document.createElement("button");
      deleter.classList.add("btn", "btn-link", "btn-remove", "ml-2");
      deleter.ariaLabel = "delete";
      deleter.innerHTML = `<span class="material-icons-outlined icon" aria-hidden="true">close</span>`;
      deleter.dataset.value = value;
      deleter.addEventListener("click", this.removeValue.bind(this, deleter));
      valuePill.appendChild(deleter);
    }

    this.valuesContainer.appendChild(valuePill);
  }

  renderRemoveRuleControl() {
    let removeRuleDiv = document.createElement("div");
    removeRuleDiv.innerHTML = `<button class="btn btn-outline btn-sm btn-borderless ml-2"><span class="material-icons-outlined icon">delete</span></button>`;
    removeRuleDiv.firstElementChild.addEventListener("click", (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      this.dispatchEvent(helpers.filtersChangedEvent("ruleneedsdeletion"));
    });
    this.container.appendChild(removeRuleDiv);
  }

  toggleValueDropdown(event) {
    event.preventDefault();

    if (event.target.ariaExpanded === "true") {
      this.hideValueDropdown();
    } else {
      this.showValueDropdown();
    }
  }

  hideValueDropdown() {
    if (!this.valueDropdown) {
      return;
    }
    this.valueDropdownTrigger.ariaExpanded = "false";
    this.valueDropdown.style.display = "none";
  }

  showValueDropdown() {
    if (!this.valueDropdown) {
      return;
    }

    this.valueDropdownTrigger.ariaExpanded = "true";
    this.valueDropdown.style.display = "block";

    if (this.valueFilter) {
      this.valueFilter.focus();
    }
  }
  /**
   *
   * @param {Event} event
   */
  removeValue(deleter, event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let value = deleter.dataset.value;
    this.dataValues = this.dataValues.filter((v) => v != value);
    deleter.parentElement.remove();
    this.filterableList.restoreItem(value);
    this.updateNewValueControl();
    this.dispatchEvent(helpers.filtersChangedEvent());
    this.updateNewValueInputElement();
  }

  labelForMissingValue(v) {
    let attribute = v || this.attribute;
    return helpers.labelForMissingValue(attribute, this.selectedProvider);
  }

  labelForAttribute(v) {
    let attribute = v || this.attribute;
    return helpers.labelForAttribute(attribute, this.selectedProvider);
  }

  updateNewValueControl() {
    if (!this.valueDropdown) {
      return;
    }

    if (this.dataValues.length === 0) {
      this.valueDropdownTrigger.classList.remove("btn-pill--invisible");
      this.valueDropdownTrigger.innerHTML = this.labelForMissingValue();
    } else {
      this.valueDropdownTrigger.classList.add("btn-pill--invisible");
      this.valueDropdownTrigger.innerHTML = helpers.labelForAddValue();
    }
  }

  updateNewValueInputElement() {
    if (this.addValueDropdown) {
      this.toggleValueInput();
    }
  }

  hasValue(value) {
    for (let v of this.dataValues) {
      if (v === value) {
        return true;
      }
    }
    return false;
  }

  reportUrl(action, params = {}) {
    return helpers.reportUrlFor(action, this.filterSet.forAccount, params);
  }

  toggleComparatorDropdown(event) {
    event.preventDefault();
    if (this.comparatorSelector.ariaExpanded === "true") {
      this.closeComparatorDropdown();
    } else {
      this.openComparatorDropdown();
    }
  }
  openComparatorDropdown() {
    this.comparatorSelector.ariaExpanded = "true";
    this.comparatorDropdown.style.display = "block";
  }
  /**
   *
   * @param {PointerEvent} event
   */
  closeDropdowns(event) {
    let path = event.composedPath();
    if (this.comparatorSelector && !path.includes(this.comparatorSelector)) {
      this.closeComparatorDropdown();
    }

    const pathIncludesFilterableDropdownWithValues = path.some(
      (node) => this.filterableList && node?.tagName === this.filterableList.tagName
    );

    if (!path.includes(this.valueDropdownTrigger) && !pathIncludesFilterableDropdownWithValues) {
      this.hideValueDropdown();
    }
  }

  closeComparatorDropdown() {
    this.comparatorSelector.ariaExpanded = "false";
    this.comparatorDropdown.style.display = "none";
  }
}
