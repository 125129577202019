import { Controller } from "@hotwired/stimulus";

// Swaps content from one element to another element on click
export default class extends Controller {
  static targets = ["source", "destination"];
  static values = {
    destroy: { type: Boolean, default: true },
  };

  swap() {
    this.destinationTarget.innerHTML = this.sourceTarget.innerHTML;
    if (this.destroyValue) {
      this.sourceTarget.remove();
    }
  }
}
