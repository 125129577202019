import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["form"];

  submit(event) {
    event.preventDefault();
    // safari doesn't support this.formTarget.requestSubmit() so invoke Turbo directly
    navigator.submitForm(this.formTarget);
  }
}
