import { Controller } from "@hotwired/stimulus";

// handles the form functionality for the issues sidebar
export default class extends Controller {
  static values = {
    useCssForHiding: {
      type: Boolean,
      default: false,
    },
    visibleItemsLimit: {
      type: Number,
      default: null,
    },
  };
  static targets = ["search", "item"];

  get visibleItemsLimit() {
    return this.visibleItemsLimitValue ?? Infinity;
  }

  filter(e) {
    e.preventDefault();

    const filterText = this.searchTarget.value.toLowerCase();
    const matchingItems = this.itemTargets.filter((element) =>
      element.dataset.text.toLowerCase().includes(filterText)
    );
    let visibleCount = 0;

    this.itemTargets.forEach((element) => {
      const shouldShow = matchingItems.includes(element) && visibleCount < this.visibleItemsLimit;

      if (shouldShow) {
        visibleCount++;
      }

      if (this.useCssForHidingValue) {
        element.classList.toggle("tw-hidden", !shouldShow);
      } else {
        element.hidden = !shouldShow;
      }
    });
  }
}
