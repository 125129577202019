import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "startDate",
    "startDateLabel",
    "emptyContainer",
    "annotation",
    "annotationsList",
    "annotationsSidebar",
  ];
  static values = { previewDate: String };
  static outlets = ["time-series", "datepicker", "previewable-form"];

  initialize() {
    this.hiddenAnnotation = null;

    document.addEventListener(
      "turbo:frame-load",
      this.handleFrameLoad.bind(this)
    );

    document.addEventListener("turbo:before-fetch-request", (event) => {
      // Intercept request to delete annotation
      if (window.annotationToDelete) {
        this.removeAnnotation(event);
      }
    });
  }

  showForm() {
    this.hideOpenForms();
    this.hideEmptyAnnotationsContainer();
    this.formTarget.hidden = false;
    this.originalStartDate = this.startDateTarget.value;
    this.originalStartDateLabel = this.startDateLabelTarget.innerText;
  }

  hideForm() {
    this.formTarget.hidden = true;
  }

  resetForm() {
    this.formTarget.reset();

    // Reset datepicker stimulus controller instance
    this.startDateTarget.value = this.originalStartDate;
    this.startDateLabelTarget.innerText = this.originalStartDateLabel;
    this.datepickerOutlet.initialize();
  }

  editAnnotation(event) {
    // Close bootstrap dropdown menu
    const dropdownMenu = event.target.closest("ul.dropdown-menu");
    if (dropdownMenu) {
      dropdownMenu.classList.remove("show");
    }

    this.hiddenAnnotation = this.annotationTargets.find(
      (annotation) => annotation.id === event.params.frameId
    );
    this.hideOpenForms();
  }

  hideOpenForms() {
    this.hideForm();
    const frameIds = this.annotationTargets
      .filter((annotation) => annotation.querySelector("form") !== null)
      .map((annotation) => annotation.id);

    frameIds.forEach((frameId) => {
      this.closeAnnotationForm({
        params: { frameId },
        preventDefault: () => {},
      });
    });
  }

  hideEmptyAnnotationsContainer() {
    if (this.hasEmptyContainerTarget) {
      this.emptyContainerTarget.classList.add("d-none");
      this.emptyContainerTarget.classList.remove("d-flex");
    }
  }

  showEmptyAnnotationsContainer(_event, { threshold = 0 } = {}) {
    if (
      this.hasEmptyContainerTarget &&
      this.annotationTargets.length <= threshold
    ) {
      this.emptyContainerTarget.classList.add("d-flex");
      this.emptyContainerTarget.classList.remove("d-none");
    }
  }

  closeAnnotationForm(event) {
    event.preventDefault();

    this.hideEmptyAnnotationsContainer();
    const frameId = event.params.frameId;
    const annotationForm = document.querySelector(`turbo-frame#${frameId}`);
    annotationForm.outerHTML = this.hiddenAnnotation.outerHTML;
  }

  highlightAnnotationLine(event) {
    const annotationId = event.params.annotationId;
    if (this.hasTimeSeriesOutlet) {
      this.timeSeriesOutlet.highlightAnnotationLine(
        annotationId,
        this.timeSeriesOutlet
      );
    }
  }

  unhighlightAnnotationLine(event) {
    const annotationId = event.params.annotationId;
    if (this.hasTimeSeriesOutlet) {
      this.timeSeriesOutlet.unhighlightAnnotationLine(
        annotationId,
        this.timeSeriesOutlet
      );
    }
  }

  previewAnnotation() {
    const date = this.previewDate;
    this.startDateTarget.value = date;
    this.datepickerOutlet.element.setAttribute("data-datepicker-start", date);
    this.datepickerOutlet.initialize();
    this.showForm();
  }

  removePreviewAnnotation() {
    this.timeSeriesOutlet.removeAnnotationPreview();
  }

  openAnnotationsSidebar() {
    if (
      this.hasAnnotationsSidebarTarget &&
      !this.annotationsSidebarTarget.hidden
    )
      return;

    const annotationSidebarButton = document.getElementById(
      "annotations-sidebar-button"
    );
    annotationSidebarButton.click();
  }

  handleFrameLoad(event) {
    if (event.target.id === "annotations" && this.previewDate) {
      this.previewAnnotation();
    }
  }

  removeAnnotation(event) {
    event.stopImmediatePropagation();
    event.stopPropagation();

    try {
      if (window.confirm("Are you sure?")) {
        this.showEmptyAnnotationsContainer(event, { threshold: 1 });
        this.previewableFormOutlet.preview();
      } else {
        event.preventDefault();
      }
    } finally {
      window.annotationToDelete = null;
    }
  }
}
