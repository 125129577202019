import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static values = {
    url: String,
    redirectUrl: String,
    initialTimeout: { type: Number, default: 5000 },
    timeout: { type: Number, default: 3500 },
  };

  connect() {
    setTimeout(() => {
      this.check();
    }, this.initialTimeoutValue);
  }

  async check() {
    try {
      await axios.get(this.urlValue);
      this.redirect();
    } catch (e) {
      setTimeout(() => {
        this.check();
      }, this.timeoutValue);
    }
  }

  redirect() {
    document.location = this.redirectUrlValue;
  }
}
