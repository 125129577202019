import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const hash = window.location.hash;
    if (hash) {
      const selectedTab = document.querySelector(`a[href="${hash}"]`);
      if (selectedTab) {
        $(selectedTab).tab("show");
      }
    }

    // Removes the hash from the url to avoid the backdrop modal that appears when the page is loaded
    history.replaceState(null, null, " ");
  }

  showTab() {
    event.preventDefault();
    $(event.target).tab("show");
  }
}
