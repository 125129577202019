import { Controller } from "@hotwired/stimulus";

// Bind the value of an input field to the textContent of an element.
export default class extends Controller {
  static targets = ["output"];

  update(event) {
    this.outputTarget.textContent = event.target.value.trim();
  }
}
