import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["resizer"];

  connect() {
    if (!this.hasResizerTarget) {
      console.error("Resizer and resizable targets must be present");
      return;
    }

    this.isResizing = false;
    this.initializeResizingEvents();
  }

  initializeResizingEvents() {
    this.resizerTargets.forEach((resizeTarget) => {
      resizeTarget.addEventListener("mousedown", (e) => {
        this.resizableContainerEl = document.getElementById(e.target.dataset.resizableTargetId);
      });
    });

    document.addEventListener("mouseup", () => {
      this.resizableContainerEl = null;
    });

    document.addEventListener("mousemove", (e) => {
      // Only resize if isResizing is true
      if (this.resizableContainerEl) {
        let newWidth;
        const position = this.resizableContainerEl.dataset.resizablePosition;

        if (position === "right") {
          const domRect = this.resizableContainerEl.getBoundingClientRect();
          newWidth = domRect.right - e.clientX;
        } else if (position === "left") {
          newWidth = e.clientX;
        }

        if (newWidth) {
          this.resizableContainerEl.style.width = newWidth + "px";
        }
      }
    });
  }

  toggleResizer() {
    this.resizerTarget.hidden = !this.resizerTarget.hidden;
  }
}
