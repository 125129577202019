// app/javascript/controllers/link_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(event) {
    const currentParams = window.location.search;
    const newHref = this.element.getAttribute("href").split("?")[0] + currentParams;
    this.element.setAttribute("href", newHref);
  }
}
