import * as helpers from "./helpers";

export class FilterableList extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.innerHTML = `
            <style type="text/css">
                :host(.in-dropdown) input[type="text"] {
                    border-width: 0 0 1px 0;
                    border-color: #E1E1E6;
                    padding: 8px 20px;
                    border-radius: 6px 6px 0 0;
                    width: 100%;
                    margin-top: -8px;
                }

                ul {
                    overflow-y: auto;
                    max-height: 15rem;
                }
            </style>
            <input type="text" class="d-none" placeholder="Search...">
            <ul class="mb-0">
                <slot></slot>
            </ul>
        `;
    helpers.attachSiteStyles(this);

    this.valueFilter.addEventListener("click", (event) =>
      event.stopPropagation()
    );
    this.valueFilter.addEventListener("keyup", this.filterValues.bind(this));
    this.values = [];
    this.shown = [];
    this.hiddenItems = new Set();
  }

  set placeholder(val) {
    this._placeholder = val;
    this.valueFilter.setAttribute("placeholder", val);
  }

  get placeholder() {
    return this._placeholder;
  }

  get defaultSlot() {
    return this.shadowRoot.querySelector("slot");
  }

  get valueFilter() {
    return this.shadowRoot.querySelector("input");
  }

  get itemLimit() {
    return 500;
  }

  set loading(val) {
    if (this._loading != val && this.loaderMarkup) {
      this._loading = val;

      this.innerHTML = "";

      if (val) {
        this.append(this.loaderMarkup.cloneNode(true));
      }
    }
  }

  get loading() {
    return this._loading;
  }

  init(values) {
    this.classList.add("in-dropdown");
    this.values = values;
    this.shown = values.slice(0, this.itemLimit);

    if (this.values.length > 5) {
      this.valueFilter.classList.remove("d-none");
    } else {
      this.valueFilter.classList.add("d-none");
    }

    this.refresh();
  }

  refresh() {
    this.loading = true;
    let items = this.shown
      .filter((v) => !this.hiddenItems.has(v.value))
      .map((v) => this.buildItem(v));
    this.loading = false;
    this.innerHTML = "";
    this.append(...items);
    this.valueFilter.focus();
  }

  buildItem(v) {
    let listItem = document.createElement("li");
    let dropdownItem = document.createElement("a");
    dropdownItem.href = "#";
    dropdownItem.classList.add("dropdown-item", "dropdown-item--wrap");
    dropdownItem.dataset.value = v.value;
    listItem.appendChild(dropdownItem);

    if (v.markup) {
      dropdownItem.innerHTML = v.markup;
    } else {
      dropdownItem.innerText = v.label;
    }

    return listItem;
  }

  /** @param event {KeyboardEvent} */
  filterValues(event) {
    if (event.target !== this.valueFilter) return;

    event.preventDefault();
    event.stopPropagation();

    if (event.key === "Enter") {
      this.dispatchEvent(
        new CustomEvent("keypressenter", {
          detail: { value: this.valueFilter.value },
        })
      );
    } else if (event.key === "Escape" || event.key === "Esc") {
      this.valueFilter.value = "";
      this.dispatchEvent(new Event("keyboardblur"), {
        bubbles: true,
        composed: true,
      });
    } else {
      let substring = this.valueFilter.value;
      this.shown = [];
      let matches = 0;
      for (let v of this.values) {
        if (matches > this.itemLimit) {
          break;
        }
        if (match(v, substring)) {
          matches++;
          this.shown.push(v);
        }
      }

      this.refresh();
    }
  }

  removeItem(value) {
    this.hiddenItems.add(value);
    this.refresh();
  }

  restoreItem(value) {
    this.hiddenItems.delete(value);
    this.refresh();
  }
}

/**
 * @param {HTMLElement} element
 * @param {RegExp | string} substring
 * @returns {boolean} Whether the substring is found (case insensitive) in the element's text.
 */
function match(element, substring) {
  substring = substring.toLowerCase();
  return (
    element.value.toLowerCase().includes(substring) ||
    (element.label && element.label.toLowerCase().includes(substring))
  );
}
