import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableBody", "emptyStateContainer"];

  get tableRows() {
    return Array.from(this.tableBodyTarget.querySelectorAll("tr")).filter(
      (row) => row !== this.emptyStateContainerTarget
    );
  }

  update() {
    if (this.tableRows.length > 0) {
      this.emptyStateContainerTarget.classList.add("tw-hidden");
    } else {
      this.emptyStateContainerTarget.classList.remove("tw-hidden");
    }
  }
}
