import { Controller } from "@hotwired/stimulus";

// Displays a confirmation dialog when checkbox is checked, and if confirmed,
// dispatches the submit event to the parent form
export default class extends Controller {
  static targets = ["source"];
  static values = {
    enableMsg: String,
    disableMsg: String,
    autoSubmitForm: {
      type: Boolean,
      default: false,
    },
  };
  submit(event) {
    event.preventDefault();
    let confirmed = false;
    const initialCheckedState = this.sourceTarget.checked;

    if (this.sourceTarget.checked) {
      confirmed = confirm(this.enableMsgValue);
      // dispatch  to the on change submit controller if confirmed
      if (confirmed) {
        this.sourceTarget.checked = true;
        this.dispatch("submit", { detail: { content: true } });
      }
    } else {
      confirmed = confirm(this.disableMsgValue);
      if (confirmed) {
        this.sourceTarget.checked = false;
        this.dispatch("submit", { detail: { content: false } });
      }
    }

    if (!confirmed) {
      this.sourceTarget.checked = initialCheckedState;
    }

    if (confirmed && this.autoSubmitFormValue) {
      this.sourceTarget.form.requestSubmit();
    }
  }
}
