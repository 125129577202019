import { FilterSet } from "./filter-set";
import { AttributeRule } from "./attribute-rule";
import { ProviderRule } from "./provider-rule";
import { AllocationRule } from "./allocation-rule";
import { TagRule } from "./tag-rule";
import { BooleanRule } from "./boolean-rule";
import { UntaggedRule } from "./untagged-rule";
import { CategoryRule } from "./category-rule";
import { SubCategoryRule } from "./sub-category-rule";
import { FilterableList } from "./filterable-list";
import { ResourceIdRule } from "./resource-id-rule";
import * as helpers from "./helpers";

class FilterSets extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  async connectedCallback() {
    this.initialize();
  }

  get formElement() {
    if (!this._formElement) {
      this._formElement = this.ownerDocument.getElementById(this.getAttribute("for"));
    }
    return this._formElement;
  }

  get features() {
    if (!this._features) {
      this._features = JSON.parse(this.getAttribute("features") || "{}");
    }
    return this._features;
  }

  get forceEditable() {
    if (!this._forceEditable) {
      this._forceEditable = JSON.parse(this.getAttribute("force-editable"));
    }
    return this._forceEditable;
  }

  get omitGrouping() {
    if (!this._omitGrouping) {
      this._omitGrouping = JSON.parse(this.getAttribute("omit-grouping"));
    }
    return this._omitGrouping;
  }

  get allocationHidden() {
    if (!this._allocationHidden) {
      this._allocationHidden = JSON.parse(this.getAttribute("allocation-hidden"));
    }
    return this._allocationHidden;
  }

  get forAccount() {
    if (!this._forAccount) {
      this._forAccount = JSON.parse(this.getAttribute("for-account"));
    }
    return this._forAccount;
  }

  get disableHistory() {
    if (!this._disableHistory) {
      this._disableHistory = JSON.parse(this.getAttribute("disable-history"));
    }
    return this._disableHistory;
  }

  get excludeSharedFilters() {
    if (!this._excludeSharedFilters) {
      this._excludeSharedFilters = JSON.parse(this.getAttribute("exclude-shared-filters"));
    }
    return this._excludeSharedFilters;
  }

  get loaderMarkup() {
    let el = this.querySelector("[data-target='filter-sets.loader']");

    if (el) {
      return el.content.cloneNode(true);
    } else {
      return;
    }
  }

  get defaultFilter() {
    return { type: "Filter", mode: "intersect", criteria: [] };
  }

  get defaultToAll() {
    return !this.features.leave_empty;
  }

  set sharedFilters(value) {
    this._sharedFilters = value;
  }

  get sharedFilters() {
    if (!this._sharedFilters) {
      this._sharedFilters = [];
    }

    return this._sharedFilters;
  }

  get sharedFiltersByToken() {
    if (!this._sharedFiltersByToken) {
      this._sharedFiltersByToken = {};
    }

    return this._sharedFiltersByToken;
  }

  get groupElementsBySharedFilterProviderAllocation() {
    if (!this._groupElementsBySharedFilterProviderAllocation) {
      this._groupElementsBySharedFilterProviderAllocation = {};
    }

    return this._groupElementsBySharedFilterProviderAllocation;
  }

  get sharedFiltersContainer() {
    if (!this._sharedFiltersContainer) {
      this._sharedFiltersContainer = document.createElement("div");
      this._sharedFiltersContainer.classList.add("shared-filters-container");
    }

    return this._sharedFiltersContainer;
  }

  set sharedFiltersContainer(value) {
    this._sharedFiltersContainer = value;
  }

  async initialize() {
    this.filterSets = [];
    helpers.attachSiteStyles(this);
    this.data = JSON.parse(this.formElement.value);
    this.filtersDiv = document.createElement("div");

    let availableProvidersRequest = fetch(helpers.reportUrlFor("providers", this.forAccount));
    this.availableProviders = await (await availableProvidersRequest).json();

    this.attributeValues = {};
    let requests = [];
    this.loaderDiv = this.loaderMarkup;

    let nonAttributeKeys = ["provider", "tag_value", "category", "sub_category", "untagged"];
    let booleanKeys = ["marketplace"];

    if (this.loaderDiv) {
      this.shadowRoot.append(this.loaderDiv);
    }

    this.availableProviders.forEach((availableProvider) => {
      // CustomCosts include an AccessCredential token suffix to disambiguate between individual integrations.
      let providerWithAccessCredentialToken = availableProvider.value;
      let [provider, accessCredentialToken] = providerWithAccessCredentialToken.split(":");

      this.attributeValues[providerWithAccessCredentialToken] = {};
      // TODO(nel): use providerWithAccessCredentialToken once we're not hard-coding the list of attributes in JS.
      let providerAttributes = helpers.attributesForProvider(provider);

      booleanKeys.forEach((bk) => {
        if (providerAttributes.includes(bk)) {
          this.attributeValues[providerWithAccessCredentialToken][bk] = true;
        }
      });

      requests.push(
        new Promise((resolve) => {
          fetch(
            helpers.reportUrlFor("attribute_values", this.forAccount, {
              provider: providerWithAccessCredentialToken,
            })
          ).then((resp) => {
            resp.json().then((json) => {
              Object.keys(json).forEach((attribute) => {
                let values = json[attribute];
                this.attributeValues[providerWithAccessCredentialToken][attribute] = values;

                if (attribute == "service") {
                  ["category", "sub_category"].forEach((k) => {
                    if (providerAttributes.includes(k)) {
                      this.attributeValues[providerWithAccessCredentialToken][k] = values;
                    }
                  });
                }

                if (attribute == "tag_name" && providerAttributes.includes("untagged")) {
                  this.attributeValues[providerWithAccessCredentialToken]["untagged"] = values;
                }
              });

              resolve();
            });
          });
        })
      );
    });

    await Promise.all(requests.concat(this.fetchSharedFilters()));

    let filterSetKeys = Object.keys(this.data);
    let filteredProviders = filterSetKeys.map((key) => key.split("|")[0]);
    let allProvidersPresent = true;

    for (let availableProvider of this.availableProviders) {
      let filtered = filteredProviders.includes(availableProvider.value);
      if ((!filtered && availableProvider.default) || (filtered && !availableProvider.default)) {
        allProvidersPresent = false;
        break;
      }
    }

    let allFiltersEmpty = true;
    filterSetKeys.forEach((key) => {
      for (let filter of this.data[key].criteria) {
        if (!this.isEmptyFilter(filter)) {
          allFiltersEmpty = false;
        }
      }
    });

    this.initSharedFilterSets(filterSetKeys, allFiltersEmpty, allProvidersPresent);

    if (!this.excludeSharedFilters) {
      // Header above all filter rules.
      this.header = document.createElement("div");
      this.header.classList.add("filter-set-header", "tw-flex", "tw-justify-between");
      this.shadowRoot.appendChild(this.header);

      this.initSharedFiltersDropdown();
      this.initVQLDropdown();
    }

    this.shadowRoot.querySelectorAll(".Loader").forEach((e) => {
      e.remove();
    });
    this.shadowRoot.append(this.filtersDiv);

    this.addFilterButton = document.createElement("button");
    this.addFilterButton.type = "button";
    this.addFilterButton.classList.add("btn", "btn-footer", "btn-tertiary", "btn-icon");
    this.addFilterButton.innerHTML = "<span class='material-icons icon'>add</span> Add a Filter";
    this.addFilterButton.onclick = this.addNewFilter.bind(this);

    this.shadowRoot.appendChild(this.addFilterButton);
    this.addEventListener("filtersetchanged", this.updateData.bind(this));
  }

  buildGroupElement() {
    let groupElement = document.createElement("div");
    groupElement.classList.add("filter-group");

    return groupElement;
  }

  initSharedFilterSets(filterSetKeys, allFiltersEmpty, allProvidersPresent) {
    if (
      (!this.defaultToAll && filterSetKeys.length > 0) ||
      !allFiltersEmpty ||
      !allProvidersPresent
    ) {
      for (let key of filterSetKeys) {
        let [provider, allocation, sharedFilterToken, _locked] = key.split("|");
        let providerAllocationKey = `${provider}|${allocation}`;

        let parentElement = this.filtersDiv;
        if (sharedFilterToken && !this.omitGrouping) {
          parentElement = this.findOrCreateGroupElementForSharedFilter(providerAllocationKey);
        }

        let editable = this.forceEditable || !sharedFilterToken;
        let filterData = this.data[key];
        for (let filter of filterData.criteria) {
          this.addFilter(false, key, filter, editable, parentElement);
        }
      }
    }
  }

  initSharedFiltersDropdown() {
    let sharedFiltersDropdownContainer = document.createElement("div");
    sharedFiltersDropdownContainer.classList.add("d-inline-block", "dropdown");
    let toggleButton = document.createElement("button");
    toggleButton.classList.add("btn", "btn-icon", "btn-sm", "btn-outline");
    toggleButton.setAttribute("data-toggle", "dropdown");
    toggleButton.setAttribute("type", "button");
    toggleButton.setAttribute("aria-expanded", "false");
    toggleButton.setAttribute("aria-label", "Activate Dropdown");

    if (this.sharedFilters.length == 0) {
      toggleButton.setAttribute("disabled", true);
      toggleButton.setAttribute("title", "No saved filters have been created.");
    }

    toggleButton.innerHTML =
      '<span class="material-icons-outlined icon" aria-hidden="true">filter_list</span>Saved Filters';

    sharedFiltersDropdownContainer.appendChild(toggleButton);
    this.sharedFiltersDropdownTrigger = toggleButton;
    this.header.appendChild(sharedFiltersDropdownContainer);

    let sharedFiltersList = document.createElement("ul");
    sharedFiltersList.classList.add("dropdown-menu", "pt-0", "overflow-auto");
    sharedFiltersList.style.maxHeight = "360px";
    sharedFiltersList.setAttribute("aria-labelledby", "dropdownMenuButton");

    let searchInput = document.createElement("input");
    searchInput.classList.add("dropdown-input", "mb-2", "py-2");
    searchInput.setAttribute("type", "text");
    searchInput.setAttribute("placeholder", "Search");
    searchInput.addEventListener("input", this.searchSharedFilters.bind(this));

    sharedFiltersList.appendChild(searchInput);

    this.sharedFilters.forEach((sharedFilter) => {
      let sharedFilterLabel = document.createElement("label");
      sharedFilterLabel.classList.add("dropdown-item", "d-flex", "align-items-center");

      let sharedFilterCheckbox = document.createElement("input");
      sharedFilterCheckbox.setAttribute("type", "checkbox");
      sharedFilterCheckbox.setAttribute("name", "sharedFilter[]");
      sharedFilterCheckbox.setAttribute("value", sharedFilter.token);
      sharedFilterCheckbox.classList.add("mr-2");
      sharedFilterCheckbox.addEventListener("change", this.toggleSharedFilter.bind(this));
      sharedFilterCheckbox.checked = this.isSharedFilterEnabled(sharedFilter.token);
      sharedFilterCheckbox.disabled = this.isSharedFilterLocked(sharedFilter.token);

      sharedFilterLabel.appendChild(sharedFilterCheckbox);
      sharedFilterLabel.appendChild(document.createTextNode(sharedFilter.title));

      sharedFiltersList.appendChild(sharedFilterLabel);
    });

    this.sharedFiltersDropdownMenu = sharedFiltersList;
    sharedFiltersDropdownContainer.appendChild(sharedFiltersList);

    sharedFiltersDropdownContainer.addEventListener(
      "click",
      this.toggleSharedFiltersDropdown.bind(this)
    );
    this.shadowRoot.addEventListener("click", this.closeDropdowns.bind(this));
  }

  initVQLDropdown() {
    const vql = this.getAttribute("vql");

    let vqlDropdownContainer = document.createElement("div");
    let vqlButton = document.createElement("button");
    vqlButton.classList.add("btn", "btn-icon", "btn-sm", "btn-outline");
    vqlButton.innerHTML = "<span class='material-icons icon'>terminal</span> View as VQL";

    this.vqlDropdownTrigger = vqlButton;

    let vqlField = document.createElement("div");
    vqlField.setAttribute("data-controller", "copy");

    let copyDiv = document.createElement("div");
    copyDiv.classList.add("tw-absolute", "tw-top-3", "tw-right-3");
    copyDiv.setAttribute("data-action", "click->copy#copyText");
    copyDiv.setAttribute("data-title", "Copy to Clipboard");

    let copySpan = document.createElement("span");
    copySpan.classList.add(
      "d-none",
      "tw-font-sans",
      "tw-text-xs",
      "tw-absolute",
      "tw-text-white",
      "tw-bg-gray-900",
      "tw-rounded-md",
      "tw-top-9",
      "tw-py-1",
      "tw-px-2",
      "tw--left-3"
    );
    copySpan.setAttribute("data-copy-target", "confirmation");
    copySpan.setAttribute("role", "alert");
    copySpan.innerHTML = "Copied";

    let copyLink = document.createElement("a");
    copyLink.href = "#";
    copyLink.classList.add(
      "material-symbols-outlined",
      "tw-text-xl",
      "tw-leading-none",
      "tw-w-8",
      "tw-h-8",
      "tw-rounded-full",
      "tw-shadow-md",
      "hover:tw-shadow-lg",
      "tw-transition-all",
      "tw-border-0",
      "tw-bg-white",
      "tw-flex",
      "tw-items-center",
      "tw-justify-center",
      "tw-text-gray-900",
      "hover:tw-text-white",
      "hover:tw-bg-gray-900"
    );

    copyLink.addEventListener("click", function (event) {
      event.preventDefault();

      navigator.clipboard.writeText(vql);

      copySpan.style.transition = "opacity 1s ease-out";
      copySpan.classList.remove("d-none");
      copySpan.style.opacity = "100%";
      setTimeout(() => {
        copySpan.style.opacity = "0%";
        copySpan.classList.add("d-none");
      }, 2000);

      for (let timer of this.activeTimeouts) {
        clearTimeout(timer);
      }
      this.activeTimeouts = [];
      this.activeTimeouts.push(
        setTimeout(() => {
          this.fadeConfirmation();
        }, 2000)
      );
      this.activeTimeouts.push(
        setTimeout(() => {
          this.hideConfirmation();
        }, 3000)
      );
    });

    let copyIcon = document.createElement("span");
    copyIcon.classList.add("material-icons-outlined", "tw-text-xl", "tw-leading-none");
    copyIcon.innerHTML = "content_copy";

    copyLink.appendChild(copyIcon);
    copyDiv.appendChild(copySpan);
    copyDiv.appendChild(copyLink);

    let vqlCode = document.createElement("code");
    vqlCode.setAttribute("data-copy-target", "text");
    vqlCode.classList.add("tw-text-gray-800");

    vqlCode.innerHTML = vql;

    // if the query params includes encoded_filters, then display a warning that the VQL is not reflective of the current set of filters
    if (window.location.search.includes("encoded_filters")) {
      let filterWarning = document.createElement("span");
      filterWarning.classList.add("tw-text-xs", "tw-text-gray-600", "tw-block", "tw-mb-2");
      filterWarning.innerHTML = "Note: VQL will reflect filters once the report is saved.";
      vqlField.appendChild(filterWarning);
    }
    vqlField.appendChild(vqlCode);
    vqlField.appendChild(copyDiv);

    vqlField.classList.add(
      "tw-p-3",
      "tw-pr-16",
      "tw-bg-gray-100",
      "tw-text-gray-800",
      "tw-relative",
      "tw-font-mono",
      "tw-text-sm",
      "tw-leading-5",
      "tw-rounded-b-md",
      "tw-min-h-16"
    );

    this.vqlDropdownMenu = vqlField;

    this.header.appendChild(vqlButton);
    vqlButton.addEventListener("click", this.toggleVQLDropdown.bind(this));
  }

  toggleVQLDropdown(event) {
    event.stopPropagation();

    if (event.target.ariaExpanded === "true") {
      this.addFilterButton.style.display = "block";
      this.shadowRoot.replaceChild(this.filtersDiv, this.vqlDropdownMenu);
      this.vqlDropdownTrigger.ariaExpanded = "false";
      this.vqlDropdownTrigger.classList.remove("tw-text-purple-600", "tw-border-purple-400");
    } else {
      this.shadowRoot.replaceChild(this.vqlDropdownMenu, this.filtersDiv);
      this.addFilterButton.style.display = "none";
      this.vqlDropdownTrigger.ariaExpanded = "true";
      this.vqlDropdownTrigger.classList.add("tw-text-purple-600", "tw-border-purple-400");
    }
  }

  toggleSharedFiltersDropdown(event) {
    event.stopPropagation();

    if (event.target.ariaExpanded === "true") {
      this.sharedFiltersDropdownMenu.style.display = "none";
      this.sharedFiltersDropdownTrigger.ariaExpanded = "false";
    } else {
      this.sharedFiltersDropdownMenu.style.display = "block";
      this.sharedFiltersDropdownTrigger.ariaExpanded = "true";
    }
  }

  searchSharedFilters(event) {
    event.preventDefault();
    event.stopPropagation();

    const searchQuery = event.target.value ? event.target.value.toLowerCase() : "";
    // hide dropdown options that don't match the search query
    this.sharedFiltersDropdownMenu.querySelectorAll("label").forEach((label) => {
      if (label.textContent.toLowerCase().includes(searchQuery)) {
        label.classList.add("d-flex");
        label.classList.remove("d-none");
      } else {
        label.classList.add("d-none");
        label.classList.remove("d-flex");
      }
    });
  }

  closeDropdowns(event) {
    if (
      !this.sharedFiltersDropdownMenu.contains(event.target) &&
      this.sharedFiltersDropdownTrigger.ariaExpanded === "true"
    ) {
      this.sharedFiltersDropdownMenu.style.display = "none";
      this.sharedFiltersDropdownTrigger.ariaExpanded = "false";
    }
  }

  findOrCreateGroupElementForSharedFilter(providerAllocationKey) {
    let existingGroupElement =
      this.groupElementsBySharedFilterProviderAllocation[providerAllocationKey];

    if (existingGroupElement) {
      return existingGroupElement;
    } else {
      let newGroupElement = this.buildGroupElement();

      this.sharedFiltersContainer.prepend(newGroupElement);
      this.groupElementsBySharedFilterProviderAllocation[providerAllocationKey] = newGroupElement;

      return newGroupElement;
    }
  }

  isSharedFilterEnabled(sharedFilterToken) {
    return this.filterSets.some((filterSet) => filterSet.sharedFilterToken === sharedFilterToken);
  }

  isSharedFilterLocked(sharedFilterToken) {
    return this.filterSets.some(
      (filterSet) => filterSet.sharedFilterToken === sharedFilterToken && filterSet.locked
    );
  }

  areAllSharedFiltersUnchecked() {
    return this.sharedFilters.every(
      (sharedFilter) => !this.isSharedFilterEnabled(sharedFilter.token)
    );
  }

  async fetchSharedFilters() {
    if (!this.excludeSharedFilters) {
      let sharedFiltersRequest = fetch(`/saved_filters`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let sharedFiltersResponse = await sharedFiltersRequest;

      if (sharedFiltersResponse.ok) {
        this.sharedFilters = await sharedFiltersResponse.json();
      }
    }

    this.sharedFilters.forEach((sharedFilter) => {
      this.sharedFiltersByToken[sharedFilter.token] = sharedFilter;
    });
  }

  toggleSharedFilter(event) {
    event.preventDefault();
    event.stopPropagation();

    let checkboxEl = event.target;
    let sharedFilterToken = checkboxEl.value;
    let sharedFilter = this.sharedFiltersByToken[sharedFilterToken];
    let data = JSON.parse(sharedFilter.filter_json);
    let keys = Object.keys(data);

    if (checkboxEl.checked) {
      for (let key of keys) {
        let providerAllocationKey = key.split("|").slice(0, 2).join("|");
        let parentElement = this.findOrCreateGroupElementForSharedFilter(providerAllocationKey);

        let filterData = data[key];
        for (let filter of filterData.criteria) {
          this.addFilter(true, key, filter, false, parentElement);
        }
      }
    } else {
      let sharedFilterTokens = keys.map((key) => key.split("|")[2]);
      let sharedFilterSetsToRemove = this.filterSets.filter(
        (filterSet) => sharedFilterTokens.indexOf(filterSet.sharedFilterToken) !== -1
      );

      sharedFilterSetsToRemove.forEach((sharedFilterSet) => this.removeFilter(sharedFilterSet));

      if (this.areAllSharedFiltersUnchecked()) {
        this.sharedFiltersContainer.remove();
      }
    }
  }

  addNewFilter() {
    const hasSingleProvider = this.availableProviders.length === 1;
    const defaultProvider = hasSingleProvider ? this.availableProviders[0].value : "";
    this.addFilter(hasSingleProvider, defaultProvider, this.defaultFilter, true, this.filtersDiv);
  }

  hasEmptyFilter() {
    return this.filterSets.filter((fs) => this.isEmptyFilter(fs.data)).length > 0;
  }

  isIncomplete() {
    for (let filterSet of this.filterSets) {
      if (hasIncompleteRules(filterSet.data)) {
        return true;
      }
    }

    return false;
  }

  isEmptyFilter(filter) {
    return filter.criteria.length === 0;
  }

  buildFilter(criteria) {
    return {
      type: "Filter",
      mode: "union",
      criteria: criteria,
    };
  }

  updateData() {
    if (!this.isIncomplete()) {
      let data = this.filterSets.reduce((d, filterSet) => {
        let key = filterSet.key;

        // Don't add filters where the provider has not been set yet
        let provider = key.split("|")[0];
        if (!provider) return d;

        if (!d[key]) {
          d[key] = this.buildFilter([]);
        }
        d[key].criteria.push(filterSet.data);

        return d;
      }, {});

      // If there are no filters just create a default for each provider.
      if (this.defaultToAll && Object.keys(data).length === 0) {
        data = this.availableProviders
          .filter((p) => p.default)
          .reduce((d, provider) => {
            d[provider.value] = this.buildFilter([this.defaultFilter]);

            return d;
          }, {});
      }

      // The backend does not support a UNION with empty rules
      // Take out any union'd empty rules and if there are 0 rules left put back the default.
      data = Object.keys(data).reduce((d, key) => {
        let filter = data[key];
        let filters = filter.criteria;

        if (filters.length > 1) {
          filters = filters.filter((fs) => !this.isEmptyFilter(fs));
        }

        if (filters.length === 0) {
          filters = [this.defaultFilter];
        }

        filter.criteria = filters;
        d[key] = filter;

        return d;
      }, {});

      const formValue = JSON.stringify(data);
      this.formElement.value = formValue;
      this.formElement.dispatchEvent(new Event("change", { bubbles: true }));
      this.updateQueryParams(formValue);
    }
  }

  updateQueryParams(val) {
    if (this.disableHistory) {
      return;
    }
    const encodedFilters = btoa(val);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("encoded_filters", encodedFilters);
    history.pushState(null, null, "?" + queryParams.toString());
  }

  addFilter(triggerEvent, key, data, editable, parentElement) {
    let splitKey = key.split("|");
    let provider = splitKey[0];
    let allocation = splitKey[1] || "1.0";
    let sharedFilterToken = splitKey[2];

    // Locked means this filter cannot be removed from the cost report. Useful for shared filters that are attached
    // to folders and thus don't belong to the cost report itself.
    let locked = splitKey[3] === "true";

    if (
      sharedFilterToken &&
      !this.omitGrouping &&
      !this.filtersDiv.contains(this.sharedFiltersContainer)
    ) {
      this.filtersDiv.prepend(this.sharedFiltersContainer);
    }

    const filterSet = document.createElement("filter-set");

    filterSet.editable = this.forceEditable || editable;
    filterSet.allocationHidden = this.allocationHidden;
    filterSet.forAccount = this.forAccount;

    if (!sharedFilterToken || this.omitGrouping) {
      filterSet.setAttribute("position", parentElement.children.length); // Add class to each filter-row to count.
    }

    filterSet.filterSetRoot = this;
    filterSet.selectedProvider = provider;
    filterSet.allocation = allocation;
    filterSet.sharedFilter = this.sharedFiltersByToken[sharedFilterToken];
    filterSet.data = data;
    filterSet.locked = locked;

    this.filterSets.push(filterSet);

    parentElement.appendChild(filterSet);

    if (triggerEvent) {
      this.dispatchEvent(helpers.filtersChangedEvent());
    }
  }

  removeFilter(removedFilterSet) {
    if (removedFilterSet.sharedFilterToken) {
      this.filterSets = this.filterSets.filter(
        (fs) => fs.sharedFilterToken !== removedFilterSet.sharedFilterToken
      );
    } else {
      this.filterSets = this.filterSets.filter((fs) => fs.position !== removedFilterSet.position);

      let newPosition = 0;
      for (let filterSet of this.filterSets) {
        filterSet.position = newPosition.toString();
        newPosition++;
      }
    }

    removedFilterSet.remove();
    this.dispatchEvent(helpers.filtersChangedEvent());
  }
}

function hasIncompleteRules(node) {
  switch (node.type) {
    case "Filter":
      for (let n of node.criteria) {
        if (hasIncompleteRules(n)) {
          return true;
        }
      }
      return false;
    case "AttributeRule":
      return node.value === null || (Array.isArray(node.value) && node.value.length === 0);
    case "TagRule":
      return node.tag_name === null;
    case "CategoryRule":
    case "ResourceIdRule":
    case "SubCategoryRule":
      return (
        node.service === null ||
        node.value === null ||
        (Array.isArray(node.value) && node.value.length === 0)
      );
    case "UntaggedRule":
    case "BooleanRule":
      return false;
    default:
      throw new Error(`Unexpected type: ${node.type}`);
  }
}

export function activate() {
  customElements.define("filter-sets", FilterSets);
  customElements.define("filter-set", FilterSet);
  customElements.define("attribute-rule", AttributeRule);
  customElements.define("provider-rule", ProviderRule);
  customElements.define("allocation-rule", AllocationRule);
  customElements.define("tag-rule", TagRule);
  customElements.define("boolean-rule", BooleanRule);
  customElements.define("untagged-rule", UntaggedRule);
  customElements.define("category-rule", CategoryRule);
  customElements.define("filterable-list", FilterableList);
  customElements.define("sub-category-rule", SubCategoryRule);
  customElements.define("resource-id-rule", ResourceIdRule);
}
