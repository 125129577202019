// Can be used to create a custom legend for a chart.js chart.
// Adapted from chart.js example: https://www.chartjs.org/docs/latest/samples/legend/html.html
// You'll want to use in conjunction with markup that contains a container with an ID that matches the containerID option.
//
// Example html:
// <div id="html-legend-container">
//   <ul className="chart-legend"></ul>
//   <template id="legendItemTemplate">
//     <li id="legend-list-item">
//       <div id="legend-list-item-content" className="tw-flex tw-items-center">
//         <span className="color color-filled material-icons tw-text-lg tw-leading-none">check_circle</span>
//         <span className="color color-unfilled material-icons tw-text-lg tw-leading-none">radio_button_unchecked</span>
//         <span className="title"></span>
//       </div>
//     </li>
//   </template>
// </div>
export const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const legendContainer = document.getElementById(options.containerID);
    const ul = legendContainer.querySelector("ul");
    const template = legendContainer.querySelector("template#legend-list-item-template");

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in chart.js legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const clone = template.content.cloneNode(true);
      const li = clone.querySelector("#legend-list-item");
      const colorFilled = li.querySelector(".color-filled");
      const colorUnfilled = li.querySelector(".color-unfilled");
      const title = li.querySelector(".title");

      li.style.cursor = "pointer";
      li.onclick = () => {
        const { type } = chart.config;
        // Pie and doughnut charts only have a single dataset and visibility is per item
        if (type === "pie" || type === "doughnut") {
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      colorFilled.style.color = item.fillStyle;
      colorUnfilled.style.color = item.strokeStyle;
      title.textContent = item.text;
      title.style.textDecoration = item.hidden ? "line-through" : "";

      ul.appendChild(clone);
    });
  },
};
