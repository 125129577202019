import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "content"];

  toggleContent(event) {
    event.preventDefault();

    this.toggleTargets.forEach((el) => el.classList.remove("active"));
    event.currentTarget.classList.add("active");

    const id = event.currentTarget.dataset.for;
    const targetToShow = document.getElementById(id);

    this.contentTargets.forEach((target) => {
      target.hidden = true;
    });
    targetToShow.hidden = false;
  }
}
