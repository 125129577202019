export const graphColors = [
  "#70D9C6",
  "#32B19B",
  "#FFC928",
  "#FF9877",
  "#91E4FB",
  "#5CB1FF",
  "#d19393",
  "#adbfea",
  "#b7d193",
  "#eaade2",
  "#93d1c6",
  "#eacead",
  "#a393d1",
  "#b0eaad",
  "#d193a8",
  "#add3ea",
  "#ccd193",
  "#ddadea",
  "#93d1b2",
  "#eabaad",
  "#9399d1",
  "#c4eaad",
  "#d193bc",
  "#ade8ea",
  "#d1c193",
  "#c9adea",
  "#93d19d",
  "#eaadb5",
  "#93add1",
  "#d8eaad",
  "#d093d1",
];

export const graphSecondaryColors = [
  "#E76F51",
  "#2F4F4F",
  "#696969",
  "#778899",
  "#808080",
  "#264653",
  "#F4A261",
];
