import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.controlledElement = document.getElementById(
      this.element.getAttribute("aria-controls")
    );
    this.verifyRegionElement(this.controlledElement);
    this.verifyAriaLabel(this.controlledElement);
    this.sync();
    this.observer = new MutationObserver(this.sync.bind(this));
    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });

    document.addEventListener("click", (e) => {
      if (
        this.expanded &&
        !this.controlledElement.contains(e.target) &&
        e.isTrusted
      ) {
        this.toggle();
      }
    });
  }

  get expanded() {
    return this.element.getAttribute("aria-expanded") == "true";
  }

  // hides the element if it is shown and vice-versa.
  toggle(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.element.setAttribute("aria-expanded", (!this.expanded).toString());
  }

  // Brings the UI into conformance with the current value of the aria-expanded attribute.
  sync() {
    if (this.expanded) {
      this.show();
    } else {
      this.hide();
    }
  }

  verifyRegionElement(element) {
    if (
      element.tagName === "SECTION" ||
      element.getAttribute("role") === "region"
    ) {
      return true;
    } else {
      console.warn(
        "The element being controlled by the revealer must be a <section> or have role='region' to be accessible."
      );
      return false;
    }
  }

  verifyAriaLabel(element) {
    if (
      element.getAttribute("aria-label") ||
      element.getAttribute("aria-labelledby")
    ) {
      return true;
    } else {
      console.warn(
        "The element being controlled by the revealer must have an aria label to be accessible."
      );
      return false;
    }
  }

  setAriaLabelForToggle(state) {
    let label = this.data.get(`aria-${state}-label`);
    if (label) {
      this.element.setAttribute("aria-label", label);
    }
  }

  show() {
    this.setAriaLabelForToggle("expanded");
    this.controlledElement.classList.remove("hidden-from-dom");
  }

  hide() {
    this.setAriaLabelForToggle("collapsed");
    this.controlledElement.classList.add("hidden-from-dom");
  }
}
