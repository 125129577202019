import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output"];

  initialize() {
    import("autonumeric").then((module) => {
      this.AutoNumeric = module.default;
      this.inputTargets.forEach((el) => {
        new this.AutoNumeric(el, { currencySymbol: "$" });

        el.addEventListener("keypress", (event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
          }
        });

        el.addEventListener("change", (event) => {
          this.outputTargets.forEach((element) => {
            element.value = event.target.innerText.replace("$", "").replace(",", "");
          });
        });
      });
    });
  }
}
