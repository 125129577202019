import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "choosePlanButtons",
    "changePlanButton",
    "contactSalesButton",
    "changePlanDialog",
    "changePlanDialogTitle",
    "currentPlanName",
    "currentPlanRate",
    "desiredPlanName",
    "desiredPlanRate",
    "cancelNotice",
    "upgradeNotice",
    "downgradeNotice",
    "subscriptionPlanID",
    "downgradeToStarterForm",
    "subscriptionForm",
    "infoMessage",
    "infoMessageText",
    "addPaymentMethodButton",
    "awsMarketplaceButton",
  ];

  get inFreeTrial() {
    return this.data.get("free-trial-status") === "active";
  }

  get isOnboarding() {
    return this.data.get("onboarding") === "true";
  }

  get has_payment_method() {
    return this.data.get("has_payment_method") == "true";
  }

  set has_payment_method(value) {
    this.data.set("has_payment_method", value);
  }

  get key() {
    return this.data.get("key");
  }

  connect() {
    this.has_payment_method =
      this.containerTarget.attributes["data-payment-method"].value == "true";
    let initialPlanRadioTarget = this.containerTarget.querySelector(
      "input[name='plan']:checked"
    );
    if (initialPlanRadioTarget && this.isOnboarding) {
      initialPlanRadioTarget.checked = false;
    } else if (initialPlanRadioTarget && !this.isOnboarding) {
      this.initialPlanOrdinal = parseInt(
        initialPlanRadioTarget.getAttribute("data-ordinal")
      );
      let initialPlanId = initialPlanRadioTarget.getAttribute("value");
      this.initialPlanName = this.containerTarget.querySelector(
        `label[for='plan-${initialPlanId}']`
      ).innerText;
      this.initialPlanRate = this.containerTarget
        .querySelector(`#rate-${initialPlanId}`)
        .getAttribute("data-rate");
    } else {
      this.initialPlanOrdinal = null;
      this.initialPlanName = null;
      this.initialPlanRate = null;
    }
    this.desiredPlanOrdinal = this.initialPlanOrdinal;
    this.desiredPlanId = null;
    this.desiredPlanName = null;
    this.desiredPlanRate = null;
    this.paymentMethodDisplayed = false;
    this.hideActionButtons();
    this.displaySubscriptionForm();
  }

  contactSales() {
    Intercom(
      "showNewMessage",
      "Hi! I am interested in signing up for a custom plan. Is there someone from sales who can assist me?"
    );
  }

  hideActionButtons() {
    this.changePlanButtonTarget.classList.remove("show");
    this.contactSalesButtonTarget.classList.remove("show");
    if (this.hasAwsMarketplaceButtonTarget) {
      this.awsMarketplaceButtonTarget.classList.remove("show");
      this.awsMarketplaceButtonTarget.setAttribute("tabindex", "-1");
    }
    this.changePlanButtonTarget.setAttribute("tabindex", "-1");
    this.contactSalesButtonTarget.setAttribute("tabindex", "-1");
    this.subscriptionPlanIDTarget.setAttribute("value", "0");
  }

  payNow(event) {
    event.preventDefault();
    $("#activatePlanModal").modal("show");
  }

  selectPlan(event) {
    this.desiredPlanOrdinal = parseInt(
      event.target.getAttribute("data-ordinal")
    );
    this.desiredPlanId = event.target.getAttribute("value");
    this.desiredPlanName = this.containerTarget.querySelector(
      `label[for='plan-${this.desiredPlanId}']`
    ).innerText;
    this.desiredPlanRate = this.containerTarget
      .querySelector(`#rate-${this.desiredPlanId}`)
      .getAttribute("data-rate");
    if (this.desiredPlanOrdinal === this.initialPlanOrdinal) {
      this.hideActionButtons();
    } else {
      this.subscriptionPlanIDTarget.setAttribute("value", this.desiredPlanId);
      this.changePlanButtonTarget.innerText = this._changeTitle();
      this.changePlanButtonTarget.classList.add("show");
      this.contactSalesButtonTarget.classList.add("show");
      this.changePlanButtonTarget.setAttribute("tabindex", "0");
      this.contactSalesButtonTarget.setAttribute("tabindex", "0");
      if (this.hasAwsMarketplaceButtonTarget) {
        this.awsMarketplaceButtonTarget.classList.add("show");
        this.awsMarketplaceButtonTarget.classList.remove("hidden-from-dom");
        this.awsMarketplaceButtonTarget.setAttribute("tabindex", "0");
      }
      if (this.desiredPlanId === "custom") {
        if (this.paymentMethodDisplayed) {
          this.toggleAddPaymentForm();
        }
        this.changePlanButtonTarget.classList.add("hidden-from-dom");
        this.contactSalesButtonTarget.classList.remove("hidden-from-dom");
      } else {
        this.changePlanButtonTarget.classList.remove("hidden-from-dom");
        this.contactSalesButtonTarget.classList.add("hidden-from-dom");
      }
      if (this.hasDowngradeToStarterFormTarget) {
        this.downgradeToStarterFormTarget.style.display =
          this.desiredPlanId === "starter" ? "block" : "none";
      }
      this.subscriptionFormTarget.style.display =
        this.desiredPlanId === "starter" ? "none" : "block";
    }
  }

  _changeTitle() {
    if (this.isOnboarding) {
      if (this.has_payment_method) {
        return `Activate ${this.desiredPlanName} Plan`;
      } else {
        return "Add a credit card";
      }
    } else {
      return this.desiredPlanOrdinal < this.initialPlanOrdinal
        ? `Downgrade to ${this.desiredPlanName} Plan`
        : `Upgrade to ${this.desiredPlanName} Plan`;
    }
  }

  changePlan(event) {
    if (this.isOnboarding) {
      if (this.has_payment_method) {
        this.submitWithoutConfirmation();
      } else {
        this.toggleAddPaymentForm();
      }
    } else {
      if (this.has_payment_method) {
        this._showChangePlanModal();
      } else {
        this.containerTarget.querySelector("#cardholder-name").focus();
      }
    }
  }

  _showChangePlanModal() {
    this.changePlanDialogTitleTarget.innerText = this._changeTitle();
    this.currentPlanNameTarget.innerText = this.initialPlanName + " Plan";
    this.desiredPlanNameTarget.innerText = this.desiredPlanName + " Plan";
    this.currentPlanRateTarget.innerText = this.initialPlanRate;
    this.desiredPlanRateTarget.innerText = this.desiredPlanRate;
    // hide all the notices and then display the correct one based on the desired plan and relative change.
    this.upgradeNoticeTarget.classList.add("hidden-from-dom");
    this.hasDowngradeNoticeTarget &&
      this.downgradeNoticeTarget.classList.add("hidden-from-dom");
    this.hasCancelNoticeTarget &&
      this.cancelNoticeTarget.classList.add("hidden-from-dom");
    if (this.desiredPlanOrdinal > this.initialPlanOrdinal) {
      this.upgradeNoticeTarget.classList.remove("hidden-from-dom");
    } else if (this.desiredPlanOrdinal < this.initialPlanOrdinal) {
      if (this.desiredPlanId === "starter") {
        this.hasCancelNoticeTarget &&
          this.cancelNoticeTarget.classList.remove("hidden-from-dom");
      } else {
        this.hasDowngradeNoticeTarget &&
          this.downgradeNoticeTarget.classList.remove("hidden-from-dom");
      }
    }
    $(this.changePlanDialogTarget).modal("show");
  }

  toggleAddPaymentForm(event) {
    if (event) event.preventDefault();
    if (this.isOnboarding && this.desiredPlanId === null) {
      this.infoMessageTextTarget.innerText =
        "Please select a plan before updating your payment method.";
      this.infoMessageTarget.classList.remove("hidden-from-dom");
      return;
    }
    if ($("#add-payment-method-form").hasClass("hidden-from-dom")) {
      this.paymentMethodDisplayed = true;
      if (this.isOnboarding) {
        this.choosePlanButtonsTarget.classList.add("hidden-from-dom");
      }
      $("#add-payment-method-form").removeClass("hidden-from-dom");
    } else {
      this.paymentMethodDisplayed = false;
      if (this.isOnboarding) {
        this.choosePlanButtonsTarget.classList.remove("hidden-from-dom");
      }
      $("#add-payment-method-form").addClass("hidden-from-dom");
    }
  }

  displaySubscriptionForm() {
    var stripe = Stripe(this.key);
    var elements = stripe.elements();

    var style = {
      base: {
        color: "#495057",
        fontFamily: '"Inter", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#6C757D",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    var card = elements.create("card", { style: style });
    card.mount("#card-element");

    card.on("change", function (event) {
      var displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
        displayError.classList.remove("hidden-from-dom");
      } else {
        displayError.classList.add("hidden-from-dom");
        displayError.textContent = "";
      }
    });

    var payment_method_form = document.getElementById(
      "add-payment-method-form"
    );

    payment_method_form.addEventListener("submit", (ev) => {
      ev.preventDefault();
      this.createPaymentMethod(stripe, card);
    });
  }

  submitWithoutConfirmation() {
    let button = this.paymentMethodDisplayed
      ? this.addPaymentMethodButtonTarget
      : this.changePlanButtonTarget;
    button.innerText = "Saving...";
    button.disabled = true;
    this.subscriptionFormTarget.submit();
  }

  createPaymentMethod(stripe, card) {
    let billingName = document.querySelector("#cardholder-name").value;
    let authToken = document.querySelector("#stripe-authenticity-token").value;

    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          name: billingName,
        },
      })
      .then((result) => {
        if (result.error) {
          displayError.textContent = result;
        } else {
          $("#add-payment-submit-button").text("Saving...");
          fetch("/subscriptions/add_payment_method", {
            method: "post",
            credentials: "same-origin",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              authenticity_token: authToken,
              paymentMethodId: result.paymentMethod.id,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              // The card had an error when trying to attach it to a customer. Inform them.
              if (result.error) {
                document.getElementById("payment-error").textContent =
                  result.error.message;
                $("#payment-error").removeClass("hidden-from-dom");
              } else {
                if (this.isOnboarding) {
                  this.submitWithoutConfirmation();
                } else {
                  location.reload();
                }
              }
              return result;
            })
            .catch((error) => {
              document.getElementById("payment-error").textContent =
                error.message;
              $("#payment-error").removeClass("hidden-from-dom");
            });
        }
      });
  }
}
