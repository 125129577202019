import "./application.scss";
// Stimulus controller
import "../controllers";

import Rails from "@rails/ujs";
if (!window.Rails) {
  window.Rails = Rails;
  if (Rails.fire(document, "rails:attachBindings")) {
    Rails.start();
  }
}

// Instrumentation
if (!["development", "test"].includes(process.env.NODE_ENV)) {
  import("@datadog/browser-rum").then(({ datadogRum }) => {
    import("@datadog/browser-worker?url").then(({ default: workerUrl }) => {
      datadogRum.init({
        applicationId: "e36d36ec-aba1-490b-9f63-accd6ccad5cc",
        clientToken: "pub6a4daf9be897fccfebb14341baa5d70c",
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "datadoghq.com",
        service: "core-frontend",
        allowedTracingUrls: [/https:\/\/.*\.vantage\.sh/],
        env: process.env.NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        workerUrl: workerUrl,
      });
    });
  });

  import("@sentry/browser").then((Sentry) => {
    import("@sentry/tracing").then((Integrations) => {
      Sentry.init({
        dsn: "https://c4832f4d90e440ec885a846d64af9d43@o390333.ingest.sentry.io/6002295",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV,
      });

      window.Sentry = Sentry;
    });
  });
}

// Rails dependencies
import * as ActiveStorage from "@rails/activestorage";
import "../channels";
ActiveStorage.start();

import "@github/details-menu-element";
import "@github/include-fragment-element";

import * as webComponents from "../web-components";
webComponents.activate();

import moment from "moment";
window.moment = moment;

// crypto.randomUUID() polyfill.
if (!("randomUUID" in crypto)) {
  // https://stackoverflow.com/a/2117523/2800218
  crypto.randomUUID = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  };
}

$(function () {
  $('[data-toggle="tooltip"]').tooltip({
    container: "body",
    boundary: "window",
  });

  document.querySelectorAll("[data-track]").forEach((e) => {
    let trackedEvent = e.dataset.track;

    if (typeof analytics !== "undefined" && trackedEvent) {
      e.addEventListener("click", () => {
        analytics.track(trackedEvent);
      });
    }
  });

  if (window.location.hash) {
    $(window.location.hash).modal("show");
  }

  function durationSlider() {
    var listItems = $(".loop ul li").length;
    var count = 1;

    setInterval(function () {
      $(".loop ul li.active").removeClass("active");
      $(".loop ul li:eq(" + count + ")").addClass("active");

      count += 1;
      if (count >= listItems) {
        count = 0;
      }
    }, 14000);
  }

  durationSlider();

  $(".focus-input").on("click", function () {
    $(".focused-input").focus();
  });
});

document.addEventListener("turbo:load", function () {
  window.Rails.refreshCSRFTokens();
  $('[data-toggle="tooltip"]').tooltip();
});

// Ensures pages loaded by Turbo are sent to Segment
document.addEventListener("turbo:visit", function () {
  if (typeof analytics !== "undefined") {
    analytics.page();
  }
});

document.addEventListener("turbo:submit-end", function () {
  if (typeof analytics !== "undefined") {
    analytics.page();
  }
});
