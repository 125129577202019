import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output", "trigger", "label"];

  static values = {
    output: String,
    propagation: Boolean,
    bubble: Boolean,
  };

  initialize() {
    if (this.hasInputTarget) {
      if (this.inputTarget.classList.contains("dropdown-menu")) {
        this.inputTarget.querySelectorAll("li").forEach((el) => {
          el.addEventListener("click", this.update.bind(this));
        });
      } else {
        this.inputTarget.addEventListener("change", this.update.bind(this));
      }

      this.triggerTargets.forEach((triggerTarget) => {
        triggerTarget.addEventListener("click", (e) =>
          this.inputTarget.dispatchEvent(new Event("change"))
        );
      });
    } else {
      // If there's no input, the trigger target has the data-value attribute we use to update.
      this.triggerTargets.forEach((triggerTarget) => {
        triggerTarget.addEventListener("click", this.update.bind(this));
      });
    }
  }

  update(event) {
    let target = event.currentTarget;
    let value = ["INPUT", "SELECT"].includes(event.currentTarget.tagName)
      ? target.value
      : target.dataset.value;

    if (this.hasOutputValue) {
      let e = document.getElementById(this.outputValue);
      e.value = value;

      if (this.propagationValue) {
        event.preventDefault();
        event.stopPropagation();
      }

      e.dispatchEvent(new Event("change"));
    } else {
      this.outputTargets.forEach((e) => {
        e.value = value;

        e.dispatchEvent(new Event("change"));

        if (this.bubbleValue) {
          e.form.dispatchEvent(new Event("change"));
        }
      });
    }
  }
}
