import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "image"];

  initialize() {
    this.imageTarget.appendChild(this.imageTag);
    this.messageTarget.innerHTML = this.message;
    setTimeout(() => {
      location.reload();
    }, 5000);
  }

  get imageTag() {
    let img = document.createElement("img");
    img.src = this.image;
    img.style = "width: 100%;";
    return img;
  }

  get message() {
    return this.data.get("message");
  }

  get image() {
    return this.data.get("image");
  }
}
