import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { autoOpen: { type: Boolean, default: true } };
  static targets = ["modal"];

  connect() {
    if (this.autoOpenValue) {
      this.open();
    }
  }

  close() {
    if (this.hasModalTarget) {
      $(this.modalTarget).modal("hide");
    } else {
      $(this.element).modal("hide");
    }

    this.modalTurboFrame.src = null;
  }

  open() {
    if (this.hasModalTarget) {
      $(this.modalTarget).modal("show");
    } else {
      $(this.element).modal("show");
    }
  }

  escClose(event) {
    if (event.key === "Escape") this.close();
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
