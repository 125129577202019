import { Controller } from "@hotwired/stimulus";

// Enables/disables target elements when checkbox is toggled.
export default class extends Controller {
  static targets = ["content"];

  enableContent(e) {
    e.preventDefault();

    if (e.target.checked) {
      this.contentTargets.forEach((target) => {
        target.removeAttribute("disabled");
      });
    } else {
      this.contentTargets.forEach((target) => {
        target.setAttribute("disabled", true);
      });
    }
  }
}
