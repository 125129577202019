import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["body", "action", "replacement"];

  connect() {
    if (this.obscured) {
      this.obscure();
    } else {
      this.unobscure();
    }
  }

  toggle(event) {
    event.preventDefault();

    if (this.obscured) {
      this.unobscure();
    } else {
      this.obscure();
    }
  }

  obscure() {
    this.bodyTarget.classList.add("d-none");
    this.replacementTarget.classList.remove("d-none");
    this.actionTarget.innerText = "Show";
    this.status = "obscured";
  }

  unobscure() {
    this.replacementTarget.classList.add("d-none");
    this.bodyTarget.classList.remove("d-none");
    this.actionTarget.innerText = "Hide";
    this.status = "unobscured";
  }

  get obscured() {
    return this.data.get("status") == "obscured";
  }

  set status(value) {
    this.data.set("status", value);
  }
}
