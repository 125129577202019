import { Controller } from "@hotwired/stimulus";

// Binds the value of an HTML element to an event.
export default class extends Controller {
  static values = {
    eventName: String,
    eventField: String,
  };

  connect() {
    document.addEventListener(this.eventNameValue, (event) => {
      const value = event.detail[this.eventFieldValue];
      this.element.value = value;
      const changeEvent = new Event("change", { bubbles: true });
      this.element.dispatchEvent(changeEvent);
    });
  }
}
