import { BaseRule } from "./base-rule";
import * as helpers from "./helpers";

export class AllocationRule extends BaseRule {
  get dataValueProp() {
    return "value";
  }

  get availableProviders() {
    return this.filterSetRoot.availableProviders;
  }

  get currentValue() {
    return this.dataValues[0];
  }

  handleValueChanged(event) {
    this.filterSet.allocation = event.target.value / 100;
    this.dispatchEvent(helpers.filtersChangedEvent());
  }

  renderLeftHandSide() {
    let comparatorSelector = document.createElement("button");
    comparatorSelector.classList.add("btn", "btn-outline", "ml-2", "btn-sm");
    comparatorSelector.innerText = "is";

    this.container.appendChild(comparatorSelector);

    this.valuePill = document.createElement("input");
    this.valuePill.type = "number";
    this.valuePill.value = this.currentValue * 100;
    this.valuePill.addEventListener(
      "change",
      this.handleValueChanged.bind(this)
    );

    let valuePillWrapper = document.createElement("div");
    valuePillWrapper.classList.add("valuePillWrapper");
    valuePillWrapper.append(this.valuePill);
    valuePillWrapper.append("%");

    this.container.appendChild(valuePillWrapper);

    this.valuePill.addEventListener(
      "focus",
      function (e) {
        valuePillWrapper.classList.add("valuePillWrapper--focus");
      },
      true
    );

    this.valuePill.addEventListener(
      "blur",
      function (e) {
        valuePillWrapper.classList.remove("valuePillWrapper--focus");
      },
      true
    );
  }

  renderPreface() {
    this.prefaceElement = document.createElement("button");
    this.prefaceElement.classList.add(
      "btn",
      "btn-outline",
      "btn-sm",
      "btn-icon"
    );
    this.prefaceElement.innerHTML =
      "<span class='material-icons-outlined icon'>calculate</span> Cost Allocation";
    this.container.appendChild(this.prefaceElement);
  }

  reset() {
    this.filterSet.allocation = 1.0;
    this.dataValues = 1.0;
    this.valuePill.value = 100;
    this.valuePill.dispatchEvent(new Event("change"));
  }

  renderRemoveRuleControl() {
    let removeRuleDiv = document.createElement("div");
    removeRuleDiv.innerHTML = `<button class="btn btn-outline btn-sm btn-borderless ml-2"><span class="material-icons-outlined icon">delete</span></button>`;

    removeRuleDiv.firstElementChild.addEventListener("click", (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      this.reset();
      this.filterSet.hideAllocation();
    });

    this.container.appendChild(removeRuleDiv);
  }

  attributeChangedCallback(name, _oldValue, newValue) {}
  renderComparator() {}
  renderRightHandSide() {}
  updateComparatorOrValue() {}
  closeComparatorDropdown() {}
}
