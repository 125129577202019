import { Controller } from "@hotwired/stimulus";

import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import { startOfISOWeek, parseISO } from "date-fns";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { BarWithErrorBarsController, BarWithErrorBar } from "chartjs-chart-error-bars";
import moment from "moment";
import ExternalTooltip from "./graph-helpers/external-tooltip";
import { graphColors, graphSecondaryColors } from "@/helpers/charts/colors";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  annotationPlugin,
  BarWithErrorBarsController,
  BarWithErrorBar
);

const GRAPH_ALL_KEY = "^all^";

export default class extends Controller {
  static targets = [
    "output",
    "filter",
    "title",
    "mode",
    "legend",
    "legendTemplate",
    "showMoreDropdownTemplate",
    "externalTooltipTemplate",
  ];

  static values = {
    allowAnnotating: {
      type: Boolean,
      default: true,
    },
    stackCount: {
      type: Number,
      default: 5,
    },
    stackLimitIncrement: {
      type: Number,
      default: 5,
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
    hidePercentageAxis: {
      type: Boolean,
      default: false,
    },
    datasetColors: {
      type: Object,
      default: {},
    },
    primaryColors: {
      type: Array,
      default: graphColors,
    },
    secondaryColors: {
      type: Array,
      default: graphSecondaryColors,
    },
  };

  static outlets = ["annotations"];

  initialize() {
    if (
      !this.inputData ||
      !Object.values(this.inputData).length ||
      !Object.values(this.inputData).some((arr) => arr.length)
    ) {
      this.generateChart([]);
      return;
    }

    this.setDateBucketDropdown(this.mode);
    this.originallyStacked = this.stacked;
    this.shownLabel = GRAPH_ALL_KEY;
    this.generateChart();
  }

  generateChart(datasets) {
    if (!datasets) {
      datasets = this.generateData();
    }

    let options = this.chartOptions(datasets);

    if (this.chart) {
      this.chart.destroy();
    }

    new Chart(this.outputTarget.getContext("2d"), options);

    if (this.stacked) {
      this.renderLegend();
    }
  }

  get annotations() {
    let visibleAnnotationLines = {};
    let invisibleAnnotationLines = {};

    this.annotationsData.forEach((annotation) => {
      const xMin = this.binDate(annotation.xMin);
      const xMax = this.binDate(annotation.xMax);

      invisibleAnnotationLines[annotation.id] = {
        type: "line",
        xMin,
        xMax,
        borderColor: "transparent",
        borderWidth: 20,
        enter: this.highlightAnnotation.bind(this),
        leave: this.unhighlightAnnotation.bind(this),
        click: this.annotationsOutlet.openAnnotationsSidebar.bind(this),
      };

      visibleAnnotationLines[`${annotation.id}-visible`] = {
        type: "line",
        xMin,
        xMax,
        borderColor: "#CFCFD6",
        borderWidth: 1,
        afterDraw: this.drawAnnotationTriangle,
      };
    });

    // Displays a light gray background for negative values.
    let negativeBackgroundArea = {
      type: "box",
      yMin: -Infinity,
      yMax: 0,
      backgroundColor: "rgb(216,216,216,0.10)",
      borderWidth: 0,
    };

    return { ...invisibleAnnotationLines, ...visibleAnnotationLines, negativeBackgroundArea };
  }

  get inputData() {
    return JSON.parse(this.data.get("input-data")) || {};
  }

  get forecastData() {
    return JSON.parse(this.data.get("forecast-data")) || {};
  }

  get previousPeriodData() {
    return JSON.parse(this.data.get("previous-data")) || [];
  }

  get budgetData() {
    return JSON.parse(this.data.get("budget-data")) || [];
  }

  get annotationsData() {
    return JSON.parse(this.data.get("annotations-data")) || [];
  }

  get secondAxisData() {
    return JSON.parse(this.data.get("second-axis-data")) || {};
  }

  get percentageAxisData() {
    return JSON.parse(this.data.get("percentage-axis-data")) || {};
  }

  get coverageData() {
    return JSON.parse(this.data.get("coverage-data"));
  }

  get mode() {
    return this.data.get("mode");
  }

  get stacked() {
    return this.data.get("stacked") === "true";
  }

  get originallyStacked() {
    return this.data.get("originallyStacked") === "true";
  }

  set originallyStacked(val) {
    this.data.set("originallyStacked", val);
  }

  set stacked(val) {
    this.data.set("stacked", val);
  }

  set mode(val) {
    this.data.set("mode", val);
  }

  get highlighted() {
    let h = this.data.get("highlighted");

    if (h) {
      return moment.utc(h);
    } else {
      return null;
    }
  }

  get bin() {
    if (this.mode === "cumulative") {
      return "day";
    } else {
      return this.mode;
    }
  }

  get chartType() {
    if (this.highlighted) {
      return "bar";
    } else if (this.data.get("chartType") === "area") {
      return "line";
    } else if (this.data.get("chartType")) {
      return this.data.get("chartType");
    } else if (this.displayCumulative()) {
      return "line";
    } else {
      return "bar";
    }
  }

  get isAreaChart() {
    return this.data.get("chartType") === "area";
  }

  get grouped() {
    return this.data.get("grouped") === "true";
  }

  get externalTooltipTemplate() {
    if (this.hasExternalTooltipTemplateTarget) {
      return this.externalTooltipTemplateTarget.content.cloneNode(true);
    }
  }

  get externalTooltip() {
    return new ExternalTooltip(this.externalTooltipTemplate);
  }

  set chartType(val) {
    this.data.set("chartType", val);
  }

  get disabledLabels() {
    let val = this.data.get("disabledLabels") || "[]";
    return JSON.parse(val);
  }

  set disabledLabels(val) {
    this.data.set("disabledLabels", JSON.stringify(val));
  }

  get shownLabel() {
    return this.data.get("shown-label");
  }

  set shownLabel(val) {
    this.data.set("shown-label", val);
  }

  get labelMap() {
    if (this.cachedLabelMap) {
      return this.cachedLabelMap;
    }
    let json = this.data.get("label-map");

    if (json) {
      this.cachedLabelMap = JSON.parse(json);
      return this.cachedLabelMap;
    } else {
      return null;
    }
  }

  get legendTemplate() {
    return this.legendTemplateTarget.content.cloneNode(true);
  }

  get showMoreDropdownTemplate() {
    if (this.hasShowMoreDropdownTemplateTarget) {
      return this.showMoreDropdownTemplateTarget.content.cloneNode(true);
    }
  }

  set ungrouped(val) {
    this.data.set("ungrouped", JSON.stringify(val));
  }

  get ungrouped() {
    return JSON.parse(this.data.get("ungrouped"));
  }

  get dateFormat() {
    return "YYYY-MM-DD";
  }

  get datasetCount() {
    return Object.keys(this.inputData).filter((data) => !data[GRAPH_ALL_KEY]).length;
  }

  get styles() {
    const opacity = "1";
    const color = `rgb(156, 36, 199, ${opacity})`;
    const highlightedColor = `rgb(255, 31, 0, ${opacity})`;
    const highlighted = this.highlighted;

    return {
      fill: this.fillStyle(),
      lineTension: 0.1,
      borderColor: (ctx) => {
        if (
          !this.displayCumulative() &&
          highlighted &&
          ctx.raw.x == highlighted.format(this.dateFormat)
        ) {
          return highlightedColor;
        } else if (
          !this.displayCumulative() &&
          ctx.raw &&
          this.withinIncompleteCostWindow(ctx.raw.x)
        ) {
          return `rgb(156, 36, 199, 0.3)`;
        } else {
          return color;
        }
      },
      backgroundColor: (ctx) => {
        if (
          !this.displayCumulative() &&
          highlighted &&
          ctx.raw.x == highlighted.format(this.dateFormat)
        ) {
          return highlightedColor;
        } else if (
          !this.displayCumulative() &&
          ctx.raw &&
          this.withinIncompleteCostWindow(ctx.raw.x)
        ) {
          return `rgb(156, 36, 199, 0.3)`;
        } else {
          return color;
        }
      },
      pointStyle: "circle",
      pointRadius: 1,
    };
  }

  displaySecondAxis() {
    let secondAxisData = this.secondAxisData;

    return secondAxisData && Object.keys(secondAxisData).length > 0;
  }

  displayPercentageAxis() {
    let percentageAxisData = this.percentageAxisData;

    return percentageAxisData && Object.keys(percentageAxisData).length > 0;
  }

  setDateBucketDropdown(val) {
    if (this.hasModeTarget) {
      this.modeTarget.value = val;
    }
  }

  fillStyle() {
    if (this.isAreaChart || (this.stacked && this.chartType !== "line")) {
      return "origin";
    }
    return false;
  }

  mappedLabel(val) {
    if (!this.labelMap) {
      return val;
    }
    return this.labelMap[val] || val;
  }

  withinIncompleteCostWindow(date) {
    let startWindow = moment.utc().subtract(2, "days").startOf("day");
    let contextDate = moment.utc(date);

    return startWindow < contextDate;
  }

  generateData() {
    let datasets = [];

    let dateRange = [];
    let currentDate = moment.utc(this.startDate);
    let stopDate = moment.utc(this.endDate);

    while (currentDate <= stopDate) {
      dateRange.push(moment.utc(currentDate).format(this.dateFormat));
      currentDate = moment.utc(currentDate).add(1, "days");
    }

    dateRange = dateRange.sort(
      (a, b) => moment(a).format("YYYYMMDD") - moment(b).format("YYYYMMDD")
    );

    let keys = [];

    if (Object.keys(this.inputData).length > 0) {
      keys = Object.keys(this.inputData);
    } else if (Object.keys(this.forecastData).length > 0) {
      keys = Object.keys(this.forecastData);
    }

    let lastDate = this.inputData[GRAPH_ALL_KEY].sort(
      (a, b) => moment(b.x).format("YYYYMMDD") - moment(a.x).format("YYYYMMDD")
    )[0].x;
    let previousPeriodDateRange;

    if (this.previousPeriodData[GRAPH_ALL_KEY]) {
      previousPeriodDateRange = this.previousPeriodData[GRAPH_ALL_KEY].map((d) => d.x).sort(
        (a, b) => moment(a).format("YYYYMMDD") - moment(b).format("YYYYMMDD")
      );
    }

    let groupingCostMap = [];
    let allCosts = {};

    this.inputData[GRAPH_ALL_KEY].forEach((p) => {
      allCosts[p.x] = p.y;
    });

    if (Object.keys(this.budgetData).length > 0) {
      let budgetDatasets = this.generateBudgetDatasets(this.budgetData);
      budgetDatasets.forEach((dataset) => (dataset.hidden = this.hiddenStatus(dataset)));
      datasets = [...datasets, ...budgetDatasets];
    }

    if (this.secondAxisData) {
      let secondAxisDatasets = this.generateSecondAxisDatasets(allCosts);
      let percentageAxisDatasets = this.generatePercentageAxisDatasets();
      datasets = [...datasets, ...secondAxisDatasets, ...percentageAxisDatasets];
    }

    if (this.coverageData) {
      let coverageDataset = this.generateCoverageDataset();
      datasets.push(coverageDataset);
    }

    if (this.forecastData && this.stacked && this.chartType === "bar") {
      const sortedAllData = this.sortByDate(this.inputData[GRAPH_ALL_KEY]);
      const forecastedDatasets = this.generateForecastedDatasets(
        this.forecastData[GRAPH_ALL_KEY],
        sortedAllData,
        this.binData(sortedAllData),
        this.makeCumulative(sortedAllData),
        GRAPH_ALL_KEY
      );
      datasets = [...datasets, ...forecastedDatasets];
    }

    keys.forEach((key) => {
      if (key === GRAPH_ALL_KEY && this.stacked) {
        return;
      }

      let costData = this.inputData[key];
      costData = this.sortByDate(costData);
      let binnedCostData = this.binData(costData);
      let cumulativeCostData = this.makeCumulative(costData);
      let previousPeriodData = this.previousPeriodData[key];

      if (Array.isArray(costData) && costData.length > 0) {
        if (this.bin === "cumulative") {
          costData = this.gapFill(
            costData,
            dateRange.filter((d) => moment.utc(d).startOf("day") <= moment.utc(lastDate))
          );
        }

        cumulativeCostData = this.makeCumulative(costData);
        let order = cumulativeCostData[cumulativeCostData.length - 1].y;
        groupingCostMap.push([key, order]);
        if (order.toFixed(2) <= 0 && this.stacked && !this.allowNegativeValue) {
          return;
        }

        // Order negative numbers in the same way instead of inversed.
        order = Math.abs(order);

        // We generate both cumulative and non-cumulative datasets for costs data to properly calculate the "Other" costs
        let cumulativeCostsDataset = {
          label: key,
          data: cumulativeCostData,
          dataIdentifier: key,
          dataType: "costs",
          cumulative: true,
          category: "Costs",
          order: order,
          type: this.chartType,
          ...this.styles,
        };
        cumulativeCostsDataset.hidden = this.hiddenStatus(cumulativeCostsDataset);
        datasets.push(cumulativeCostsDataset);

        let costsDataset = {
          label: key,
          data: binnedCostData,
          dataIdentifier: key,
          dataType: "costs",
          category: "Costs",
          cumulative: false,
          stack: "all",
          order: order,
          type: this.chartType,
          ...this.styles,
        };
        costsDataset.hidden = this.hiddenStatus(costsDataset);
        datasets.push(costsDataset);
      }

      if (!this.stacked) {
        const forecastedDatasets = this.generateForecastedDatasets(
          this.forecastData[key],
          costData,
          binnedCostData,
          cumulativeCostData,
          key
        );
        datasets = [...datasets, ...forecastedDatasets];
      }

      if (previousPeriodData && !this.stacked) {
        let previousStyles = Object.assign({}, this.styles);

        previousStyles.borderColor = "rgb(156, 36, 199, 0.25)";
        previousStyles.backgroundColor = "rgb(156, 36, 199, 0.25)";

        previousPeriodData = this.sortByDate(previousPeriodData);

        if (this.bin === "cumulative") {
          previousPeriodData = this.gapFill(previousPeriodData, previousPeriodDateRange);
        }

        previousPeriodData = previousPeriodData
          .map((point, i) => {
            let referenceDate = dateRange[i];
            let adjustedPoint;

            if (referenceDate) {
              adjustedPoint = {
                y: point.y,
                x: referenceDate,
                originalX: point.x,
              };
            }

            return adjustedPoint;
          })
          .filter((p) => p);

        const cumulativePreviousDataset = {
          label: this.previousLabel(key),
          data: this.makeCumulative(previousPeriodData),
          cumulative: true,
          dataType: "previous",
          dataIdentifier: key,
          category: "Previous Period",
          type: this.chartType,
          ...previousStyles,
        };

        cumulativePreviousDataset.hidden = this.hiddenStatus(cumulativePreviousDataset);
        datasets.push(cumulativePreviousDataset);

        if (this.chartType === "line") {
          let previousDataset = {
            label: this.previousLabel(key),
            data: this.binData(previousPeriodData),
            dataType: "previous",
            dataIdentifier: key,
            category: "Previous Period",
            cumulative: false,
            type: this.chartType,
            ...previousStyles,
          };

          previousDataset.hidden = this.hiddenStatus(previousDataset);
          datasets.push(previousDataset);
        }
      }
    });

    if (this.stacked) {
      const totalColors = this.primaryColorsValue.length;

      this.ungrouped = groupingCostMap
        .sort((a, b) => {
          return b[1] - a[1];
        })
        .slice(0, this.stackCountValue)
        .map((grouping) => grouping[0]);

      let colorLabels = {};

      datasets.forEach((dataset) => {
        if (this.ungrouped.includes(dataset.label)) {
          if (!dataset.cumulative) {
            dataset.data.forEach((d) => {
              allCosts[d.x] = allCosts[d.x] - parseFloat(d.y);
            });
          }

          if (this.datasetColorsValue[dataset.label]) {
            dataset.backgroundColor = this.datasetColorsValue[dataset.label];
            dataset.borderColor = this.datasetColorsValue[dataset.label];
          } else if (colorLabels[dataset.label]) {
            dataset.backgroundColor = colorLabels[dataset.label];
            dataset.borderColor = colorLabels[dataset.label];
          } else {
            let colorIndex = this.ungrouped.indexOf(dataset.label) % totalColors;
            dataset.backgroundColor = this.primaryColorsValue[colorIndex];
            dataset.borderColor = this.primaryColorsValue[colorIndex];
            colorLabels[dataset.label] = this.primaryColorsValue[colorIndex];
          }
        } else {
          if (this.isSecondaryDataset(dataset) || dataset.dataType === "forecast") {
            dataset.grouped = false;
            dataset.hidden = false;
            dataset.stack = dataset.dataType;
            dataset.order = -1;
          } else {
            dataset.grouped = true;
            dataset.hidden = true;
          }
        }
      });

      if (this.datasetCount > this.stackCountValue) {
        let allData = Object.keys(allCosts).map((date) => {
          let cost = this.allowNegativeValue ? allCosts[date] : Math.max(0, allCosts[date]);

          return {
            x: date,
            y: cost,
          };
        });

        allData = this.binData(allData);

        if (this.displayCumulative()) {
          let cumulativeOtherDataset = {
            label: "Other Costs",
            data: this.makeCumulative(allData),
            dataIdentifier: "Other",
            dataType: "other-costs",
            cumulative: true,
            category: "Costs",
            order: -1,
            hidden: false,
            type: this.chartType,
            ...this.styles,
          };
          cumulativeOtherDataset.hidden = this.hiddenStatus(cumulativeOtherDataset);
          datasets.push(cumulativeOtherDataset);
        } else {
          let otherDataset = {
            label: "Other Costs",
            data: this.sortByDate(allData),
            dataIdentifier: "Other",
            dataType: "other-costs",
            category: "Costs",
            cumulative: false,
            stack: "all",
            order: -1,
            type: this.chartType,
            ...this.styles,
          };
          otherDataset.hidden = this.hiddenStatus(otherDataset);
          datasets.push(otherDataset);
        }
      }
    }

    // Parse out the costs dataset that does not correspond whether we're displaying cumulative or not.
    // We add the dataset originally, to properly calculate the Other dataset.
    return datasets.filter(
      (dataset) => dataset.dataType !== "costs" || dataset.cumulative === this.displayCumulative()
    );
  }

  generateForecastedDatasets(
    forecastedData,
    costData,
    binnedCostData,
    cumulativeCostData,
    category
  ) {
    if (!forecastedData) return [];

    let forecastedDatasets = [];
    const binnedForecastedData = this.binForecastData(forecastedData);

    if (this.chartType === "bar" && !this.displayCumulative()) {
      const barChartForecastData = binnedForecastedData.map((point) => {
        return {
          x: point.x,
          y: point.y["yhat"],
          yMin: point.y["yhat_lower"],
          yMax: point.y["yhat_upper"],
        };
      });

      let forecastDataset = {
        label: this.forecastLabel(category),
        data: barChartForecastData,
        dataIdentifier: category,
        dataType: "forecast",
        cumulative: false,
        category: "Forecasted Median",
        type: "barWithErrorBars",
        ...this.styles,
        errorBarColor: { v: ["#2c2c2c", "#1f1f1f"] },
      };

      forecastDataset.hidden = this.hiddenStatus(forecastDataset);
      forecastedDatasets.push(forecastDataset);
    } else {
      const forecastStyles = { ...this.styles, borderDash: [5, 5] };
      const forecastMeasurements = ["yhat", "yhat_lower", "yhat_upper"];
      forecastMeasurements.forEach((measurement) => {
        let measurementData = binnedForecastedData.map((point) => {
          return {
            x: point.x,
            y: point.y[measurement],
          };
        });

        if (measurement !== "yhat") {
          delete forecastStyles.borderDash;
          forecastStyles.pointRadius = 0.2;
          forecastStyles.borderWidth = 0.2;
          forecastStyles.backgroundColor = "rgb(156, 36, 199, 0.075)";
          forecastStyles.fill = "-1";
        }

        const isCumulative = this.chartType !== "bar" ? this.displayCumulative() : true;
        if (isCumulative) {
          if (cumulativeCostData && cumulativeCostData.length > 0) {
            let base = Object.assign({}, cumulativeCostData[costData.length - 1]);
            measurementData = this.makeCumulative(measurementData, base.y);
            base.category = false;
            // Necessary to overlap first forecasted datapoint to last current period datapoint to prevent a gap in a line chart.
            measurementData.unshift(base);
          } else {
            measurementData = this.makeCumulative(measurementData);
          }
        } else {
          measurementData.unshift(binnedCostData[binnedCostData.length - 1]);
        }

        measurementData[0].hiddenFromTooltip = true;

        let forecastDataset = {
          label: this.forecastLabel(category),
          data: measurementData,
          dataIdentifier: category,
          dataType: "forecast",
          cumulative: isCumulative,
          type: this.chartType,
          ...forecastStyles,
        };

        if (measurement === "yhat") {
          forecastDataset.category = "Forecast Median";
        }

        forecastDataset.hidden = this.hiddenStatus(forecastDataset);
        forecastedDatasets.push(forecastDataset);
      });
    }

    return forecastedDatasets;
  }

  generateSecondAxisDatasets(allCosts) {
    let secondAxisDataStyles = Object.assign({}, this.styles);
    let order = -9999;

    let datasets = [];
    let colorIndex = 0;
    Object.keys(this.secondAxisData).forEach((key) => {
      let { data, scale, label } = this.secondAxisData[key];
      data = this.sortByDate(data);
      let binnedData = {};
      data.map((d) => {
        binnedData[d.x] = d.y;
      });

      let datasetData = binnedData;
      if (scale) {
        datasetData = Object.keys(binnedData).map((date) => {
          let unitCost = 0;
          if (binnedData[date] > 0 && allCosts[date]) {
            unitCost = (allCosts[date] / binnedData[date]) * scale;
          }

          return {
            x: date,
            y: unitCost,
          };
        });
      } else {
        datasetData = Object.keys(binnedData).map((date) => {
          return {
            x: date,
            y: binnedData[date],
          };
        });
      }

      secondAxisDataStyles.borderColor = this.secondaryColorsValue[colorIndex];
      secondAxisDataStyles.backgroundColor = this.secondaryColorsValue[colorIndex];

      colorIndex = (colorIndex + 1) % this.secondaryColorsValue.length;

      secondAxisDataStyles.fill = false;

      let dataset = {
        label,
        data: datasetData,
        dataIdentifier: GRAPH_ALL_KEY,
        dataType: "secondAxis",
        category: label,
        cumulative: false,
        yAxisID: "y1",
        type: "line",
        order,
        ...secondAxisDataStyles,
      };

      order = order - 1;
      dataset.hidden = this.hiddenStatus(dataset);
      datasets.push(dataset);
    });

    return datasets;
  }

  generateCoverageDataset() {
    const { data, label } = this.coverageData;

    const coverageStyles = {
      ...this.styles,
      fill: "origin",
      backgroundColor: "rgba(192, 85, 230, 0.2)",
      borderColor: "rgba(192, 85, 230, 1)",
    };

    return {
      label,
      data,
      dataType: "coverage",
      yAxisID: "y",
      type: "line",
      ...coverageStyles,
    };
  }

  generatePercentageAxisDatasets() {
    const percentageAxisData = this.percentageAxisData;
    const colors = ["#3498db", "#e74c3c", "#34495e", "#f1c40f", "#2ecc71", "#f54269"];

    const percentageAxisStyles = { ...this.styles, fill: "none" };
    let order = -9999;
    let datasets = [];

    Object.keys(percentageAxisData).forEach((key, index) => {
      let { data, label } = percentageAxisData[key];
      const lineColor = colors[index % colors.length];
      datasets.push({
        label,
        data: this.sortByDate(data),
        dataType: "percentageAxis",
        dataIdentifier: label,
        category: label,
        cumulative: false,
        yAxisID: "y2",
        type: "line",
        order,
        ...percentageAxisStyles,
        borderColor: lineColor,
        backgroundColor: lineColor,
      });
      order = order - 1;
    });

    return datasets;
  }

  generateBudgetDatasets(budgetData) {
    const currentPeriodBudgetStyles = {
      ...this.styles,
      borderColor: "#FFDF35",
      backgroundColor: "#FFDF35",
      fill: "none",
    };

    const forecastPeriodBudgetStyles = {
      ...currentPeriodBudgetStyles,
      borderDash: [5, 5],
    };

    budgetData = this.displayCumulative()
      ? this.makeCumulative(budgetData)
      : this.binData(budgetData);

    let currentPeriodBudget = [];
    let forecastedBudget = [];
    budgetData.forEach((datapoint) => {
      if (parseISO(datapoint.x) <= this.currentPeriodMaxDate) {
        currentPeriodBudget.push(datapoint);
      } else {
        forecastedBudget.push(datapoint);
      }
    });

    // Connect the current period and the forecasted period dots
    if (forecastedBudget.length > 0) {
      forecastedBudget = [
        {
          ...currentPeriodBudget[currentPeriodBudget.length - 1],
          hiddenFromTooltip: true,
        },
        ...forecastedBudget,
      ];
    }

    const budgetDatasetConfig = {
      dataIdentifier: GRAPH_ALL_KEY,
      type: "line",
      cumulative: this.displayCumulative(),
    };

    const budgetLabel = "Budget";
    const forecastedBudgetLabel = "Forecasted Budget";
    let budgetDatasets = [];
    if (currentPeriodBudget.length > 0) {
      budgetDatasets.push({
        label: budgetLabel,
        category: budgetLabel,
        data: currentPeriodBudget,
        dataType: "budget",
        ...budgetDatasetConfig,
        ...currentPeriodBudgetStyles,
      });
    }

    if (forecastedBudget.length > 0) {
      budgetDatasets.push({
        label: forecastedBudgetLabel,
        category: forecastedBudgetLabel,
        data: forecastedBudget,
        dataType: "forecastedBudget",
        ...budgetDatasetConfig,
        ...forecastPeriodBudgetStyles,
      });
    }

    return budgetDatasets;
  }

  hiddenStatus(dataset) {
    if (this.disabledLabels.includes(dataset.label)) {
      return true;
    }

    if (
      (dataset.dataIdentifier === GRAPH_ALL_KEY || dataset.grouped) &&
      this.stacked &&
      !this.isSecondaryDataset(dataset) &&
      dataset.dataType !== "forecast"
    ) {
      return true;
    }

    let hidden = true;

    if (this.shownLabel === dataset.dataIdentifier || this.stacked) {
      if (
        !this.displayCumulative() &&
        (["costs", "other-costs", "previous", "forecast"].includes(dataset.dataType) ||
          this.isSecondaryDataset(dataset)) &&
        !dataset.cumulative
      ) {
        hidden = false;
      } else if (this.displayCumulative() && dataset.cumulative) {
        hidden = false;
      }
    }

    return hidden;
  }

  isSecondaryDataset(dataset) {
    return ["coverage", "budget", "forecastedBudget", "secondAxis", "percentageAxis"].includes(
      dataset.dataType
    );
  }

  displayCumulative() {
    return this.mode === "cumulative";
  }

  preview(event, filterTargets = this.filterTargets) {
    let el = event.target;

    if (el.tagName.toLowerCase() === "a" || el.parentElement.tagName.toLowerCase() === "a") {
      return;
    }

    if (!el.dataset.label) {
      el = el.closest("[data-label]");
    }

    let label = el.dataset.label;

    if (el.classList.contains("row-selected")) {
      el.classList.remove("row-selected");
      label = GRAPH_ALL_KEY;
      this.stacked = this.originallyStacked;
    } else {
      filterTargets.forEach((e) => {
        e.classList.remove("row-selected");
      });

      el.classList.add("row-selected");
      this.stacked = false;
    }

    this.shownLabel = label;
    this.updateDatasets();
    this.chart.update("none");

    if (this.stacked) {
      this.renderLegend();
    }
  }

  updateDatasets() {
    this.chart.data.datasets.forEach((dataset) => {
      dataset.hidden = this.hiddenStatus(dataset);
    });
  }

  get chart() {
    return Chart.getChart(this.outputTarget);
  }

  get startDate() {
    return this.data.get("startDate");
  }

  get endDate() {
    return this.data.get("endDate");
  }

  get currentPeriodMaxDate() {
    return parseISO(this.data.get("currentPeriodMaxDate") || new Date().toISOString());
  }

  forecastLabel(label) {
    if (label === GRAPH_ALL_KEY) {
      return "Forecasted Costs";
    } else {
      return "Forecast: " + label;
    }
  }

  previousLabel(label) {
    return "Previous Period: " + label;
  }

  budgetLabel(label) {
    return "Budget: " + label;
  }

  sortByDate(data) {
    return data
      .filter((d) => d)
      .sort((a, b) => {
        return moment(a.x) - moment(b.x);
      });
  }

  binData(data) {
    if (this.mode === "cumulative") {
      return data;
    }

    return Object.values(
      data.reduce((binnedData, d) => {
        let date = this.binDate(d.x);

        let amount = parseFloat(d.y);
        if (binnedData[date]) {
          binnedData[date].y = binnedData[date].y + amount;
        } else {
          binnedData[date] = { x: date, y: amount };
        }
        return binnedData;
      }, {})
    );
  }

  binForecastData(data) {
    data = this.sortByDate(data);
    if (this.mode === "cumulative") {
      return data;
    }

    const binnedData = Object.values(
      data.reduce((binnedData, d) => {
        let date = this.binDate(d.x);

        let { yhat, yhat_lower, yhat_upper } = d.y;
        yhat = parseFloat(yhat);
        yhat_lower = parseFloat(yhat_lower);
        yhat_upper = parseFloat(yhat_upper);

        if (binnedData[date]) {
          binnedData[date].y["yhat"] += yhat;
          binnedData[date].y["yhat_lower"] += yhat_lower;
          binnedData[date].y["yhat_upper"] += yhat_upper;
        } else {
          binnedData[date] = {
            x: date,
            y: {
              yhat,
              yhat_lower,
              yhat_upper,
            },
          };
        }
        return binnedData;
      }, {})
    );

    const currentPeriodBinnedDates = this.inputData[GRAPH_ALL_KEY].map((d) => d.x);
    // Only include forecast data that does not overlap with current period data.
    return binnedData.filter((data) => !currentPeriodBinnedDates.includes(data.x));
  }

  binDate(date) {
    if (this.bin === "week") {
      const firstDayOfWeek = startOfISOWeek(parseISO(date));
      return moment(firstDayOfWeek).format(this.dateFormat);
    } else {
      return moment.utc(date).startOf(this.bin).format(this.dateFormat);
    }
  }

  makeCumulative(data, base) {
    let cumulative = base || 0;

    return this.sortByDate(data).map((point) => {
      let p = Object.assign({}, point);
      cumulative = parseFloat(p.y) + cumulative;
      p.y = cumulative;

      return p;
    });
  }

  gapFill(data, dateRange) {
    let category = data[0].category;

    return dateRange.map((date) => {
      let point = data.find((d) => d.x === date);

      if (!point) {
        point = {
          y: 0,
          x: date,
          category: category,
        };
      }

      return point;
    });
  }

  getLocaleDateFormat() {
    const formatObj = new Intl.DateTimeFormat().formatToParts(new Date());

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  currencyFormat(value, opts = { precision: 2 }) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: opts.precision,
    }).format(value);
  }

  renderLegend() {
    let legendLabels = this.chart.options.plugins.legend.labels.generateLabels(this.chart);
    this.legendTarget.innerHTML = "";

    legendLabels
      .filter((l) => {
        return !l.hidden;
      })
      .sort((a, b) => {
        // Make sure 'Other' is always the last item in the legend
        if (a.text === "Other Costs") return 1;
        if (b.text === "Other Costs") return -1;

        return a.text.localeCompare(b.text);
      })
      .forEach((label) => {
        const labelElement = this.legendTemplate;
        const labelTitle = labelElement.querySelector(".title");
        const mappedLabel = this.mappedLabel(label.text);
        const elementWrapper = labelElement.firstElementChild;

        elementWrapper.dataset.value = label.text;
        elementWrapper.dataset.action = "click->time-series#toggleLegendItem";

        labelElement.querySelector(".color-filled").style.color = label.fillStyle;
        labelElement.querySelector(".color-unfilled").style.color = label.fillStyle;
        labelTitle.innerHTML = mappedLabel;
        labelTitle.setAttribute("title", mappedLabel);

        if (label.text === "Other Costs") {
          this.renderShowMoreMenu(legendLabels, labelElement);
        }

        this.legendTarget.append(labelElement);
      });
  }

  renderShowMoreMenu(legendLabels, labelElement) {
    if (this.legendTarget.querySelector("#showMoreDropdown")) return;

    const showMoreDropdown = this.showMoreDropdownTemplate;
    // "Other" is included in the legendLabels array, so we need to subtract 1
    const remainingLegendItems = legendLabels.length - this.stackCountValue - 1;
    if (remainingLegendItems <= 0 || !showMoreDropdown || !this.stacked) return;

    let addMoreQuantity;
    if (remainingLegendItems < this.stackLimitIncrementValue) {
      addMoreQuantity = remainingLegendItems;
    } else {
      addMoreQuantity = this.stackLimitIncrementValue;
    }

    showMoreDropdown.querySelector(
      "#add-more-button"
    ).innerHTML = `Show ${addMoreQuantity} More Items`;
    showMoreDropdown.querySelector(
      "#add-more-desc"
    ).innerHTML = `This will show ${addMoreQuantity} more items behind "Other Costs" as their own legend and chart item.`;
    labelElement.querySelector("#legend-list-item").style.overflow = "visible";
    const labelElementContent = labelElement.querySelector("#legend-list-item-content");
    labelElementContent.appendChild(showMoreDropdown);
  }

  showMoreCategories() {
    this.stackCountValue += this.stackLimitIncrementValue;
    this.generateChart();
  }
  toggleLegendItem(event) {
    // Prevent toggling the legend item when clicking on Other Dropdown button
    if (document.querySelector("#showMoreDropdown")?.contains(event.target)) return;

    let el = event.currentTarget;
    let labelText = el.dataset.value;
    let alreadyDisabled = this.disabledLabels.includes(labelText);

    if (alreadyDisabled) {
      this.disabledLabels = this.disabledLabels.filter((l) => l !== labelText);
      el.classList.remove("label-disabled");
    } else {
      this.disabledLabels = [...this.disabledLabels, labelText];
      el.classList.add("label-disabled");
    }

    this.updateDatasets();
    this.chart.update("none");
  }

  resetDisabledLabels() {
    this.disabledLabels = [];
    this.updateDatasets();
    this.chart.update("none");
  }

  // Enter event on an annotation line triggers for one annotation line.
  // This method ensure to find other annotation entries that have the same date.
  getAnnotationLines(annotationId) {
    const annotationLine = this.annotationsData.find(
      (annotation) => annotation.id === annotationId
    );
    return this.annotationsData.filter((annotation) => annotation.xMin === annotationLine.xMin);
  }

  highlightAnnotation(event) {
    this.highlightAnnotationLine(event.id);
    const annotations = this.getAnnotationLines(event.id);
    if (annotations.length === 0) return;

    annotations.forEach((annotation) => {
      const annotationTurboFrameEl = document.getElementById(annotation.id);
      if (annotationTurboFrameEl) {
        const annotationContainer = annotationTurboFrameEl.querySelector(".annotation");
        annotationContainer.classList.add("annotation-highlighted");
      }
    });
  }

  unhighlightAnnotation(event) {
    this.unhighlightAnnotationLine(event.id);
    const annotations = this.getAnnotationLines(event.id);
    if (annotations.length === 0) return;

    annotations.forEach((annotation) => {
      const annotationTurboFrameEl = document.getElementById(annotation.id);
      if (annotationTurboFrameEl) {
        const annotationContainer = annotationTurboFrameEl.querySelector(".annotation");
        annotationContainer.classList.remove("annotation-highlighted");
      }
    });
  }

  highlightAnnotationLine(annotationId) {
    const annotations = this.getAnnotationLines(annotationId);
    if (annotations.length === 0) return;

    // We want to highlight the last annotation line added because they're overlayed on top of each other.
    annotationId = annotations[annotations.length - 1].id;
    const borderWidth = annotations.length > 1 ? 3 : 1;
    const annotationLine =
      this.chart.options.plugins.annotation.annotations[`${annotationId}-visible`];
    if (annotationLine) {
      this.chart.canvas.style.cursor = "pointer";
      annotationLine.borderColor = "#C156E7";
      annotationLine.borderWidth = borderWidth;
    }
    this.chart.update("none");
  }

  unhighlightAnnotationLine(annotationId) {
    const annotations = this.getAnnotationLines(annotationId);
    if (annotations.length === 0) return;

    // We want to unhighlight the last annotation line added because they're overlayed on top of each other.
    annotationId = annotations[annotations.length - 1].id;
    const annotationLine =
      this.chart.options.plugins.annotation.annotations[`${annotationId}-visible`];
    if (annotationLine) {
      this.chart.canvas.style.cursor = "default";
      annotationLine.borderColor = "#CFCFD6";
      annotationLine.borderWidth = 1;
    }
    this.chart.update("none");
  }

  createAnnotation(event) {
    if (!this.hasAnnotationsOutlet) return;

    const points = this.chart.getElementsAtEventForMode(
      event,
      "nearest",
      { axis: "x", intersect: false },
      true
    );
    if (points.length === 0) return;

    const closestDate = points[0].element.$context.raw.x;

    this.chart.config.options.plugins.annotation.annotations["annotation-preview"] = {
      type: "line",
      mode: "vertical",
      scaleID: "x",
      value: closestDate,
      borderColor: "magenta",
      borderWidth: 1,
      afterDraw: this.drawAnnotationTriangle,
    };

    this.chart.update("none");
    this.annotationsOutlet.previewDate = closestDate;
    if (this.annotationsOutlet.annotationsSidebarTarget.hidden) {
      this.annotationsOutlet.openAnnotationsSidebar();
    } else {
      this.annotationsOutlet.previewAnnotation();
    }
  }

  removeAnnotationPreview() {
    if (this.chart.config.options.plugins.annotation.annotations["annotation-preview"]) {
      delete this.chart.config.options.plugins.annotation.annotations["annotation-preview"];
      this.chart.update("none");
    }
  }

  // Custom drawing of inverted triangle at the top of the line
  drawAnnotationTriangle(event) {
    const chartInstance = event.chart;
    const ctx = chartInstance.ctx;
    const { x } = event.element.getProps(["x"]);
    const yTop = chartInstance.chartArea.top;

    ctx.save();
    ctx.beginPath();
    ctx.rect(0, 0, chartInstance.width, chartInstance.height);
    ctx.clip();

    ctx.fillStyle = event.element.options.borderColor;
    ctx.beginPath();
    ctx.moveTo(x, yTop + 8);
    ctx.lineTo(x - 10, yTop - 8);
    ctx.lineTo(x + 10, yTop - 8);
    ctx.closePath();
    ctx.fill();
    ctx.restore();
  }

  tooltipConfig() {
    return {
      enabled: !this.externalTooltipTemplate,
      external: this.externalTooltipTemplate
        ? (context) => this.externalTooltip.build(context)
        : () => {},
      intersect: false,
      usePointStyle: true,
      padding: 20,
      bodySpacing: 10,
      itemSort: (a, b) => b.dataset.order - a.dataset.order,
      callbacks: {
        title: () => {
          "";
        },
        label: (context) => {
          if (context.raw?.hiddenFromTooltip) {
            return false;
          }

          let label = this.stacked ? context.dataset.label : context.dataset.category;
          label = this.mappedLabel(label);
          if (!label) {
            return false;
          }

          if (
            context.dataset.category === "Costs" &&
            this.withinIncompleteCostWindow(context.raw.x)
          ) {
            label = `${label} (Incomplete)`;
          }

          let date = context.raw.originalX || context.raw.x;
          date = moment(date).format(this.getLocaleDateFormat());

          let value;
          if (context.dataset.dataType === "secondAxis") {
            const precision = Math.abs(parseFloat(context.parsed.y)) < 1 ? 5 : 2;
            value = this.currencyFormat(context.parsed.y, {
              precision,
            });
          } else if (context.dataset.dataType === "percentageAxis") {
            value = `${(context.parsed.y * 100).toFixed(2)}%`;
          } else if (context.dataset.dataType === "forecast") {
            const { y, yMin, yMax } = context.parsed;
            const tooltipLabel = [`Forecasted Median - ${date}: ${this.currencyFormat(y)}`];
            const hasValidForecastBounds = !isNaN(yMin) && !isNaN(yMax);
            if (hasValidForecastBounds) {
              tooltipLabel.push(`Forecasted Min: ${this.currencyFormat(yMin)}`);
              tooltipLabel.push(`Forecasted Max: ${this.currencyFormat(yMax)}`);
            }
            return tooltipLabel;
          } else {
            value = this.currencyFormat(context.parsed.y);
          }

          return ` ${label} - ${date} : ${value}`;
        },
        footer: (tooltipItems) => {
          if (!this.stacked) return;

          let total = 0;
          tooltipItems.forEach((tooltipItem) => {
            // Make sure to skip over non-cost related datasets (e.g. budget)
            if (this.isSecondaryDataset(tooltipItem.dataset)) return;

            total += tooltipItem.parsed.y;
          });
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          });
          return "Total: " + formatter.format(total);
        },
      },
      footerMarginTop: 12,
    };
  }

  maxSecondaryDatasetValue(datasets) {
    const secondaryData = datasets
      .filter((dataset) => dataset.dataType === "secondAxis")
      .flatMap((dataset) => dataset.data);

    if (secondaryData.length === 0) return;

    const maxData = Math.max(...secondaryData.map((d) => d.y));

    if (maxData >= 1) return;

    // Round up max data point to next magnitude
    return Math.pow(10, Math.ceil(Math.log10(maxData)));
  }

  hasNegativeData(datasets) {
    const visibleData = datasets
      .filter((dataset) => !dataset.hidden)
      .flatMap((dataset) => dataset.data);

    return visibleData.some((d) => d.y < 0);
  }

  chartOptions(datasets) {
    const renderNegativeAxis = this.allowNegativeValue && this.hasNegativeData(datasets);

    return {
      data: { datasets: datasets },
      options: {
        interaction: {
          mode: "nearest",
          intersect: false,
          axis: "x",
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: this.tooltipConfig(),
          annotation: {
            annotations: this.annotations,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0,
          },
          bar: {
            borderWidth: 0,
          },
          line: {
            borderWidth: 2.5,
          },
        },
        scales: {
          x: {
            type: "time",
            min: this.binDate(this.startDate),
            max: this.binDate(this.endDate),
            grid: {
              display: false,
            },
            time: {
              isoWeekday: true,
              unit: this.bin,
              displayFormats: {
                day: this.getLocaleDateFormat(),
              },
            },
          },
          y: {
            stacked: this.stacked && this.data.get("chartType") !== "line",
            grace: "5%",
            min: renderNegativeAxis ? undefined : 0,
            max: datasets.length === 0 ? 1000 : undefined,
            grid: {
              display: true,
              drawBorder: false,
              lineWidth: ({ tick }) => (tick.value == 0 && renderNegativeAxis ? 2 : 1),
            },
            ticks: {
              precision: 0,
              autoSkip: false,
              maxTicksLimit: 5,
              callback: this.currencyFormat,
            },
            gridLines: {
              color: "#D2D4ED",
            },
          },
          y1: {
            display: this.displaySecondAxis(),
            stacked: false,
            position: "right",
            grace: "5%",
            min: 0,
            max: this.maxSecondaryDatasetValue(datasets),
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              precision: 0,
              grid: {
                display: false,
              },
              autoSkip: false,
              maxTicksLimit: 5,
              callback: this.currencyFormat,
            },
          },
          y2: {
            display: this.displayPercentageAxis() && !this.hidePercentageAxisValue,
            stacked: false,
            position: "right",
            beginAtZero: true,
            min: 0,
            max: 1,
            grid: {
              display: false,
            },
            ticks: {
              format: {
                style: "percent",
              },
            },
          },
        },
        onClick: this.allowAnnotatingValue ? this.createAnnotation.bind(this) : () => {},
      },
    };
  }
}
