import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    eventName: String,
  };

  connect() {
    let eventName = this.eventNameValue;

    Array.from(this.element.children).forEach((el) => {
      el.addEventListener(eventName, (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });
  }
}
