import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["form", "submit", "disable", "saveButton"];

  initialize() {
    this.preview = debounce(this.preview, 200).bind(this);
    this.formTarget.addEventListener("change", this.preview.bind(this));
    this.formTarget.addEventListener(
      "turbo:before-fetch-response",
      this.handleResponse.bind(this)
    );
  }

  get turboFrameElement() {
    return document.getElementById(this.turboFrame);
  }

  get turboFrame() {
    return this.data.get("turbo-frame");
  }

  preview(event) {
    this.setBusy();

    let oldAction = this.formTarget.action;
    let method = this.formTarget.querySelector("input[name='_method']");

    if (method) {
      method.value = "post";
    }

    this.formTarget.action = this.data.get("url");

    if (this.turboFrame) {
      this.formTarget.setAttribute("data-turbo", "true");
      this.formTarget.setAttribute("data-turbo-frame", this.turboFrame);
    }

    if (this.hasSubmitTarget) {
      this.submitTarget.click();
    } else {
      this.formTarget.requestSubmit();
    }

    if (this.hasDisableTarget) {
      this.disableTargets.forEach((e) => {
        e.removeAttribute("contenteditable");
        e.disabled = true;
      });
    }

    if (this.turboFrame) {
      this.formTarget.setAttribute("data-turbo", "false");
      this.formTarget.removeAttribute("data-turbo-frame");
    }

    if (method) {
      method.value = "patch";
    }

    this.enableSaveButton();

    this.formTarget.action = oldAction;
  }

  enableSaveButton() {
    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.removeAttribute("disabled");
      this.saveButtonTarget.classList.remove("btn-disabled");
    }
  }

  setBusy() {
    this.turboFrameElement.setAttribute("previewable-busy", "true");
  }

  removeBusy() {
    this.turboFrameElement.removeAttribute("previewable-busy");
  }

  handleResponse(event) {
    this.removeBusy();
  }
}
