import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  initialize() {
    this.check_status();
  }

  async check_status() {
    let response;
    try {
      response = await axios.get(
        `/provider_credentials/${this.id}/access_credentials.json`
      );
    } catch (e) {
      console.error(e);
      // hope this is a transient issue and goes away.
      setTimeout(() => {
        this.check_status();
      }, 3500);
      return;
    }

    const data = response.data;

    if (data.count > this.startingCount) {
      document.location = this.redirectURL;
    } else {
      setTimeout(() => {
        this.check_status();
      }, 3500);
    }
  }

  get startingCount() {
    return parseInt(this.data.get("starting-count"));
  }

  get id() {
    return this.data.get("id");
  }

  get redirectURL() {
    return this.data.get("redirect-url");
  }
}
