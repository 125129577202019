import { Controller } from "@hotwired/stimulus";
import { withMethodMissing } from "unmiss";

@withMethodMissing
export default class ForeignClickController extends Controller {
  initialize() {
    let foreignElement = document.getElementById(this.data.get("id"));
    let controllerName = this.data.get("controller");
    this.controller = this.application.getControllerForElementAndIdentifier(
      foreignElement,
      controllerName
    );
  }
  methodMissing(name, ...args) {
    this.controller[name](...args);
  }
}
