import { Controller } from "@hotwired/stimulus";

// Useful for showing one target element from a list of elements at a time.
export default class extends Controller {
  static targets = ["element"];

  show(event) {
    const index = event.currentTarget.dataset.index;

    this.elementTargets.forEach((element) => {
      if (element.dataset.index === index) {
        element.classList.remove("tw-hidden");
      } else {
        element.classList.add("tw-hidden");
      }
    });
  }
}
