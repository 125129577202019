import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    identifier: {
      type: String,
    },
    limit: {
      type: Number,
      default: 3,
    },
  };

  connect() {
    this.setup();

    this.element.addEventListener(
      "turbo:before-fetch-request",
      this.handleRequest.bind(this)
    );
    this.element.addEventListener(
      "turbo:before-fetch-response",
      this.handleResponse.bind(this)
    );
    this.element.addEventListener(
      "turbo:fetch-request-error",
      this.handleFailedRequest.bind(this)
    );
  }

  get requests() {
    return window._throttledRequests[this.identifierValue];
  }

  overLimit() {
    return this.requests.length > this.limitValue;
  }

  addRequest(request) {
    let requestId = request.detail.url.href;

    if (!this.requests.includes(requestId)) {
      this.requests.push(requestId);
    }
  }

  removeById(responseId) {
    let idx = this.requests.indexOf(responseId);

    if (idx > -1) {
      this.requests.splice(idx);
    }
  }

  setup() {
    if (
      typeof window._throttledRequests === "undefined" ||
      window._throttledRequests === null
    ) {
      window._throttledRequests = {};
    }

    if (typeof this.requests === "undefined" || this.requests === null) {
      window._throttledRequests[this.identifierValue] = [];
    }
  }

  handleRequest(event) {
    event.preventDefault();
    this.addRequest(event);
    this.resumeOrPauseRequest(event);
  }

  resumeOrPauseRequest(event) {
    if (this.overLimit()) {
      setTimeout(() => {
        this.resumeOrPauseRequest(event);
      }, 2000);
    } else {
      event.detail.resume();
    }
  }

  handleFailedRequest(event) {
    let requestId = event.detail.url.href;
    this.removeById(requestId);
  }

  handleResponse(event) {
    let responseId = event.detail.fetchResponse.response.url;
    this.removeById(responseId);
  }
}
