import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  adjustBilling() {
    event.preventDefault();
    $("#adjustBillingModal").modal("show");
  }

  updatePlatformType() {
    event.preventDefault();
    $("#update_vm_form").submit();
  }
}
