export function attachSiteStyles(customElement) {
  customElement.shadowRoot.appendChild(
    customElement.ownerDocument.getElementById("applicationCSS").cloneNode()
  );
  customElement.shadowRoot.appendChild(
    customElement.ownerDocument.getElementById("applicationPackCSS").cloneNode()
  );
  customElement.shadowRoot.appendChild(
    customElement.ownerDocument.getElementById("iconsCSS").cloneNode()
  );
}

const providerTermMap = {
  gcp: {
    account_id: "Project",
    provider_account_id: "Billing Account",
    tag_name: "Label",
    tag_value: "Label Value",
    untagged: "Not Labeled",
    service: "Service",
    region: "Region",
    provider: "Provider",
    category: "Category",
    resource_id: "Resource",
    cost_type: "Charge Type",
    marketplace: "Marketplace",
  },
  aws: {
    account_id: "Account",
    provider_account_id: "Billing Account",
    tag_name: "Tag",
    tag_value: "Tag Value",
    untagged: "Not Tagged",
    service: "Service",
    region: "Region",
    provider: "Provider",
    category: "Category",
    sub_category: "Subcategory",
    marketplace: "Marketplace",
    resource_id: "Resource",
    cost_type: "Charge Type",
  },
  azure: {
    provider_account_id: "Subscription",
    account_id: "Resource Group",
    tag_name: "Tag",
    tag_value: "Tag Value",
    untagged: "Not Tagged",
    service: "Service",
    region: "Region",
    provider: "Provider",
    category: "Category",
    sub_category: "Subcategory",
    resource_id: "Resource",
    cost_type: "Charge Type",
    marketplace: "Marketplace",
  },
  oracle: {
    provider_account_id: "Tenancy",
    account_id: "Compartment",
    tag_name: "Tag",
    tag_value: "Tag Value",
    untagged: "Not Tagged",
    service: "Service",
    region: "Region",
    provider: "Provider",
    category: "Category",
    resource_id: "Resource",
    cost_type: "Charge Type",
  },
  kubernetes: {
    account_id: "Account",
    tag_name: "Grouping",
    tag_value: "Grouping Value",
    service: "Cluster",
    region: "Region",
    provider: "Provider",
    cost_type: "Charge Type",
  },
  fastly: {
    account_id: "Account",
    service: "Service",
    provider: "Provider",
    region: "Region",
    category: "Category",
    sub_category: "Subcategory",
    resource_id: "Resource",
    cost_type: "Charge Type",
    tag_value: "Tag Value",
    tag_name: "Tag",
  },
  snowflake: {
    provider_account_id: "Organization",
    account_id: "Account",
    provider: "Provider",
    region: "Region",
    category: "Category",
    tag_name: "Metadata",
    tag_value: "Metadata Value",
    service: "Service",
    cost_type: "Charge Type",
  },
  datadog: {
    account_id: "Organization",
    service: "Service",
    provider: "Provider",
    tag_name: "Tag",
    tag_value: "Tag Value",
    untagged: "Not Tagged",
    category: "Category",
    sub_category: "Subcategory",
    cost_type: "Charge Type",
  },
  databricks: {
    account_id: "Account",
    service: "Service",
    tag_name: "Tag",
    tag_value: "Tag Value",
    untagged: "Not Tagged",
    category: "Category",
    resource_id: "Cluster",
    cost_type: "Charge Type",
  },
  mongo: {
    provider_account_id: "Organization",
    account_id: "Project",
    service: "Service",
    category: "Category",
    resource_id: "Resource",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  new_relic: {
    account_id: "Account",
    service: "Service",
    category: "Category",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  open_ai: {
    provider_account_id: "Organization",
    service: "Operation",
    category: "Model",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  confluent: {
    provider_account_id: "Organization",
    service: "Service",
    category: "Category",
    resource_id: "Resource",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  planetscale: {
    provider_account_id: "Organization",
    service: "Service",
    category: "Category",
    resource_id: "Resource",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  coralogix: {
    provider_account_id: "Organization",
    service: "Service",
    category: "Category",
    sub_category: "Subcategory",
    resource_id: "Resource",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  github: {
    provider_account_id: "Organization",
    service: "Service",
    category: "Category",
    sub_category: "Subcategory",
    resource_id: "Resource",
    cost_type: "Charge Type",
    tag_name: "Tag",
    tag_value: "Tag Value",
  },
  // TODO(nel): Support fetching these key-value pairs from the server so customers can customize their custom cost labels.
  custom_provider: {
    tag_name: "Tag",
    tag_value: "Tag Value",
    untagged: "Not Tagged",
    service: "Service",
    region: "Region",
    provider: "Provider",
    category: "Category",
    sub_category: "Subcategory",
    resource_id: "Resource",
    cost_type: "Charge Type",
  },
};

const attributeIconMap = {
  account_id: "account_circle",
  provider_account_id: "request_quote",
  tag_name: "label",
  service: "widgets",
  region: "outlined_flag",
  category: "category",
  sub_category: "lan",
  untagged: "label_off",
  marketplace: "store",
  resource_id: "memory",
  cost_type: "payments",
};

export function attributesForProvider(provider) {
  return Object.keys(providerTermMap[provider]);
}

export function labelForAttribute(attribute, provider) {
  let icon = attributeIconMap[attribute];
  // Uppercase first letter of each word.
  let term = termForAttribute(attribute, provider);

  return `<div><span class="material-icons-outlined icon" aria-hidden="true">${icon}</span> <span>${term}</span></div>`;
}

export function termForAttribute(attribute, providerWithAccessCredentialToken) {
  // TODO(nel): allow custom terms for custom costs providers.
  let [provider, _accessCredentialToken] = providerWithAccessCredentialToken.split(":");
  return providerTermMap[provider][attribute];
}

export function labelForMissingValue(attribute, provider) {
  let term = termForAttribute(attribute, provider);
  let seperator = "a";

  if (term == "Account") {
    seperator = "an";
  }

  return `<span class="material-icons-outlined icon" aria-hidden="true">ads_click</span> Select ${seperator} ${term}`;
}

export function placeholderForFilterAttributeValue(attribute, provider) {
  let term = termForAttribute(attribute, provider);
  term = term.toLowerCase();

  return `Find ${term}s...`;
}

export function labelForAddValue() {
  return `<span class="material-icons-outlined icon" aria-hidden="true">add</span>`;
}

export function filtersChangedEvent(name = "filtersetchanged") {
  return new Event(name, { bubbles: true, composed: true });
}

const uniqueIds = new Set();
export function uniqueId() {
  let id = Math.round(Math.random() * 10000000).toString(36);
  if (uniqueIds.has(id)) {
    return uniqueId();
  } else {
    uniqueIds.add(id);
    return id;
  }
}

export function reportUrlFor(action, forAccount, params = {}) {
  if (forAccount) {
    params["for_account"] = 1;
  }
  const queryParams = new URLSearchParams(params).toString();
  return `/reports/${action}${queryParams !== "" ? `?${queryParams}` : ""}`;
}
