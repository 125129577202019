import { Controller } from "@hotwired/stimulus";
import { htmlLegendPlugin } from "./graph-helpers/html-legend-plugin";

import "chartjs-adapter-moment";

import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

import moment from "moment";

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

export default class extends Controller {
  static targets = ["output"];

  initialize() {
    this.generateChart();
  }

  updateChart(evt) {
    this.generateChart();
  }

  generateChart() {
    let datasets = this.generateData();
    let options = this.chartOptions(datasets);

    if (this.chart) {
      this.chart.destroy();
    }

    new Chart(this.outputTarget.getContext("2d"), options);
  }

  get chart() {
    return Chart.getChart(this.outputTarget);
  }

  get inputData() {
    return JSON.parse(this.data.get("input-data"));
  }

  get bin() {
    return this.data.get("bin") || "month";
  }

  get showAxis() {
    return (this.data.get("axis") || true) === true;
  }

  get colorFuture() {
    return (this.data.get("color-future") || true) === true;
  }

  sortByDate(data) {
    return data
      .filter((d) => d)
      .sort((a, b) => {
        return moment(a.x) - moment(b.x);
      });
  }

  generateData() {
    const colors = [
      [156, 36, 199],
      [241, 201, 255],
    ];

    let data;
    if (Array.isArray(this.inputData)) {
      data = {
        "": this.inputData,
      };
    } else {
      data = this.inputData;
    }

    return Object.keys(data).map((key, index) => {
      let color = colors[index];
      let backgroundColors = [];

      let datum = this.sortByDate(data[key]).map((d) => {
        d.category = key;

        if (
          moment.utc().startOf("month").format() === moment.utc(d.x).format() &&
          this.colorFuture
        ) {
          backgroundColors.push(`rgb(${color.join(",")}, 0.8)`);
        } else {
          backgroundColors.push(`rgb(${color.join(",")}, 0.8)`);
        }
        return d;
      });

      let styles = {
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
      };

      return {
        label: key,
        data: datum,
        ...styles,
      };
    });
  }

  getLocaleDateFormat() {
    const formatObj = new Intl.DateTimeFormat().formatToParts(new Date());

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  chartOptions(datasets) {
    let showLegend = datasets.length > 1;

    return {
      data: { datasets: datasets },
      type: "bar",
      options: {
        categoryPercentage: 0.25,
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          htmlLegend: {
            containerID: "html-legend-container",
          },
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: true,
            padding: 20,
            bodySpacing: 10,
            callbacks: {
              label: (context) => {
                let category = context.raw.category;
                let value = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(context.parsed.y);

                if (!category) {
                  return ` ${value}`;
                }

                return ` ${category} : ${value}`;
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            display: this.showAxis,
            grid: {
              display: false,
            },
            type: "time",
            min: this.startDate,
            max: this.endDate,
            time: {
              unit: this.bin,
              displayFormats: {
                month: "MMM YYYY",
              },
              tooltipFormat: "MMM YYYY",
              parser: "YYYY-MM-DD",
            },
          },
          y: {
            stacked: true,
            display: this.showAxis,
            afterFit: function (scale) {
              scale.minWidth = 50;
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              callback: (value, index, values) => {
                return new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  notation: "compact",
                }).format(value);
              },
            },
          },
        },
      },
      plugins: [htmlLegendPlugin],
    };
  }
}
