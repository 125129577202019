export default class ExternalTooltip {
  constructor(tooltipTemplate) {
    if (!tooltipTemplate) {
      console.error("missing tooltip template");
    }

    this.tooltipTemplate = tooltipTemplate.querySelector("#chartjs-external-tooltip");
  }
  build(context) {
    const { chart, tooltip } = context;

    const tooltipEl = this.getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const tableBody = tooltipEl.querySelector("tbody");
    tableBody.innerHTML = "";

    const bodyLines = tooltip.body.map((b) => b.lines);
    bodyLines.forEach((body, i) => {
      if (!body || (Array.isArray(body) && body.length === 0)) return;

      const newTableRow = this.tooltipTemplate.querySelector("tr").cloneNode(true);

      const colors = tooltip.labelColors[i];
      const span = newTableRow.querySelector("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;

      const rowData = newTableRow.querySelector("td");
      const textContainer = document.createElement("div");
      textContainer.classList.add("tw-flex", "tw-flex-col");
      [body].flat().forEach((line) => {
        const textSpan = document.createElement("span");
        const text = document.createTextNode(line);
        textSpan.appendChild(text);
        textContainer.appendChild(textSpan);
      });

      rowData.appendChild(textContainer);

      tableBody.appendChild(newTableRow);
    });

    const footerLines = tooltip.footer.map((f) => f);
    footerLines.forEach((footer, i) => {
      const newTableRow = this.tooltipTemplate.querySelector("tr").cloneNode(true);
      if (i === 0) {
        newTableRow.classList.add("tw-mt-3");
      }
      const span = newTableRow.querySelector("span");
      span.classList = ["tw-font-semibold"];
      span.innerText = footer;
      tableBody.appendChild(newTableRow);
    });

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    const tooltipWidthHalf = Math.max(tooltip.width, 360) / 2;
    const offsetXRight =
      Math.min(window.innerWidth, tooltip.caretX + tooltipWidthHalf) - tooltipWidthHalf;
    const offsetXLeft = Math.max(0, tooltip.caretX - tooltipWidthHalf) + tooltipWidthHalf;
    const offsetX = tooltip.caretX > window.innerWidth / 2 ? offsetXRight : offsetXLeft;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + offsetX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
  }
  getOrCreateTooltip(chart) {
    let tooltipEl = chart.canvas.parentNode.querySelector("#chartjs-external-tooltip");
    if (!tooltipEl) {
      tooltipEl = this.tooltipTemplate.cloneNode(true);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
  }
}
