import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["time-series"];
  static targets = ["filter"];

  preview(event) {
    this.timeSeriesOutlet.preview(event, this.filterTargets);
  }
}
