import { Controller } from "@hotwired/stimulus";
import startCase from "lodash/startCase";

const DateIntervalMap = {
  "Last 7 Days": "last_7_days",
  "Last 30 Days": "last_30_days",
  "This Month": "this_month",
  "Last Month": "last_month",
  "Year To Date": "year_to_date",
  "Last 3 Months": "last_3_months",
  "Last 6 Months": "last_6_months",
  Custom: "custom",
  "Last 12 Months": "last_12_months",
  "Last 24 Months": "last_24_months",
  "Last 36 Months": "last_36_months",
};

export default class extends Controller {
  static values = {
    singleDatePicker: { type: Boolean, default: false },
    updateQueryParams: { type: Boolean, default: true },
  };

  static targets = ["input", "label", "start", "end", "dateInterval", "dateRangeLabel"];

  initialize() {
    const start = moment.utc(this.data.get("start"));
    const end = moment.utc(this.data.get("end"));
    const min = this.data.get("min") ? moment.utc(this.data.get("min")) : undefined;
    const max = moment.utc(this.data.get("max"));
    const dateInterval = this.data.get("dateInterval");

    let dateRangeObj = JSON.parse(this.data.get("dateRanges"));
    let dateRanges = {};
    for (let key in dateRangeObj) {
      dateRanges[startCase(key)] = dateRangeObj[key].map((date) => moment.utc(date));
    }

    let ranges = {};
    if (this.monthly()) {
      const min = moment.utc(this.data.get("min"));
      const max = moment.utc(this.data.get("max"));
      // Clone the min date so we can start modifying it
      let currentMonth = min.clone().startOf("month");

      // Iterate through each month until we reach the max date
      while (currentMonth.isSameOrBefore(max)) {
        const monthName = currentMonth.format("MMM YYYY");
        // Add the month range to the object
        ranges[monthName] = [
          currentMonth.clone().startOf("month"),
          currentMonth.clone().endOf("month"),
        ];
        // Move to the next month
        currentMonth.add(1, "month");
      }
    } else if (this.singleDatePickerValue) {
      ranges = undefined;
    } else {
      ranges = dateRanges;
    }

    $(this.inputTarget).daterangepicker(
      {
        startDate: start,
        endDate: end,
        minDate: min,
        maxDate: max,
        ranges: ranges,
        showCustomRangeLabel: false,
        singleDatePicker: this.singleDatePickerValue,
        alwaysShowCalendars: !this.monthly(),
        opens: this.data.get("position") || "right",
      },
      this.display.bind(this)
    );

    this.setDatePickerLabel(start, end, dateInterval);

    if (this.startTarget.form) {
      this.startTarget.addEventListener("change", (e) => {
        e.target.form.dispatchEvent(new Event("change"));
      });
    }
  }

  get range() {
    return this.data.get("range") === "true";
  }

  get granularity() {
    let granularity = this.data.get("granularity");

    if (granularity === "monthly") {
      return "monthly";
    }

    return "daily";
  }

  monthly() {
    return this.granularity === "monthly";
  }

  display(start, end, event) {
    if (event instanceof Event) {
      event.preventDefault();
    }
    const dateInterval = event || "Custom";
    this.setDatePickerLabel(start, end, dateInterval);
    this.setFormData(start, end, dateInterval);
    this.startTarget.dispatchEvent(new Event("change"));
    if (this.hasEndTarget) {
      this.endTarget.dispatchEvent(new Event("change"));
    }
    if (this.hasDateIntervalTarget) {
      this.dateIntervalTarget.dispatchEvent(new Event("change"));
    }
    this.updateQueryParams(start, end, dateInterval);
  }

  updateQueryParams(start, end, dateInterval) {
    if (!this.updateQueryParamsValue) return;

    const queryParams = new URLSearchParams(window.location.search);
    const format = "YYYY-MM-DD";

    if (this.hasDateIntervalTarget && dateInterval !== "Custom") {
      queryParams.delete("start_date");
      queryParams.delete("end_date");
      queryParams.set("date_interval", this.getDateIntervalKey(dateInterval));
    } else {
      queryParams.set("start_date", start.format(format));
      queryParams.set("end_date", end.format(format));
      queryParams.set("date_interval", this.getDateIntervalKey(dateInterval));
    }

    history.pushState(null, null, "?" + queryParams.toString());
  }

  getDateIntervalKey(dateInterval) {
    const datePickerLabel = dateInterval || "This Month";
    return DateIntervalMap[datePickerLabel];
  }

  setFormData(start, end, dateInterval) {
    this.startTarget.value = start.format();
    if (this.hasEndTarget) {
      this.endTarget.value = end.format();
    }
    if (this.hasDateIntervalTarget) {
      this.dateIntervalTarget.value = this.getDateIntervalKey(dateInterval);
    }
  }

  setDatePickerLabel(start, end, dateInterval) {
    if (!DateIntervalMap[dateInterval]) {
      dateInterval =
        Object.keys(DateIntervalMap).find((key) => DateIntervalMap[key] === dateInterval) ||
        dateInterval;
    }

    if (this.monthly()) {
      this.labelTarget.innerHTML = start.format("MMMM YYYY");
    } else if (this.singleDatePickerValue) {
      const format = "MMM D, YYYY";
      this.labelTarget.innerHTML = start.format(format);
    } else {
      this.labelTarget.innerHTML = dateInterval || "This Month";
      const format = start.year() === end.year() ? "MMM D" : "MMM D, YYYY";
      this.dateRangeLabelTarget.innerHTML = `${start.format(format)} - ${end.format(format)}`;
    }
  }
}
