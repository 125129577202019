import { BaseRule } from "./base-rule";
import * as helpers from "./helpers";

export class BooleanRule extends BaseRule {
  get dataValueProp() {
    return "value";
  }

  renderLeftHandSide() {
    let pill = document.createElement("div");
    pill.classList.add("btn", "btn-tertiary", "btn-sm", "ml-2");
    (pill.innerHTML = this.labelForAttribute()), this.attr;
    this.container.appendChild(pill);
  }

  renderRightHandSide() {
    this.createNewValueControl();
  }

  renderComparator() {
    let comparatorDropdownContainer = document.createElement("div");
    comparatorDropdownContainer.classList.add("ml-2", "mt-1");
    comparatorDropdownContainer.innerText = "purchases";
    this.container.appendChild(comparatorDropdownContainer);
  }

  labelFor(value) {
    if (value === "true") {
      return "Only";
    } else {
      return "Excluded";
    }
  }

  labelForMissingValue() {
    return "Excluded";
  }

  labelForSelected() {
    if (this.dataValues[0] === "true") {
      return "Only";
    } else {
      return "Excluded";
    }
  }

  createNewValueControl() {
    if (this.dataValues.length == 0) {
      this.dataValues.push("false");
    }

    let addValueDiv = document.createElement("div");
    addValueDiv.classList.add("dropdown");
    let buttonId = helpers.uniqueId();

    if (this.dataValues.length === 0) {
      addValueDiv.innerHTML = `<button id="select-value-${buttonId}" class="btn btn-outline btn-sm ml-2" aria-expanded="false">${this.labelForMissingValue()}</button>
               <div class="dropdown-menu" aria-labelledby="select-value-${buttonId}"></div>`;
    } else {
      addValueDiv.innerHTML = `<button id="select-value-${buttonId}" class="btn btn-outline btn-sm ml-2" aria-expanded="false">${this.labelForSelected()}</button>
               <div class="dropdown-menu" aria-labelledby="select-value-${buttonId}"></div>`;
    }

    this.filterableList = document.createElement("filterable-list");
    this.filterableList.placeholder =
      helpers.placeholderForFilterAttributeValue(
        this.attribute,
        this.selectedProvider
      );
    addValueDiv.lastElementChild.appendChild(this.filterableList);

    this.valueDropdownTrigger = addValueDiv.firstElementChild;
    this.valueDropdown = addValueDiv.lastElementChild;
    this.valueFilter = addValueDiv.lastElementChild.firstElementChild;
    this.valueFilter.addEventListener("keyboardblur", () => {
      this.hideValueDropdown();
      this.valueDropdownTrigger.focus();
    });

    this.filterableList.init([
      {
        label: "Excluded",
        value: false,
      },
      {
        label: "Only",
        value: true,
      },
    ]);

    this.valueDropdownTrigger.addEventListener(
      "click",
      this.toggleValueDropdown.bind(this)
    );
    this.container.appendChild(addValueDiv);
  }

  updateNewValueControl() {
    this.valueDropdownTrigger.innerText = this.labelForSelected();
  }

  updateComparatorOrValue(event) {
    let target =
      event.target.parentElement && event.target.parentElement.tagName === "A"
        ? event.target.parentElement
        : event.target;

    if (target.dataset.value) {
      event.preventDefault();

      if (!this.hasValue(target.dataset.value)) {
        this.dataValues = [target.dataset.value];
        this.filterableList.removeItem(target.dataset.value);
      }

      this.updateNewValueControl();

      // The detail in this situation is the number of mouse clicks. Zero
      // means that we used a keyboard instead of a mouse to cause the click.
      if (event.detail === 0) {
        this.valueDropdownTrigger.focus();
      }
      this.dispatchEvent(helpers.filtersChangedEvent());
    }
  }
}
