import { Controller } from "@hotwired/stimulus";

// Update a given element's innerHTML with the filename of a form's uploaded file.
export default class extends Controller {
  static targets = ["element"];

  update(event) {
    const fileElement = event.currentTarget;
    this.elementTarget.textContent = fileElement.files[0].name;
  }
}
