import { Controller } from "@hotwired/stimulus";

import Tribute from "tributejs";

export default class extends Controller {
  static targets = [
    "input",
    "formInput",
    "container",
    "selectTemplate",
    "menuItemTemplate",
    "noMatchTemplate",
    "link",
  ];
  static values = {
    options: Array,
    singleSelect: Boolean,
    showOnFocus: { type: Boolean, default: true },
  };

  connect() {
    this.initializeTribute();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  initializeTribute() {
    this.regexPattern = new RegExp(this.data.get("regexPattern"));
    const collection = this.data.get("regexPattern")
      ? this.setupValidatedCollection()
      : this.setupCollection(this.optionsValue);

    this.tribute = new Tribute(collection);
    this.tribute.attach(this.inputTarget);
    this.updateForm();
  }

  addEventListeners() {
    this.boundTributeReplaced = this.handleTributeReplaced.bind(this);
    this.inputTarget.addEventListener("blur", this.handleBlur.bind(this));
    this.inputTarget.addEventListener(
      "tribute-replaced",
      this.boundTributeReplaced
    );
    this.inputTarget.addEventListener("input", this.updateForm.bind(this));
    this.inputTarget.addEventListener(
      "keydown",
      this.deleteLastValue.bind(this)
    );
    this.inputTarget.addEventListener(
      "click",
      this.handleClickInside.bind(this)
    );
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  removeEventListeners() {
    this.inputTarget.removeEventListener(
      "tribute-replaced",
      this.boundTributeReplaced
    );
    this.inputTarget.removeEventListener("blur", this.handleBlur.bind(this));
  }

  handleBlur() {
    if (this.tribute.isActive) this.tribute.hideMenu();
  }

  handleTributeReplaced(e) {
    if (this.singleSelectValue) this.clearAllBadges();
    this.insertBadge(e.detail.item);
    if (this.hasLinkTarget) this.updateLinkTarget(e.detail.item);
    this.removeTextNode();
    this.cleanupBadges();
    this.updateForm();
  }

  cleanupBadges() {
    // Remove stray text nodes. These get created every time a user taps enter.
    this.inputTarget.childNodes.forEach((node) => {
      if (node.nodeType === 3 && !node.textContent.trim()) {
        node.remove();
      }
    });
  }

  handleClickInside() {
    if (!this.tribute.isActive) this.showAllValuesOnFocus();
  }

  handleClickOutside(event) {
    const menuElement = document.querySelector(".tribute-container");
    if (
      !this.inputTarget.contains(event.target) &&
      (!menuElement || !menuElement.contains(event.target))
    ) {
      this.tribute.hideMenu();
    }
  }

  updateLinkTarget(item) {
    if (!item || !this.hasLinkTarget) return;
    this.linkTarget.innerHTML = `<a href="${item.original.url}" class='Link--primary small d-inline-block'>Go to ${item.original.key}</a>`;
  }

  getSelectTemplate(item) {
    return this.selectTemplateTarget.innerHTML
      .replace("{{original_value}}", item.original.value)
      .replace("{{item}}", item.string)
      .replace("{{original_url}}", item.original.url);
  }

  setupValidatedCollection() {
    return {
      ...this.setupCollection([]),
      trigger: null,

      // Validate
      values: (input, items) => {
        const inputValue = input.trim();
        const isValidInput = this.regexPattern.test(inputValue);

        if (inputValue && isValidInput) {
          const newItem = { key: inputValue, value: inputValue };

          const collection = Array.isArray(items)
            ? [...items, newItem]
            : [newItem];

          items(collection);
        }
      },

      selectTemplate: function (item) {
        let template = this.selectTemplateTarget.innerHTML
          .replace("{{original_value}}", item.original.key)
          .replace("{{item}}", item.string);

        if (item.original.url) {
          template = template.replace("{{url}}", item.original.url);
        } else {
          template = template.replace("{{url}}", "");
        }

        return template;
      }.bind(this),
    };
  }

  // There is weirdness around deleting the last value from a content editable div
  // this brute force deletes the last item.
  deleteLastValue(event) {
    // Check if the pressed key is backspace or delete
    let keycode = event.keyCode;
    if (keycode !== 8 && keycode !== 46) return;

    // Collect the badges
    let elements = [...this.inputTarget.querySelectorAll("span")];
    if (!elements.length) return;

    // Setup cursor position
    const sel = window.getSelection();
    const cursorPosition = sel.focusOffset;

    // If single select select, just remove the single badge if backspace/delete is pressed
    if (this.singleSelectValue) {
      let element = this.inputTarget.querySelector("span");
      if (element && cursorPosition === 0) {
        element.parentNode.removeChild(element);
      }
      if (this.hasLinkTarget && !element) {
        this.clearLinkTarget();
      }
    } else {
      // If multi-select, only remove a badge if the cursor is right after it
      if (elements.length == 1) {
        let e = elements[0];
        e.parentNode.removeChild(e);
      }
    }

    if (this.hasLinkTarget) {
      this.clearLinkTarget();
    }

    this.updateForm();
  }

  clearLinkTarget() {
    if (this.hasLinkTarget) {
      this.linkTarget.innerHTML = "";
    }
  }

  showAllValuesOnFocus() {
    if (!this.showOnFocusValue || this.tribute.isActive) {
      return;
    }

    this.tribute.showMenuForCollection(this.inputTarget);
  }

  clearAllBadges() {
    this.inputTarget
      .querySelectorAll("span")
      .forEach((badge) => badge.remove());
  }

  insertBadge(item) {
    const badgeHTML = this.getSelectTemplate(item);
    const template = document.createElement("div");
    template.innerHTML = badgeHTML;

    this.singleSelectValue
      ? (this.inputTarget.innerHTML = badgeHTML)
      : this.inputTarget.appendChild(template.firstChild);
  }

  removeTextNode() {
    this.inputTarget.childNodes.forEach(
      (node) => node.nodeType === 3 && node.remove()
    );
  }

  updateForm() {
    let ids = [];

    if (this.singleSelectValue) {
      let selectedSpan = this.inputTarget.querySelector("span");
      ids.push(selectedSpan ? selectedSpan.dataset.id : "");
    } else {
      ids = [...this.inputTarget.querySelectorAll("span")]
        .map((i) => i.dataset.id)
        .filter((i) => i)
        .filter((value, index, self) => self.indexOf(value) === index); // Unique values only
    }

    let input = this.formInputTarget;
    if (this.formInputTarget.tagName === "DIV") {
      input = this.formInputTarget.querySelector('input[type="hidden"]');
    }

    input.value = ids.join(",");
    const event = new Event("change");
    input.dispatchEvent(event);
  }

  setupCollection(values) {
    return {
      // turn tribute into an autocomplete
      autocompleteMode: true,
      trigger: null,

      // class added to the menu container
      containerClass: "tribute-container dropdown-menu",

      // class added to each list item
      itemClass: "",

      // function called on select that returns the content to insert
      selectTemplate: function (item) {
        let template = this.selectTemplateTarget.innerHTML
          .replace("{{original_value}}", item.original.value)
          .replace("{{item}}", item.string);

        // Replace the url placeholder if it exists in the item, otherwise remove the placeholder from the template.
        if (item.original.url) {
          template = template.replace("{{original_url}}", item.original.url);
        } else {
          template = template.replace('data-url="{{original_url}}"', "");
        }

        return template;
      }.bind(this),

      // template for displaying item in menu
      menuItemTemplate: function (item) {
        return this.menuItemTemplateTarget.innerHTML.replace(
          "{{item}}",
          item.string
        );
      }.bind(this),

      // template for when no match is found (optional),
      // If no template is provided, menu is hidden.
      noMatchTemplate: function () {
        if (this.hasNoMatchTemplateTarget) {
          return this.noMatchTemplateTarget.innerHTML.replace(
            "{{item}}",
            this.inputTarget.innerText
          );
        }

        return "<li class='text-center'>No matches found</li>";
      }.bind(this),

      // specify an alternative parent container for the menu
      // container must be a positioned element for the menu to appear correctly ie. `position: relative;`
      // default container is the body
      menuContainer: this.containerTarget,

      // column to search against in the object (accepts function or string)
      lookup: "key",

      // column that contains the content to insert by default
      //fillAttr: 'key',

      // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
      values: values,

      // When your values function is async, an optional loading template to show
      loadingItemTemplate: null,

      // specify whether a space is required before the trigger string
      //requireLeadingSpace: true,

      // specify whether a space is allowed in the middle of mentions
      allowSpaces: false,

      // optionally specify a custom suffix for the replace text
      // (defaults to empty space if undefined)
      replaceTextSuffix: "\n",

      // specify whether the menu should be positioned.  Set to false and use in conjuction with menuContainer to create an inline menu
      // (defaults to true)
      positionMenu: false,

      // when the spacebar is hit, select the current match
      spaceSelectsMatch: false,

      // Customize the elements used to wrap matched strings within the results list
      // defaults to <span></span> if undefined
      searchOpts: {
        pre: "<span>",
        post: "</span>",
        skip: false, // true will skip local search, useful if doing server-side search
      },

      // Limits the number of items in the menu
      menuItemLimit: 25,

      // specify the minimum number of characters that must be typed before menu appears
      menuShowMinLength: 0,
    };
  }
}
