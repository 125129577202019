import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "card",
    "errors",
    "form",
    "customerName",
    "paymentMethodId",
  ];
  static values = { apiKey: String };

  connect() {
    this.stripe = Stripe(this.apiKeyValue);
    this.card = this.stripe.elements().create("card", {
      style: {
        base: {
          color: "#495057",
          fontFamily: '"Inter", sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "14px",
          "::placeholder": {
            color: "#6C757D",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    });
    this.card.mount(this.cardTarget);

    // Real time validation from the card Element.
    this.card.on(
      "change",
      function (event) {
        if (event.error) {
          this.errorsTarget.textContent = event.error.message;
          this.errorsTarget.hidden = false;
        } else {
          this.errorsTarget.hidden = true;
          this.errorsTarget.textContent = "";
        }
      }.bind(this)
    );

    this.formTarget.addEventListener(
      "submit",
      function (event) {
        event.preventDefault();

        let params = {
          type: "card",
          card: this.card,
          billing_details: {
            name: this.customerNameTarget.value,
          },
        };

        this.stripe.createPaymentMethod(params).then(
          function (result) {
            if (result.error) {
              this.errorsTarget.textContent = result.error.message;
            } else {
              this.paymentMethodIdTarget.value = result.paymentMethod.id;
              this.formTarget.submit();
            }
          }.bind(this)
        );
      }.bind(this)
    );
  }
}
