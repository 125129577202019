import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    max: Number,
    inputName: String,

    // This is a comma separated list of the values that don't support multiple selections.
    singleGroupingOptions: {
      type: Array,
      default: ["folder_id"],
    },
  };
  static targets = ["ungrouped", "tags", "label", "tagCheckbox"];

  initialize() {
    this.checkboxInputs.forEach((el) => {
      el.addEventListener("change", this.handleGroupingSelection.bind(this));
    });

    this.sortTagValues();
    this.setGroupingsLabel();
    this.setGroupingsDisabledState();
  }

  get checkboxInputs() {
    return Array.from(this.element.querySelectorAll('input[type="checkbox"]'));
  }

  get groupingInputs() {
    if (this.hasTagCheckboxTarget) {
      return this.checkboxInputs.filter((el) => el !== this.tagCheckboxTarget);
    } else {
      return this.checkboxInputs;
    }
  }

  get selectedGroupingInputs() {
    return this.groupingInputs.filter((el) => el.checked);
  }

  get tagInputs() {
    if (this.hasTagsTarget) {
      return Array.from(this.tagsTarget.querySelectorAll("input[type='checkbox']"));
    } else {
      return [];
    }
  }

  get singleGroupingInputs() {
    return this.groupingInputs.filter((el) => this.singleGroupingOptionsValue.includes(el.value));
  }

  get selectedTagInputs() {
    return this.tagInputs.filter((el) => el.checked);
  }

  get isTagSelected() {
    return this.tagInputs.some((el) => el.checked);
  }

  handleUngroupedSelection(event) {
    if (event.target.checked) {
      this.checkboxInputs.forEach((el) => {
        if (el !== this.ungroupedTarget) {
          el.checked = false;
        }
      });

      this.updateQueryParams();
      this.setGroupingsLabel();
      this.enableGroupings();
    } else {
      event.preventDefault();
      event.target.checked = true;
    }
  }

  handleGroupingSelection(event) {
    // Ungrouped selection is handled separately
    if (event.target === this.ungroupedTarget) return;

    if (this.singleGroupingOptionsValue.includes(event.target.value)) {
      if (event.target.checked) {
        this.checkboxInputs.forEach((el) => {
          if (el !== event.target) {
            el.checked = false;
          }
        });
      }
    } else if (event.target.checked) {
      this.ungroupedTarget.checked = false;
      this.singleGroupingInputs.forEach((el) => (el.checked = false));
    }

    if (event.target.checked && !this.singleGroupingOptionsValue.includes(event.target.value)) {
    }

    if (this.selectedGroupingInputs.length === 0) {
      this.ungroupedTarget.checked = true;
    }

    if (this.selectedTagInputs.length === 0) {
      if (this.hasTagCheckboxTarget) {
        this.tagCheckboxTarget.checked = false;
      }
    }

    this.updateQueryParams();
    this.setGroupingsLabel();
    this.setGroupingsDisabledState();

    if (this.isTagSelected) {
      this.tagCheckboxTarget.checked = true;
    }
  }

  updateQueryParams() {
    const params = new URLSearchParams(window.location.search);

    params.delete(this.inputNameValue);

    this.checkboxInputs.forEach((el) => {
      if (el.checked) {
        params.append(this.inputNameValue, el.value);
      }
    });

    history.pushState(null, null, "?" + params.toString());
  }

  sortTagValues() {
    if (!this.hasTagsTarget) return;

    const tags = Array.from(this.tagsTarget.querySelectorAll("li"));

    tags.sort((a, b) => {
      const checkboxA = a.querySelector("input[type='checkbox']");
      const checkboxB = b.querySelector("input[type='checkbox']");
      return checkboxB.checked - checkboxA.checked;
    });

    tags.forEach((tag) => {
      this.tagsTarget.appendChild(tag);
    });
  }

  unselectTag(event) {
    this.tagInputs.forEach((el) => {
      if (el.checked) {
        el.click();
      }
    });
  }

  setGroupingsLabel() {
    const selectedGroupings = this.groupingInputs.filter((el) => el.checked);

    if (selectedGroupings.length === 1) {
      this.labelTarget.innerHTML = selectedGroupings[0].getAttribute("data-label");
    } else if (selectedGroupings.length > 1) {
      this.labelTarget.innerHTML = selectedGroupings.length;
    } else {
      this.labelTarget.innerHTML = "Ungrouped";
    }
  }

  // This handles disabling the grouping checkboxes that cannot be selected because we've reached the limit
  disableGroupings() {
    this.checkboxInputs.forEach((el) => {
      // Don't disable the tag checkbox if a tag is selected
      if (this.hasTagCheckboxTarget && el === this.tagCheckboxTarget && this.isTagSelected) return;

      if (el !== this.ungroupedTarget && !el.checked) {
        el.disabled = true;
        el.closest("label").classList.add("disabled");
      }
    });
  }

  enableGroupings() {
    this.checkboxInputs.forEach((el) => {
      el.disabled = false;
      el.closest("label").classList.remove("disabled");
    });
  }

  resetAllCheckboxes() {
    this.checkboxInputs.forEach((el) => {
      el.checked = false;
      el.disabled = false;
      el.closest("label").classList.remove("disabled");
    });
  }

  setGroupingsDisabledState() {
    const selectedGroupings = this.groupingInputs.filter((el) => el.checked);
    const selectedSingleGroupingInputs = this.singleGroupingInputs.filter((el) => el.checked);
    if (selectedGroupings.length >= this.maxValue || selectedSingleGroupingInputs.length > 0) {
      this.disableGroupings();
    } else {
      this.enableGroupings();
    }
  }

  selectSingleGrouping(event) {
    event.preventDefault();
    event.stopPropagation();

    this.resetAllCheckboxes();

    const grouping_value = event.target.dataset.value;
    const groupingInput = this.groupingInputs.find((el) => el.value === grouping_value);
    groupingInput.click();
  }
}
