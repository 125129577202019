import { BaseRule } from "./base-rule";
import * as helpers from "./helpers";

export class TagRule extends BaseRule {
  get dataValueProp() {
    return "tag_value";
  }

  get dataTagName() {
    return this._data.tag_name;
  }

  set dataTagName(v) {
    return (this._data.tag_name = v);
  }

  noTagsAvailable() {
    return this.attributeValues.tag_name.length === 0;
  }

  renderLeftHandSide() {
    this.renderTagPill();
    this.tagNameContainer = document.createElement("div");
    this.container.appendChild(this.tagNameContainer);
    if (this.noTagsAvailable()) {
      let tagsEmpty = document.createElement("div");
      tagsEmpty.classList.add("filter-set-empty");
      tagsEmpty.innerHTML = `<span class="material-icons md-small">error</span> <span class="small">No ${this.providerTerm()}s are available.</span>`;
      this.tagNameContainer.appendChild(tagsEmpty);
    } else {
      this.renderSelectTagNameControl();
    }
  }

  renderRightHandSide() {
    if (this.noTagsAvailable()) return;

    super.renderRightHandSide();
  }

  renderTagPill() {
    let pill = document.createElement("div");
    pill.classList.add("btn", "btn-tertiary", "btn-sm", "ml-2");
    pill.innerHTML = helpers.labelForAttribute("tag_name", this.selectedProvider);
    this.container.appendChild(pill);
  }

  renderComparator() {
    if (this.noTagsAvailable()) return;

    super.renderComparator();
  }

  providerTerm() {
    return helpers.termForAttribute("tag_name", this.selectedProvider);
  }

  labelFor(value) {
    return value;
  }

  labelForMissingValue() {
    return helpers.labelForMissingValue("tag_value", this.selectedProvider);
  }

  labelForMissingName() {
    return helpers.labelForMissingValue("tag_name", this.selectedProvider) + " Key";
  }

  renderSelectTagNameControl() {
    let tagNameDropdownContainer = document.createElement("div");
    tagNameDropdownContainer.classList.add("dropdown");
    let buttonId = `select-name-${helpers.uniqueId()}`;
    tagNameDropdownContainer.innerHTML = `<button id="${buttonId}" class="btn btn-outline btn-sm ml-2" aria-expanded="false">${this.labelForMissingName()}</button>
            <div class="dropdown-menu" aria-labelledby="${buttonId}"></div>`;

    let fl = document.createElement("filterable-list");
    fl.placeholder = helpers.placeholderForFilterAttributeValue("tag_name", this.selectedProvider);

    tagNameDropdownContainer.lastElementChild.appendChild(fl);
    this.nameDropdownTrigger = tagNameDropdownContainer.firstElementChild;
    this.nameFilter = tagNameDropdownContainer.lastElementChild.firstElementChild;

    this.nameFilter.addEventListener("keyboardblur", () => {
      this.hideNameDropdown();
      this.nameDropdownTrigger.focus();
    });

    this.nameDropdown = tagNameDropdownContainer.lastElementChild;
    fl.addEventListener("click", this.selectTagName.bind(this));
    this.nameDropdownTrigger.addEventListener("click", this.toggleNameDropdown.bind(this));
    this.tagNameContainer.appendChild(tagNameDropdownContainer);

    fl.init(
      this.attributeValues.tag_name.map((n) => {
        return {
          label: n,
          value: n,
        };
      })
    );

    if (this.dataTagName) {
      this.nameDropdownTrigger.innerText = this.dataTagName;
      this.fetchValues();
    }
  }

  selectTagName(event) {
    event.preventDefault();
    event.stopPropagation();

    this.nameDropdownTrigger.innerText = event.target.dataset.value;
    this.dataTagName = event.target.dataset.value;

    if (this.dataValues.length > 0) {
      this.clearSelected();
    }

    this.hideNameDropdown();
    this.fetchValues();
    this.dispatchEvent(helpers.filtersChangedEvent());
  }

  async fetchValues() {
    // If the user is fast, we might end up with multiple fetch values
    // requests in flight at the same time.  So we abort any previous fetch
    // before starting the next one.
    if (this.abortValuesFetch) {
      this.abortValuesFetch();
    }
    let abortController = new AbortController();
    this.abortValuesFetch = () => abortController.abort();
    this.tagValues = [];
    if (this.filterableList) {
      this.filterableList.loading = true;
    }

    let request = fetch(
      this.reportUrl("tag_values", { provider: this.selectedProvider, name: this.dataTagName }),
      { credentials: "same-origin", signal: abortController.signal }
    );

    try {
      let response = await request;
      this.abortValuesFetch = null;
      this.tagValues = await response.json();
      this.renderValueDropDownContents();
    } catch (e) {
      if (e.name !== "AbortError") {
        throw e;
      } else {
        console.warn("A midflight values fetch was aborted.");
      }
    }
  }

  createNewValueControl() {
    let addValueDiv = document.createElement("div");
    this.addValueDropdown = document.createElement("div");
    this.addValueDropdown.classList.add("dropdown");

    let buttonId = `select-value-${helpers.uniqueId()}`;
    if (this.dataValues.length === 0) {
      this.addValueDropdown.innerHTML = `<button id="${buttonId}" class="btn btn-outline btn-sm ml-2" aria-expanded="false">${this.labelForMissingValue()}</button>
                  <div class="dropdown-menu" aria-labelledby="${buttonId}"></div>`;
    } else {
      this.addValueDropdown.innerHTML = `<button id="${buttonId}" class="btn btn-outline btn-sm ml-2" aria-expanded="false">${helpers.labelForAddValue()}</button>
                  <div class="dropdown-menu" aria-labelledby="${buttonId}"></div>`;
    }

    this.valueDropdown = this.addValueDropdown.lastElementChild;

    this.filterableList = document.createElement("filterable-list");
    this.filterableList.placeholder = "Find tag values...";
    this.filterableList.loaderMarkup = this.loaderMarkup;

    this.addValueDropdown.lastElementChild.appendChild(this.filterableList);

    this.valueDropdownTrigger = this.addValueDropdown.firstElementChild;
    this.valueFilter = this.addValueDropdown.lastElementChild.firstElementChild;
    this.valueFilter.addEventListener("keyboardblur", () => {
      this.hideValueDropdown();
      this.valueDropdownTrigger.focus();
    });

    this.valueDropdownTrigger.addEventListener("click", this.toggleValueDropdown.bind(this));

    this.addValueInput = document.createElement("input");
    this.addValueInput.type = "text";
    this.addValueInput.classList.add("d-none", "form-control", "input-sm", "ml-2", "w-150");
    this.addValueInput.setAttribute("placeholder", "Value...");

    this.valueInputBtn = document.createElement("button");
    this.valueInputBtn.classList.add("d-none", "btn", "btn-sm", "btn-outline", "ml-2");
    this.valueInputBtn.ariaExpanded = "false";
    this.valueInputBtn.innerText = "Add";

    this.valueInputBtn.addEventListener(
      "click",
      ((event) => {
        event.target.dataset.value = this.addValueInput.value;
        this.addValueInput.value = "";
        this.updateComparatorOrValue(event);
      }).bind(this)
    );

    addValueDiv.classList.add("d-flex", "flex-shrink-0");
    addValueDiv.appendChild(this.addValueDropdown);
    addValueDiv.appendChild(this.addValueInput);
    addValueDiv.appendChild(this.valueInputBtn);

    this.container.appendChild(addValueDiv);
  }

  renderValueDropDownContents() {
    this.filterableList.init(
      this.tagValues.map((t) => {
        return {
          label: t,
          value: t,
        };
      })
    );
  }

  toggleNameDropdown(event) {
    event.preventDefault();
    if (event.target.ariaExpanded === "true") {
      this.hideNameDropdown();
    } else {
      this.showNameDropdown();
    }
  }

  hideNameDropdown() {
    if (this.nameDropdownTrigger) {
      this.nameDropdownTrigger.ariaExpanded = "false";
      this.nameDropdown.style.display = "none";
    }
  }

  showNameDropdown() {
    this.nameDropdownTrigger.ariaExpanded = "true";
    this.nameDropdown.style.display = "block";
    this.nameFilter.focus();
  }

  closeDropdowns(event) {
    super.closeDropdowns(event);
    if (!event.composedPath().includes(this.nameDropdownTrigger)) {
      this.hideNameDropdown();
    }
  }

  updateComparatorOrValue(event) {
    let target =
      event.target.parentElement && event.target.parentElement.tagName === "A"
        ? event.target.parentElement
        : event.target;
    if (target.dataset.comparator) {
      event.preventDefault();
      this.dataComparator = target.dataset.comparator;
      this.comparatorSelector.innerText = this.comparatorLabel;
      this.comparatorSelector.focus();
      this.updateComparatorDropOptions();
      this.toggleValueInput();
      this.dispatchEvent(helpers.filtersChangedEvent());
    } else if (target.dataset.value) {
      event.preventDefault();
      if (!this.hasValue(target.dataset.value)) {
        this.dataValues.push(target.dataset.value);
        this.createValuePill(target.dataset.value);
        this.filterableList.removeItem(target.dataset.value);
      }
      this.updateNewValueControl();
      // The detail in this situation is the number of mouse clicks. Zero
      // means that we used a keyboard instead of a mouse to cause the click.
      if (event.detail === 0) {
        this.valueDropdownTrigger.focus();
      }
      this.dispatchEvent(helpers.filtersChangedEvent());
    }
  }

  updateComparatorDropOptions() {
    this.comparatorDropdown.innerHTML = `
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "=" ? "check" : "&nbsp;"
        }</span><span>is</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="!="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "!=" ? "check" : "&nbsp;"
        }</span><span>is not</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="~="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "~=" ? "check" : "&nbsp;"
        }</span><span>contains</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="!~="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "!~=" ? "check" : "&nbsp;"
        }</span><span>does not contain</span></a></li>
        `;
  }
}
