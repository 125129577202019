import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "output"];

  duplicate(evt) {
    evt.preventDefault();

    let template = this.templateTarget.content.cloneNode(true);
    let form = evt.target.closest("form");

    this.outputTarget.prepend(template);
    this.change(form);
  }

  remove(evt) {
    evt.preventDefault();
    let form = evt.target.closest("form");
    evt.target.closest("tr").remove();
    this.change(form);
  }

  change(el) {
    el.dispatchEvent(new Event("change"));
  }
}
