import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import uPlot from "uplot";

export default class extends Controller {
  getSize() {
    return {
      width:
        document.getElementById("resource-request-metrics").offsetWidth - 100,
      height: 200,
    };
  }

  throttle(cb, limit) {
    var wait = false;

    return () => {
      if (!wait) {
        requestAnimationFrame(cb);
        wait = true;
        setTimeout(() => {
          wait = false;
        }, limit);
      }
    };
  }

  set retrieved(value) {
    this.data.set("retrieved", value);
  }

  get retrieved() {
    // Convert to Boolean
    return this.data.get("retrieved") === "true";
  }

  async retrieve_request_metrics() {
    if (!this.retrieved) {
      var s3_bucket_id =
        document.location.pathname.split("/")[
          document.location.pathname.split("/").length - 1
        ];

      var response;
      if (s3_bucket_id.startsWith("prvdr_rsrc")) {
        response = await axios.get(
          document.location.pathname + "/s3_request_metrics"
        );
      } else {
        response = await axios.get(
          "/s3_buckets/" + s3_bucket_id + " /request_metrics"
        );
      }

      document.getElementById("request-metrics-loading").innerText = "";
      const cw_data = response.data["request_metrics"];
      const rates = response.data["egress_rates"];

      for (var i = 0; i < cw_data.length; i++) {
        if (cw_data[i]["data"].length > 0) {
          $("#s3-bucket-request-metrics-container").append(
            "<h4>" +
              cw_data[i].client_name +
              "</h4><p><b>" +
              cw_data[i]["total"].toLocaleString() +
              "</b> Total Requests costing <b>$" +
              ((cw_data[i]["total"] / 1000.0) * cw_data[i]["rate"]).toFixed(2) +
              "</b></p><div id='request-metric-" +
              cw_data[i].metric +
              "'></div>"
          );
        } else {
          $("#s3-bucket-request-metrics-container").append(
            "<h4>" + cw_data[i].client_name + "</h4><p>No Requests Made</p>"
          );
        }
      }

      this.add_charts(cw_data);
    }
    this.retrieved = true;
  }

  egress_cost(bytes, rates) {
    if (bytes < 1073741824) {
      // First GB always free across all regions
      return 0.0;
    } else if (bytes < 10000000000000) {
      // Up to 10 TB is Rate 2
      return ((bytes - 1073741824) / 1073741824) * rates["tier_two_rate"];
    } else if (bytes < 50000000000000) {
      // Up to 50 TB is Rate 3
      return (
        9999 * rates["tier_two_rate"] +
        ((bytes - 10000000000000) / 1073741824) * rates["tier_three_rate"]
      );
    } else if (bytes < 150000000000000) {
      // Up to 150 TB is Rate 4
      return (
        9999 * rates["tier_two_rate"] +
        40000 * rates["tier_three_rate"] +
        ((bytes - 50000000000000) / 1073741824) * rates["tier_four_rate"]
      );
    } else if (bytes >= 150000000000000) {
      return (
        9999 * rates["tier_two_rate"] +
        40000 * rates["tier_three_rate"] +
        100000 * rates["tier_four_rate"] +
        ((bytes - 150000000000000) / 1073741824) * rates["tier_five_rate"]
      );
    } else {
      return 0.0;
    }
  }

  add_charts(cw_data) {
    const cursorOpts = {
      lock: true,
      focus: {
        prox: 16,
      },
      sync: {
        key: "rm",
        setSeries: true,
      },
    };
    var rm_data;
    var elem;
    window.rm_uplots = [];

    // Push each of the metrics
    for (var i = 0; i < cw_data.length; i++) {
      rm_data = [];
      rm_data.push(cw_data[i]["labels"]);
      rm_data.push(cw_data[i]["data"]);

      elem = document.getElementById("request-metric-" + cw_data[i]["metric"]);

      var opts = {
        title: cw_data[i]["client_side_name"],
        ...this.getSize(),
        cursor: cursorOpts,
        axes: [{}, {}],
        series: [
          {},
          {
            label: cw_data[i]["client_side_name"],
            show: true,
            stroke: "#9D9FBE",
            width: 2,
            fill: "#D1D2E5",
          },
        ],
      };

      window.rm_uplots.push(new uPlot(opts, rm_data, elem));
    }

    var _this = this;

    window.addEventListener(
      "resize",
      this.throttle(function () {
        for (var i = 0; i < window.rm_uplots.length; i++) {
          window.rm_uplots[i].setSize(_this.getSize());
        }
      }, 100)
    );
  }
}
