import { Controller } from "@hotwired/stimulus";

// Meant to bind dropdown options to elements on the page and only show the option that's been selected.
// If there's a blank/prompt value on the dropdown, it'll show all elements.
export default class extends Controller {
  static targets = ["content"];

  focusContent(e) {
    e.preventDefault();

    let selectedValue = e.target.selectedOptions[0].value;

    if (selectedValue == "") {
      // Don't filter out any content.
      this.contentTargets.forEach((target) => {
        target.removeAttribute("hidden");
      });
    } else {
      // Only show the selected content target.
      this.contentTargets.forEach((target) => {
        if (
          target.getAttribute("data-dropdown-focus-id-param") == selectedValue
        ) {
          target.removeAttribute("hidden");
        } else {
          target.setAttribute("hidden", true);
        }
      });
    }
  }
}
