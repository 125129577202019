import { Controller } from "@hotwired/stimulus";

// Enables/disables target element when an event is fired.
export default class extends Controller {
  static targets = ["submit", "fields"];

  connect() {
    if (!this.hasSubmitTarget) return;

    if (this.fieldsTargets.length === 0) {
      this.element.addEventListener("change", (_event) => {
        this.submitTarget.removeAttribute("disabled");
      });
    } else {
      this.fieldsTargets.forEach((field) => {
        field.addEventListener("change", (_event) => {
          this.submitTarget.removeAttribute("disabled");
        });
      });
    }
  }
}
