import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import uPlot from "uplot";

export default class extends Controller {
  getSize() {
    return {
      width: document.getElementById("resource-metrics").offsetWidth - 100,
      height: 200,
    };
  }

  throttle(cb, limit) {
    var wait = false;

    return () => {
      if (!wait) {
        requestAnimationFrame(cb);
        wait = true;
        setTimeout(() => {
          wait = false;
        }, limit);
      }
    };
  }

  async changeTimeframe() {
    for (
      var j = 0;
      j < document.getElementsByClassName("timeframe").length;
      j++
    ) {
      document
        .getElementsByClassName("timeframe")
        [j].classList.remove("selected");
    }
    event.srcElement.classList.add("selected");
    const response = await axios.get(
      document.location.pathname +
        "/metrics?timeframe=" +
        event.srcElement.value
    );
    const cw_data = response.data;

    for (var i = 0; i < cw_data["metrics"].length; i++) {
      window.uplots[i].setData([cw_data["labels"], cw_data["metrics"][i]]);
    }
  }

  async initialize() {
    const response = await axios.get(document.location.pathname + "/metrics");
    const cw_data = response.data;
    const cursorOpts = {
      lock: true,
      focus: {
        prox: 16,
      },
      sync: {
        key: "mcyolo",
        setSeries: true,
      },
    };
    var data;
    var elem;
    window.uplots = [];

    // Push each of the metrics
    for (var i = 0; i < cw_data["metrics"].length; i++) {
      data = [];
      data.push(cw_data["labels"]);
      data.push(cw_data["metrics"][i]);

      elem = document.getElementsByClassName("metric-chart")[i];

      var opts = {
        title: elem.id,
        ...this.getSize(),
        cursor: cursorOpts,
        axes: [
          {},
          {
            values: (self, splits) =>
              splits.map((v) => {
                if (elem.id == "NumberOfObjects") {
                  if (v < 1000) {
                    return v;
                  } else if (v < 1000000) {
                    return String(v / 1000) + "K";
                  } else if (v < 1000000000) {
                    return String(v / 1000000) + "M";
                  } else if (v < 1000000000000) {
                    return String(v / 1000000000) + "B";
                  }
                } else if (elem.id == "CPUUtilization") {
                  return String(v * 100.0) + "%";
                } else {
                  if (v < 1000) {
                    return v;
                  } else if (v < 1000000) {
                    return String(v / 1000) + "K";
                  } else if (v < 1000000000) {
                    return String(v / 1000000) + "M";
                  } else if (v < 1000000000000) {
                    return String(v / 1000000000) + "B";
                  }
                }
              }),
          },
        ],
        series: [
          {},
          {
            label: elem.id,
            show: true,
            stroke: "#9D9FBE",
            width: 2,
            fill: "#D1D2E5",
          },
        ],
      };

      let plot = new uPlot(opts, data, elem);
      window.uplots.push(plot);
    }
    var _this = this;
    var targetNode = document.getElementById("resource-metrics");
    var observer = new MutationObserver(
      function () {
        if (targetNode.style.display != "none") {
          window.uplots.forEach((p) => p.setSize(this.getSize()));
        }
      }.bind(this)
    );
    observer.observe(targetNode, { attributes: true, childList: true });

    window.addEventListener(
      "resize",
      this.throttle(function () {
        for (var i = 0; i < window.uplots.length; i++) {
          window.uplots[i].setSize(_this.getSize());
        }
      }, 100)
    );
  }
}
