import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "item", "dropdown"];

  connect() {
    let controller = this;

    this.dropdownTarget.addEventListener("show.bs.dropdown", function () {
      controller.clear();
      controller.showAll();
    });

    this.dropdownTarget.addEventListener("hidden.bs.dropdown", function () {
      controller.clear();
      controller.showAll();
    });

    this.inputTarget.addEventListener("click", this.focus);
    this.inputTarget.addEventListener(
      "keypress",
      ((e) => {
        if (e.keyCode == 13) {
          this.focus();
        }
      }).bind(this)
    );
  }

  focus(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.focus();
  }

  clear() {
    this.inputTarget.value = "";
  }

  search(event) {
    let searchValue = this.inputTarget.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      let value = item.dataset.value.toLowerCase();

      if (value.includes(searchValue)) {
        this.show(item);
      } else {
        this.hide(item);
      }
    });
  }

  showAll() {
    this.itemTargets.forEach(this.show);
  }

  show(element) {
    element.classList.remove("d-none");
  }

  hide(element) {
    element.classList.add("d-none");
  }
}
