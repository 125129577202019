import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    refresh: Boolean,
  };

  initialize() {
    this.inputTargets.forEach((target) => {
      target.addEventListener("change", this.updateQueryParams.bind(this));
    });
  }

  updateQueryParams(event) {
    const queryParams = new URLSearchParams(window.location.search);
    const inputName = event.target.name;

    let inputValue;

    if (event.target.type == "checkbox") {
      inputValue = event.target.checked.toString();
    } else {
      inputValue = event.target.value;
    }

    if (!inputValue) {
      queryParams.delete(this.inputName);
    } else {
      queryParams.set(inputName, inputValue);
    }

    history.pushState(null, null, "?" + queryParams.toString());

    if (this.refreshValue) {
      window.location.reload();
    }
  }

  toggleQueryParams() {
    const currentUrl = new URL(window.location.href);
    this.inputTargets.forEach((target) => {
      const inputValue = target.value;
      const inputName = target.name;

      if (currentUrl.searchParams.has(inputName)) {
        currentUrl.searchParams.delete(inputName);
      } else {
        currentUrl.searchParams.set(inputName, "true");
      }

      target.value = inputValue === "true" ? null : "true";
      history.pushState(null, null, currentUrl.toString());
    });
  }
}
