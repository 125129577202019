import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["element"];

  hide() {
    this.elementTargets.forEach((element) => element.classList.add("tw-hidden"));
  }
}
