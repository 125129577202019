import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "resource",
    "bulkActionButtons",
    "bulkSelectionInputCell",
    "headerRow",
    "newFolderInput",
    "form",
    "modalTitle",
    "starButton",
    "unstarButton",
  ];

  connect() {
    this.resourceTargets.forEach((resource) => {
      resource.addEventListener("change", this.handleResourceSelection.bind(this));
    });
  }

  get anyResourceSelected() {
    return this.resourceTargets.some((resource) => resource.checked);
  }

  get bulkActionModalTitle() {
    if (this.anyResourceSelected) {
      const dataTypes = this.resourceTargets
        .filter((resource) => resource.checked)
        .map((resource) => resource.getAttribute("data-type"));
      let dataTypeCounts = dataTypes.reduce((acc, item) => {
        acc[item] = (acc[item] || 0) + 1;
        return acc;
      }, {});

      const dataTypesCountText = Object.entries(dataTypeCounts)
        .map(([key, value]) => `${value} ${key}${value > 1 ? "s" : ""}`)
        .join(", ");
      return `Moving ${dataTypesCountText}`;
    } else {
      return "Bulk move Selected Items";
    }
  }

  get anySelectedResourceUnstarred() {
    return this.resourceTargets.some((resource) => {
      const parentTableRowEl = resource.closest("tr");
      return resource.checked && parentTableRowEl.querySelector(".btn-unstarred");
    });
  }

  handleResourceSelection(event) {
    this.updateSelectedResourceTokens(event);
    this.setRowBackgroundColor(event);
  }

  updateSelectedResourceTokens(event) {
    if (!event.target.checked) {
      this.bulkSelectionInputCellTarget.querySelector("input").checked = false;
    }

    if (this.anyResourceSelected) {
      this.showBulkActionHeader();
      this.setBulkStarState();
    } else {
      this.hideBulkActionHeader();
    }
  }

  setRowBackgroundColor(event) {
    const parentTableRowEl = event.target.closest("tr");
    const tableRowDataElements = parentTableRowEl.querySelectorAll("td");
    if (event.target.checked) {
      tableRowDataElements.forEach((el) => el.classList.add("tw-bg-purple-50"));
    } else {
      tableRowDataElements.forEach((el) => el.classList.remove("tw-bg-purple-50"));
    }
  }

  toggleAllResources(event) {
    const checked = event.target.checked;
    this.resourceTargets.forEach((resource) => {
      resource.checked = checked;
      this.setRowBackgroundColor({ target: resource });
    });

    if (checked) {
      this.showBulkActionHeader();
      this.setBulkStarState();
    } else {
      this.hideBulkActionHeader();
    }
  }

  showBulkActionHeader() {
    this.headerRowTarget.querySelectorAll("th").forEach((th) => {
      if (th === this.bulkSelectionInputCellTarget) {
        th.setAttribute("colspan", 5);
      } else {
        th.classList.add("tw-hidden");
      }
    });
    this.bulkActionButtonsTarget.classList.remove("tw-hidden");
    this.bulkActionButtonsTarget.classList.add("tw-flex");
  }

  hideBulkActionHeader() {
    this.headerRowTarget.querySelectorAll("th").forEach((th) => {
      if (th === this.bulkSelectionInputCellTarget) {
        th.removeAttribute("colspan");
      } else {
        th.classList.remove("tw-hidden");
      }
    });
    this.bulkActionButtonsTarget.classList.add("tw-hidden");
    this.bulkActionButtonsTarget.classList.remove("tw-flex");
  }

  setBulkActionModalTitle() {
    this.modalTitleTarget.textContent = this.bulkActionModalTitle;
  }

  submitBulkMove({ params }) {
    this.newFolderInputTarget.value = params.targetFolder || "";
    this.formTarget.submit();
  }

  setBulkStarState() {
    if (!this.hasStarButtonTarget) return;

    if (this.anySelectedResourceUnstarred) {
      this.unstarButtonTarget.classList.add("tw-hidden");
      this.starButtonTarget.classList.remove("tw-hidden");
    } else {
      this.starButtonTarget.classList.add("tw-hidden");
      this.unstarButtonTarget.classList.remove("tw-hidden");
    }
  }
}
