import { Controller } from "@hotwired/stimulus";

const DEFAULT_POLLING_INTERVAL = 1000; // 1 second
const DEFAULT_MAX_POLLING_DURATION = 30000; // 30 seconds

export default class extends Controller {
  static values = {
    pollingInterval: { type: Number, default: DEFAULT_POLLING_INTERVAL },
    maxPollingDuration: { type: Number, default: DEFAULT_MAX_POLLING_DURATION },
    url: String,
    redirectUrl: String,
    alertMessage: {
      type: String,
      default: "Still processing... Refresh the page or come back momentarily.",
    },
  };

  initialize() {
    this.intervalId = setInterval(
      this.getStatus.bind(this),
      this.pollingIntervalValue
    );
    this.timeoutId = setTimeout(
      function () {
        clearInterval(this.intervalId);
        const message = this.alertMessageValue;
        alert(message);
      }.bind(this),
      this.maxPollingDurationValue
    );
  }

  async getStatus() {
    try {
      const response = await fetch(this.urlValue);
      if (response.status === 200) {
        this.reset();
        this.redirect();
      } else if (response.status === 400) {
        const json = await response.json();
        this.reset();
        const error = json.error || "Something went wrong with the import.";
        alert(error);
      }
    } catch (e) {
      this.reset();
      console.error(e);
    }
  }

  reset() {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  }

  redirect() {
    if (this.redirectUrlValue) {
      window.location = this.redirectUrlValue;
    } else {
      window.location.reload();
    }
  }

  disconnect() {
    this.reset();
  }
}
