import { Controller } from "@hotwired/stimulus";

/**
 * This controller is used to the leftmost columns on a table sticky. The amount of columns you'd like to make sticky
 * is arbitrary. To use this controller you must add date-sticky-columns-target="row" to the table row elements and add
 * the class "fixedCell" to the cells you'd like to make sticky.
 */
export default class extends Controller {
  static targets = ["row"];

  initialize() {
    this.rowTargets.forEach((rowElement) => this.applyStickyStyle(rowElement));
  }

  applyStickyStyle(rowElement) {
    const cellElement = rowElement.getElementsByClassName("fixedCell");

    let cumulativeCellWidth = 0;

    // Set initial positions for cell elements within the current cell
    for (let i = 0; i < cellElement.length; i++) {
      if (i === 0) {
        cumulativeCellWidth = 0; // Reset cumulative width for the first cell
      } else {
        cumulativeCellWidth += cellElement[i - 1].offsetWidth;
      }

      cellElement[i].style.position = "sticky";
      cellElement[i].style.left = cumulativeCellWidth + "px";
      cellElement[i].style.zIndex = "2"; // Set higher z-index for sticky cells
    }
  }
}
