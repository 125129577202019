import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["button", "status"];

  static values = {
    url: String,
    syncUrl: String,
  };

  validatePermission(event) {
    event.preventDefault();

    this.buttonTarget.classList.add("disabled");
    this.buttonTarget.text = "Checking Permissions...";
    this.checkPermission();
  }

  async checkPermission() {
    const syncing = await this.isSyncing();

    if (syncing) {
      this.statusTarget.innerText = "Syncing...";
      setTimeout(() => this.checkPermission(), 5000);
      return;
    }

    const response = await axios.get(this.urlValue);
    const data = response.data;

    if (data.has_permission) {
      this.statusTarget.innerText = "Permission Added Successfully";
    } else {
      this.statusTarget.innerText = "Permission Not Present";
    }

    this.buttonTarget.innerText = "Check Permissions";
    this.buttonTarget.classList.remove("disabled");
  }

  async isSyncing() {
    const status = await axios.get(this.syncUrlValue);

    if (status.data.syncs.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
