import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "message", "confirmation"];

  initialize() {
    this.activeTimeouts = [];
    this.hideConfirmation();
  }

  copyText(event) {
    event.preventDefault();

    let text = this.textTarget.innerText;
    navigator.clipboard.writeText(text);

    this.showConfirmation();
  }

  showConfirmation() {
    if (!this.hasConfirmationTarget) {
      return;
    }

    this.confirmationTarget.style.transition = "opacity 1s ease-out";

    // In addition to making the message visible, removing display: none causes
    // the confirmation message to alert for screen readers.  The confirmation
    // message element must have role=alert for this to work correctly.
    this.confirmationTarget.classList.remove("d-none");
    // We set the opacity back to 100% just in case this is called after
    // fadeConfirmation, but before hideConfirmation.
    // We clear the timeouts, just in case they haven't been executed yet so that
    // it resets the timer to hide the message.
    this.confirmationTarget.style.opacity = "100%";

    for (let timer of this.activeTimeouts) {
      clearTimeout(timer);
    }
    this.activeTimeouts = [];
    this.activeTimeouts.push(
      setTimeout(() => {
        this.fadeConfirmation();
      }, 2000)
    );
    this.activeTimeouts.push(
      setTimeout(() => {
        this.hideConfirmation();
      }, 3000)
    );
  }

  // starts the fade transition
  fadeConfirmation() {
    if (!this.hasConfirmationTarget) {
      return;
    }
    this.confirmationTarget.style.opacity = "0%";
  }

  // hide the element from the dom so the alert message can play again if it needs to.
  // return opacity back to 100% so that when the element is displayed
  hideConfirmation() {
    if (!this.hasConfirmationTarget) {
      return;
    }
    this.confirmationTarget.style.opacity = "100%";
    this.confirmationTarget.classList.add("d-none");
  }
}
