import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";
import {
  TreeController,
  DendogramController,
  EdgeLine,
} from "chartjs-chart-graph";

// register controller in chart.js and ensure the defaults are set
Chart.register(TreeController, DendogramController, EdgeLine);

export default class extends Controller {
  static targets = ["output"];

  static values = {
    nodes: Array,
  };

  initialize() {
    const nodes = this.nodesValue;
    const options = this.chartOptions(nodes);
    new Chart(this.outputTarget.getContext("2d"), options);
  }

  chartOptions(nodes) {
    return {
      type: "tree",
      data: {
        labels: nodes.map((d) => d.name),
        datasets: [
          {
            pointBackgroundColor: "#9c25c7",
            pointRadius: 5,
            data: nodes,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            padding: 20,
            bodySpacing: 5,
            titleMarginBottom: 10,
            footerMarginTop: 10,
            callbacks: {
              title: (context) => {
                return context[0].raw.name;
              },
              label: (context) => {
                const keys = Object.keys(context.raw.details);
                return keys
                  .map((k) => {
                    const v = context.raw.details[k];
                    if (!v) {
                      return null;
                    }

                    return `${k}: ${v}`;
                  })
                  .filter((v) => v);
              },
              footer: (context) => {
                const keys = Object.keys(context[0].raw.footer);
                return keys
                  .map((k) => {
                    const v = context[0].raw.footer[k];
                    if (!v) {
                      return null;
                    }

                    return `${k}: ${v}`;
                  })
                  .filter((v) => v);
              },
            },
          },
        },
        tree: {
          orientation: "vertical",
        },
      },
    };
  }
}
