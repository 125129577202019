import { BaseRule } from "./base-rule";
import * as helpers from "./helpers";

export class AttributeRule extends BaseRule {
  get dataValueProp() {
    return "value";
  }

  renderLeftHandSide() {
    // Attribute type pill
    let pill = document.createElement("div");
    pill.classList.add("btn", "btn-tertiary", "btn-sm", "ml-2");
    (pill.innerHTML = this.labelForAttribute()), this.attr;
    this.container.appendChild(pill);
  }

  renderComparator() {
    // "is" or "is not" dropdown
    let comparatorDropdownContainer = document.createElement("div");
    comparatorDropdownContainer.classList.add("dropdown");
    this.container.appendChild(comparatorDropdownContainer);
    this.comparatorSelector = document.createElement("button");
    this.comparatorSelector.ariaExpanded = "false";
    this.comparatorSelector.classList.add("btn", "btn-outline", "ml-2", "btn-sm");
    this.comparatorSelector.innerText = this.comparatorLabel;
    this.comparatorSelector.addEventListener("click", this.toggleComparatorDropdown.bind(this));
    comparatorDropdownContainer.appendChild(this.comparatorSelector);
    this.comparatorDropdown = document.createElement("ul");
    this.comparatorDropdown.classList.add("dropdown-menu");
    this.updateComparatorDropOptions();
    comparatorDropdownContainer.appendChild(this.comparatorDropdown);
  }

  labelFor(value) {
    let v = this.attributeValues[this.attribute].find((v) => v.value === value);
    if (v) {
      return v.label;
    } else {
      return value;
    }
  }

  createNewValueControl() {
    let addValueDiv = document.createElement("div");
    this.addValueDropdown = document.createElement("div");
    this.addValueDropdown.classList.add("dropdown");

    let buttonId = `select-value-${helpers.uniqueId()}`;
    if (this.dataValues.length === 0) {
      this.addValueDropdown.innerHTML = `<button id="${buttonId}" class="btn btn-outline btn-sm ml-2 pill--truncate" aria-expanded="false">${this.labelForMissingValue()}</button>
              <div class="dropdown-menu" aria-labelledby="${buttonId}"></div>`;
    } else {
      this.addValueDropdown.innerHTML = `<button id="${buttonId}" class="btn btn-outline btn-sm ml-2 pill--truncate" aria-expanded="false">${helpers.labelForAddValue()}</button>
              <div class="dropdown-menu" aria-labelledby="${buttonId}"></div>`;
    }

    this.valueDropdown = this.addValueDropdown.lastElementChild;

    this.filterableList = document.createElement("filterable-list");
    this.filterableList.placeholder = "Find values...";
    this.filterableList.loaderMarkup = this.loaderMarkup;

    this.addValueDropdown.lastElementChild.appendChild(this.filterableList);

    this.valueDropdownTrigger = this.addValueDropdown.firstElementChild;
    this.valueFilter = this.addValueDropdown.lastElementChild.firstElementChild;
    this.valueFilter.addEventListener("keyboardblur", () => {
      this.hideValueDropdown();
      this.valueDropdownTrigger.focus();
    });

    this.filterableList.addEventListener("keypressenter", (event) => {
      this.updateComparatorOrValue(event);
    });

    this.valueDropdownTrigger.addEventListener("click", this.toggleValueDropdown.bind(this));

    this.addValueInput = document.createElement("input");
    this.addValueInput.type = "text";
    this.addValueInput.classList.add("d-none", "form-control", "input-sm", "ml-2", "w-150");
    this.addValueInput.setAttribute("placeholder", "Keyword...");

    this.valueInputBtn = document.createElement("button");
    this.valueInputBtn.classList.add("d-none", "btn", "btn-sm", "btn-outline", "ml-2");
    this.valueInputBtn.ariaExpanded = "false";
    this.valueInputBtn.innerText = "Add";

    this.valueInputBtn.addEventListener(
      "click",
      ((event) => {
        event.target.dataset.value = this.addValueInput.value;
        this.addValueInput.value = "";
        this.updateComparatorOrValue(event);
      }).bind(this)
    );

    addValueDiv.classList.add("d-flex", "flex-shrink-0");

    addValueDiv.appendChild(this.addValueDropdown);
    addValueDiv.appendChild(this.addValueInput);
    addValueDiv.appendChild(this.valueInputBtn);

    this.filterableList.init(
      this.attributeValues[this.attribute].map((v) => {
        return {
          label: v.label,
          value: v.value,
        };
      })
    );

    this.container.appendChild(addValueDiv);
  }

  updateComparatorOrValue(event) {
    let target =
      event.target.parentElement && event.target.parentElement.tagName === "A"
        ? event.target.parentElement
        : event.target;
    if (target.dataset.comparator) {
      event.preventDefault();

      this.dataComparator = target.dataset.comparator;
      this.comparatorSelector.innerText = this.comparatorLabel;
      this.comparatorSelector.focus();
      this.updateComparatorDropOptions();
      this.toggleValueInput();
      this.dispatchEvent(helpers.filtersChangedEvent());
    } else if (target.dataset.value) {
      event.preventDefault();

      if (!this.hasValue(target.dataset.value)) {
        this.dataValues.push(target.dataset.value);
        this.createValuePill(target.dataset.value);
        this.filterableList.removeItem(target.dataset.value);
      }
      this.updateNewValueControl();
      // The detail in this situation is the number of mouse clicks. Zero
      // means that we used a keyboard instead of a mouse to cause the click.
      if (event.detail === 0) {
        this.valueDropdownTrigger.focus();
      }
      this.dispatchEvent(helpers.filtersChangedEvent());
    }
  }

  updateComparatorDropOptions() {
    this.comparatorDropdown.innerHTML = `
            <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
              this._data.comparator === "=" ? "check" : "&nbsp;"
            }</span><span>is</span></a></li>
            <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="!="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
              this._data.comparator === "!=" ? "check" : "&nbsp;"
            }</span><span>is not</span></a></li>
            <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="~="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
              this.dataComparator === "~=" ? "check" : "&nbsp;"
            }</span><span>contains</span></a></li>
            <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="!~="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
              this.dataComparator === "!~=" ? "check" : "&nbsp;"
            }</span><span>does not contain</span></a></li>
        `;
  }
}
