import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["default"];

  static values = {
    default: { type: Number, default: 0 },
    skipSort: { type: Boolean, default: false },
    direction: { type: String, default: "desc" },
  };

  initialize() {
    let initialOrder = [];

    if (!this.skipSortValue) {
      initialOrder = [[this.defaultSortColumn(), this.directionValue]];
    }

    import("datatables.net-dt").then((module) => {
      this.DataTable = module.default;
      // Datatables will throw an error, but also trigger a JS alert dialog when an error occurs on initialization.
      // This is an annoyance, and here we're just making sure we temporarily override the alert interface.
      const originalAlert = window.alert;
      window.alert = () => {};
      try {
        new this.DataTable(this.element, {
          order: initialOrder,
          columnDefs: [{ orderable: false, targets: this.skipColumns }],
          paging: false,
          info: false,
          searching: false,
        });
      } finally {
        window.alert = originalAlert;
      }
    });
  }

  get skipColumns() {
    return this.data.get("skip") ? JSON.parse(this.data.get("skip")) : [];
  }

  defaultSortColumn() {
    if (!this.hasDefaultTarget) return this.defaultValue;

    const siblings = Array.from(this.defaultTarget.parentNode.children).filter(
      (child) => child.tagName.toLowerCase() === "th"
    );
    return siblings.indexOf(this.defaultTarget);
  }
}
