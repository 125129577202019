import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  static values = { actionUrl: String };

  flip(event) {
    let method = this.data.get("method");
    let el = this.formTarget.querySelector("input[name='_method']");
    el.value = method;

    if (this.hasActionUrlValue) {
      this.formTarget.action = this.actionUrlValue;
    }
  }
}
