import { ProviderServiceRule } from "./provider-service-rule";
export class ResourceIdRule extends ProviderServiceRule {
  fetchValues() {
    return super.fetchValues(
      this.reportUrl("resource_ids", {
        provider: this.selectedProvider,
        service: this.dataService,
      })
    );
  }

  renderLeftHandSide() {
    this.renderSelectPill();
    this.nameContainer = document.createElement("div");
    this.container.appendChild(this.nameContainer);

    if (this.noResourceIdsAvailable()) {
      let resourceIdsEmpty = document.createElement("div");
      resourceIdsEmpty.classList.add("filter-set-empty");
      resourceIdsEmpty.innerHTML =
        '<span class="material-icons md-small">error</span> <span class="small">No Resource IDs found.</span>';
      this.nameContainer.appendChild(resourceIdsEmpty);
    } else {
      this.renderSelectServiceControl();
    }
  }

  renderRightHandSide() {
    if (this.noResourceIdsAvailable()) return;

    super.renderRightHandSide();
  }

  renderComparator() {
    if (this.noResourceIdsAvailable()) return;

    super.renderComparator();
  }

  renderSelectServiceControl() {
    super.renderSelectServiceControl("resource_id");
  }

  updateComparatorDropOptions() {
    this.comparatorDropdown.innerHTML = `
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "=" ? "check" : "&nbsp;"
        }</span><span>is</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="!="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "!=" ? "check" : "&nbsp;"
        }</span><span>is not</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="~="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "~=" ? "check" : "&nbsp;"
        }</span><span>contains</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="!~="><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "!~=" ? "check" : "&nbsp;"
        }</span><span>does not contain</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="^"><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "^" ? "check" : "&nbsp;"
        }</span><span>starts with</span></a></li>
        <li><a href="#" class="dropdown-item d-flex align-items-center" data-comparator="$"><span class="material-icons-outlined mr-2 md-small" aria-hidden="true">${
          this.dataComparator === "$" ? "check" : "&nbsp;"
        }</span><span>ends with</span></a></li>
      `;
  }

  noResourceIdsAvailable() {
    return this.attributeValues.resource_id.length === 0;
  }
}
