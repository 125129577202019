import { BaseRule } from "./base-rule";
import * as helpers from "./helpers";

export class ProviderRule extends BaseRule {
  get dataValueProp() {
    return "value";
  }

  get availableProviders() {
    return this.filterSetRoot.availableProviders;
  }

  get currentValue() {
    return this.dataValues[0];
  }

  get providerDisplayMap() {
    return {
      aws: "AWS",
      azure: "Azure",
      databricks: "Databricks",
      datadog: "Datadog",
      fastly: "Fastly",
      gcp: "GCP",
      kubernetes: "Kubernetes",
      mongo: "MongoDB Atlas",
      new_relic: "New Relic",
      open_ai: "OpenAI",
      snowflake: "Snowflake",
      oracle: "Oracle",
      confluent: "Confluent",
      planetscale: "PlanetScale",
      coralogix: "Coralogix",
      github: "GitHub",
      custom_provider: "Custom Provider",
    };
  }

  renderLeftHandSide() {
    this.valuesContainer = document.createElement("div");
    this.valuesContainer.classList.add("d-flex", "align-items-center", "flex-wrap");
    this.container.appendChild(this.valuesContainer);
    if (!this.currentValue) {
      this.createDefaultValuePill();
    } else {
      this.createValuePill(this.currentValue);
    }
    this.createNewValueControl();
  }

  labelFor(providerOrValue) {
    let value = providerOrValue.hasOwnProperty("value") ? providerOrValue.value : providerOrValue;
    let foundValue = this.availableProviders.find((v) => v.value === value);
    let label;
    if (foundValue) {
      label = foundValue.label;
    } else {
      label = value.label;
    }

    // TODO(nel): Define these labels in the backend. Silly to have them hard-coded in the front-end.
    return this.providerDisplayMap[label] || providerOrValue?.label;
  }

  labelForMissingProvider() {
    return '<span class="material-icons-outlined icon" aria-hidden="true">ads_click</span> Select a provider';
  }

  renderPreface() {
    this.prefaceElement = document.createElement("span");
    this.prefaceElement.classList.add("mt-1");
    this.prefaceElement.innerText = "from";
    this.container.appendChild(this.prefaceElement);
  }

  attributeChangedCallback(name, _oldValue, newValue) {}

  createValuePill(provider) {
    let valuePill = document.createElement("span");
    valuePill.tabIndex = "0";
    valuePill.classList.add(
      "btn",
      "btn-outline",
      "btn-pill--deletable",
      "btn-sm",
      "ml-2",
      "provider-icon",
      "dropdown",
      provider.value
    );
    valuePill.setAttribute("aria-expanded", "false");
    valuePill.innerText = this.labelFor(provider);

    this.valuesContainer.innerHTML = "";
    this.valuesContainer.appendChild(valuePill);
  }

  createDefaultValuePill() {
    let providerNameDropdownContainer = document.createElement("div");
    providerNameDropdownContainer.classList.add("dropdown");
    let buttonId = `select-name-${helpers.uniqueId()}`;
    providerNameDropdownContainer.innerHTML = `<button id="${buttonId}" class="btn btn-outline btn-sm ml-2" aria-expanded="false">${this.labelForMissingProvider()}</button>`;

    this.valuesContainer.innerHTML = "";
    this.valuesContainer.appendChild(providerNameDropdownContainer);
  }

  createNewValueControl() {
    let addValueDiv = document.createElement("div");
    addValueDiv.innerHTML = `<div class="dropdown-menu dropdown-menu--provider" aria-labelledby="select-value-${helpers.uniqueId()}"></div>`;
    this.valueDropdown = addValueDiv.lastElementChild;

    this.filterableList = document.createElement("filterable-list");
    this.filterableList.placeholder = "Find Provider...";

    addValueDiv.lastElementChild.appendChild(this.filterableList);

    this.filterableList.init(
      this.filterSetRoot.availableProviders
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((v) => {
          return {
            label: v.label,
            value: v.value,
            markup: `<span class="provider-icon dropdown-provider-icon ${v.value}">${this.labelFor(
              v
            )}</span>`,
          };
        })
    );

    this.valueDropdownTrigger = this.valuesContainer.firstElementChild;
    this.valueDropdownTrigger.addEventListener("click", this.toggleValueDropdown.bind(this));
    this.valuesContainer.firstElementChild.appendChild(addValueDiv);
  }

  renderComparator() {}
  renderRightHandSide() {}

  updateComparatorOrValue(event) {
    event.preventDefault();

    let target =
      event.target.parentElement && event.target.parentElement.tagName === "A"
        ? event.target.parentElement
        : event.target;
    let newValue = target.dataset.value;

    if (newValue && newValue != this.currentValue) {
      this.createValuePill(newValue);
      this.filterSet.selectedProvider = newValue;
      this.filterSet.clearSelf();
    }

    // The detail in this situation is the number of mouse clicks. Zero
    // means that we used a keyboard instead of a mouse to cause the click.
    if (event.detail === 0) {
      this.valueDropdownTrigger.focus();
    }
  }

  closeComparatorDropdown() {}
  renderRemoveRuleControl() {}
}
