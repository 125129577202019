import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    target: String,
  };

  directClick(e) {
    e.preventDefault();
    e.stopPropagation();
    let el = document.getElementById(this.targetValue);

    if (el.tagName.toLowerCase() == "form") {
      el.submit();
    } else {
      el.click();
    }
  }
}
