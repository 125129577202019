import { Controller } from "@hotwired/stimulus";

const MEGABYTE = 1024 * 1024;
export default class extends Controller {
  static targets = ["csvFile", "form"];

  get sizeLimit() {
    return parseInt(this.data.get("size-limit"));
  }

  uploadFile(e) {
    e.preventDefault();
    this.csvFileTarget.click();
  }

  onCsvFileChange() {
    const file = this.csvFileTarget.files[0];
    if (this.sizeLimit && file.size > this.sizeLimit * MEGABYTE) {
      alert(`File size exceeds ${this.sizeLimit} MB limit.`);
    } else {
      this.formTarget.submit();
    }
  }
}
