import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["table", "successStatus", "loadingStatus", "nextButton"];

  initialize() {
    this.accountIds = this.data.get("accounts").split(",");
    this.providerId = this.data.get("provider-id");
    this.waiting = null;
  }
  startWaiting() {
    if (this.waiting) {
      return;
    }
    this.waiting = setInterval(() => this.checkStatus(), 2000);
  }
  stopWaiting() {
    if (this.waiting) {
      clearInterval(this.waiting);
    }
  }
  async checkStatus() {
    let response;
    try {
      response = await axios.get(
        `/provider_credentials/${this.providerId}/access_credentials.json`
      );
    } catch (e) {
      // TODO: report this to an error monitoring app?
      console.error(e);
      return;
    }
    let complete = true;
    for (let id of this.accountIds) {
      if (response.data && response.data[id] && response.data[id].cur_enabled) {
        this.showSuccess(id);
      } else {
        complete = false;
      }
    }
    if (complete) {
      this.stopWaiting();
      this.nextButtonTarget.classList.remove("hidden-from-dom");
    }
  }
  showSuccess(id) {
    let statusCellElement = this.tableTarget.querySelector(
      `[data-enable-analytics-status="${id}"]`
    );
    if (
      statusCellElement.getAttribute("data-enable-analytics-state") !==
      "success"
    ) {
      statusCellElement.innerHTML = "";
      statusCellElement.appendChild(
        this.successStatusTarget.content.firstElementChild.cloneNode(true)
      );
      statusCellElement.setAttribute("data-enable-analytics-state", "success");
    }
  }
  showLoading(event) {
    let el = event.target;
    this._showLoading(el.getAttribute("data-enable-analytics-id"));
    this.startWaiting();
  }
  _showLoading(id) {
    let statusCellElement = this.tableTarget.querySelector(
      `[data-enable-analytics-status="${id}"]`
    );
    statusCellElement.innerHTML = "";
    statusCellElement.appendChild(
      this.loadingStatusTarget.content.firstElementChild.cloneNode(true)
    );
  }
}
