import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  routingPoilicySelected() {
    var additional_field_policies = document.getElementsByClassName(
      "routing-policy-additional-fields"
    );
    for (var i = 0; i < additional_field_policies.length; i++) {
      additional_field_policies[i].classList.add("hidden-from-dom");
    }

    // Only show additional fields for non-simple routing policies.
    if (event.target.value != "Simple") {
      var displayed_attributes = document.getElementsByClassName(
        "routing-policy-" +
          $("#record-type-routing-policy").val().toLowerCase().replace(" ", "-")
      );
      for (i = 0; i < displayed_attributes.length; i++) {
        displayed_attributes[i].classList.remove("hidden-from-dom");
      }
    }

    this.updateAWSCLICommand();
  }

  validate() {
    if (
      this.validateRecordName() &&
      this.validateTTL() &&
      this.validateValues() &&
      this.validateAdditionalFields()
    ) {
      $("#dns_record_form").submit();
    } else {
      this.displayError();
      return false;
    }
  }

  validateAdditionalFields() {
    var routing_policy = document.getElementById(
      "record-type-routing-policy"
    ).value;
    if (routing_policy == "Simple") {
      return true;
    } else {
      if (routing_policy == "Weighted") {
        return this.validateWeight() && this.validateSetID();
      } else {
        return this.validateSetID();
      }
    }
  }

  validateWeight() {
    var weight = document.getElementById("record-weight-input").value;
    var regex = /^[0-9]+$/;

    if (weight == "") {
      this.error =
        "<b>Routing Policy 'Weighted'</b> requires a numerical value for 'Weight' between 0 and 255.";
      return false;
    }

    if (!weight.match(regex)) {
      this.error =
        "<b>Routing Policy 'Weighted'</b> must be a numerical value.";
      return false;
    }

    if (parseInt(weight) < 0 || parseInt(weight) > 255) {
      this.error =
        "<b>Routing Policy 'Weighted'</b> must be between 0 and 255.";
      return false;
    }

    return true;
  }

  validateSetID() {
    var set_id = document.getElementById("record-set-identifier-input").value;
    if (set_id.length == 0) {
      this.error = "<b>Set Identifier </b> cannot be blank";
      return false;
    }
    return true;
  }

  validateValues() {
    var record_type_values =
      document.getElementById("record-type-values").value;
    var record_type = document.getElementById("record-type-input").value;
    var aliased = document.getElementById("alias_toggle").checked;
    var i;

    if (aliased) {
      if ($("#record-alias-dns-name").val() == "") {
        this.error = "<b>Alias Target</b> cannot be blank";
        return false;
      }
      if ($("#record-alias-hosted-zone").val() == "") {
        this.error = "<b>Alias Hosted Zone ID:</b> cannot be blank";
        return false;
      }
    } else {
      if (record_type_values.length == 0) {
        this.error = "<b>Record Value</b> cannot be blank";
        return false;
      }

      record_type_values = record_type_values
        .split(/\r?\n/)
        .filter(function (el) {
          return el != "";
        });

      if (record_type == "A") {
        for (i = 0; i < record_type_values.length; i++) {
          if (!this.validateIPv4Address(record_type_values[i])) {
            return false;
          }
        }
      } else if (record_type == "AAAA") {
        for (i = 0; i < record_type_values.length; i++) {
          if (!this.validateIPv6Address(record_type_values[i])) {
            return false;
          }
        }
      }
    }

    return true;
  }

  validateIPv4Address(ip) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ip
      )
    ) {
      return true;
    }

    this.error =
      "<b>Record Values</b> for <b>A records</b> must be valid IPv4 addresses.";
    return false;
  }

  validateIPv6Address(ip) {
    if (
      /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/.test(
        ip
      )
    ) {
      return true;
    }
    this.error =
      "<b>Record Values</b> for <b>AAAA records</b> must be valid IPv6 addresses.";
    return false;
  }

  // Ensure TTL is present and only numerical.
  validateTTL() {
    var ttl = document.getElementById("record-type-ttl").value;

    if (ttl.length == 0) {
      this.error = "<b>TTL</b> cannot be blank";
      return false;
    }

    var regex = /^[0-9]+$/;
    if (!ttl.match(regex)) {
      this.error = "<b>TTL</b> must be a numerical value";
      return false;
    }

    if (parseInt(ttl) < 0 || parseInt(ttl) > 2147483647) {
      this.error = "<b>TTL</b> must be between 0 and 2,147,483,647";
      return false;
    }

    return true;
  }

  get error() {
    return this.data.get("error");
  }

  set error(value) {
    this.data.set("error", value);
  }

  // Ensure it's present
  validateRecordName() {
    if ($("#record-name-input").val().length == 0) {
      this.error = "<b>Record Name</b> cannot be blank";
      return false;
    }
    return true;
  }

  displayError() {
    document.getElementById("record-errors").innerHTML = this.error;
    document
      .getElementById("record-errors")
      .classList.remove("hidden-from-dom");
  }

  hideError() {
    this.error = "";
    document.getElementById("record-errors").classList.add("hidden-from-dom");
  }

  validateAndSubmit() {
    this.hideError();
    this.validate();
  }

  selectRecordType() {
    var tip = document.getElementById("record-type-tip");
    if (event.target.value == "TXT") {
      tip.innerHTML =
        "TXT Records will automatically be enclosed within quotation marks if you omit them to ensure they're valid. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#TXTFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "A") {
      tip.innerHTML =
        "Add multiple records at once on separate lines. A Records must be in IPv4 format (i.e. 192.168.1.1). <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#AFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "AAAA") {
      tip.innerHTML =
        "Add multiple records at once on separate lines. AAAA Records must be in IPv6 format (i.e. 2001:0db8:85a3:0:0:8a2e:0370:7334). <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#AAAAFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "MX") {
      tip.innerHTML =
        "MX records require a priority and a domain name that specifies a mail server, for example: 10 mailserver.example.com <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#MXFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "NS") {
      tip.innerHTML =
        "NS records require a domain name of a name server, for example: ns1.example.com. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#NSFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "PTR") {
      tip.innerHTML =
        "PTR records require the domain name that you want Route 53 to return. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#PTRFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "CAA") {
      tip.innerHTML =
        "CAA records specify which certificate authorities (CAs) are allowed to issue certificates for a domain or subdomain. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#CAAFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "CNAME") {
      tip.innerHTML =
        "CNAME records map DNS queries for the name of the current record to another domain or subdomain. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#CNAMEFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "NAPTR") {
      tip.innerHTML =
        "NAPTR records are used by DDDS applications to convert one value to another or to replace one value with another. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#NAPTRFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "SRV") {
      tip.innerHTML =
        "SRV records define the location (i.e., the hostname and port number) of servers for specified services. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#SRVFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "SPF") {
      tip.innerHTML =
        "SPF records were formerly used to verify the identity of the sender of email messages. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#SPFFormat' target='_blank'>Read more</a>";
    } else if (event.target.value == "SOA") {
      tip.innerHTML =
        "SOA records provide information about a domain and the corresponding Route53 hosted zone. <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html#SOAFormat' target='_blank'>Read more</a>";
    }
    this.updateAWSCLICommand();
  }

  updateValueLabel() {
    var match = /\r|\n/.exec($("#record-type-values").val());
    var label = document.getElementById("record-value-label");

    if (match && label.innerHTML != "Record Values:") {
      label.innerHTML = "Record Values:";
    } else if (!match && label.innerHTML != "Record Value:") {
      label.innerHTML = "Record Value:";
    }
    this.updateAWSCLICommand();
  }

  updateAWSCLICommand() {
    var aliased = document.getElementById("alias_toggle").checked;

    document.getElementById("aws-cli-record-name").innerHTML =
      document.getElementById("record-name-input").value;
    document.getElementById("aws-cli-record-type").innerHTML =
      document.getElementById("record-type-input").value;

    if (document.getElementById("record-type-ttl").value.length > 0) {
      document.getElementById("aws-cli-record-ttl").innerHTML =
        document.getElementById("record-type-ttl").value;
    } else {
      document.getElementById("aws-cli-record-ttl").innerHTML = "";
    }

    if (aliased) {
      $("#aws-cli-values-container").addClass("hidden-from-dom");
      $("#aws-cli-alias").html(
        '"AliasTarget":{ "HostedZoneId": "' +
          $("#record-alias-hosted-zone").val() +
          '", "DNSName": "' +
          $("#record-alias-dns-name").val() +
          '", "EvaluateTargetHealth": ' +
          document
            .getElementById("dns_record_alias_target_evaluate_target_health")
            .checked.toString() +
          "}"
      );
      $("#aws-cli-ttl-container").addClass("hidden-from-dom");
    } else {
      $("#aws-cli-values-container").removeClass("hidden-from-dom");
      $("#aws-cli-ttl-container").removeClass("hidden-from-dom");
      $("#aws-cli-alias").html("");
      // Values
      var match = /\r|\n/.exec(
        document.getElementById("record-type-values").value
      );
      if (match) {
        var values = document
          .getElementById("record-type-values")
          .value.split(/\r?\n/)
          .filter(function (el) {
            return el != "";
          });
        var value_string = [];
        for (var i = 0; i < values.length; i++) {
          value_string.push('{ "Value": "' + values[i] + '" }');
        }
        document.getElementById("aws-cli-record-values").innerHTML =
          value_string.join(", ");
      } else {
        document.getElementById("aws-cli-record-values").innerHTML =
          '{ "Value": "' +
          document.getElementById("record-type-values").value +
          '" }';
      }
    }

    // Set Identifier
    if (
      document.getElementById("record-type-routing-policy").value != "Simple" &&
      document.getElementById("record-set-identifier-input").value.length > 0
    ) {
      document.getElementById("aws-cli-record-set-identifier").innerHTML =
        ', "SetIdentifier": "' +
        document.getElementById("record-set-identifier-input").value +
        '"';
    } else {
      document.getElementById("aws-cli-record-weight").innerHTML = "";
      document.getElementById("aws-cli-record-set-identifier").innerHTML = "";
    }

    // Weight
    if (
      document.getElementById("record-type-routing-policy").value == "Weighted"
    ) {
      $("#aws-cli-record-weight").text(
        ', "Weight": ' + $("#record-weight-input").val()
      );
    } else {
      document.getElementById("aws-cli-record-weight").innerHTML = "";
    }

    // Latency
    if ($("#record-type-routing-policy").val() == "Latency") {
      $("#aws-cli-record-region").text(
        ', "Region": "' + $("#dns_record_latency").val() + '"'
      );
    } else {
      $("#aws-cli-record-region").text("");
    }

    // Multivalue
    if ($("#record-type-routing-policy").val() == "Multivalue Answer") {
      $("#aws-cli-record-multivalue-answer").text(', "MultiValueAnswer": true');
    } else {
      $("#aws-cli-record-multivalue-answer").text("");
    }
    // Failover
    if ($("#record-type-routing-policy").val() == "Failover") {
      $("#aws-cli-record-failover").text(
        ', "Failover": "' + $("#record-type-failover").val() + '"'
      );
    } else {
      $("#aws-cli-record-failover").text("");
    }

    // Set the action
    $("#aws-cli-record-action").text($("#dns-record-action").val());
  }

  toggleCLICommand() {
    var aws_cli_container = document.getElementById("aws-cli-command");

    if (event.target.innerHTML == "Display AWS CLI Command") {
      aws_cli_container.classList.remove("hidden-from-dom");
      event.target.innerHTML = "Hide AWS CLI Command";
    } else {
      aws_cli_container.classList.add("hidden-from-dom");
      event.target.innerHTML = "Display AWS CLI Command";
    }
  }

  addRecord() {
    if ($("#dns-record-action").val() == "CREATE") {
      return false;
    } else {
      $("#record-info").addClass("hidden-from-dom");
      $("#dns-record-action").val("CREATE");
      if (!$("#record-errors").hasClass("iam")) {
        $("#record-errors").addClass("hidden-from-dom");
      }
      $("#record-name-input").val("");
      $("#record-type-input").val("A");
      $("#record-type-values").val("");
      $("#record-type-ttl").val("");
      $("#record-type-routing-policy").val("Simple");
      $("#record-weight-input").val("");
      $("#record-modal-type").html("Add record set to");
      $("#record-set-submit-button").text("Create Record Set");
      $("#alias_toggle").prop("checked", false);
      $("#record-alias-dns-name").val("");
      $("#record-alias-hosted-zone").val("");
      if ($("#record-errors").hasClass("hidden-from-dom")) {
        $("#record-set-form-footer").removeClass("hidden-from-dom");
      }
      this.toggleAlias();
      this.selectRecordType();
      this.routingPoilicySelected();
    }
  }

  // AWS creates a NS and SOA record that cannot be edited. Inform the user if this is the case.
  potentiallyShowAWSNotice() {
    var record_type = $("#record-type-input").val();
    if (
      (record_type == "NS" || record_type == "SOA") &&
      "." + $("#record-name-input").val() == $("#hosted-zone-name").text()
    ) {
      $("#record-info").removeClass("hidden-from-dom");
      $("#record-set-form-footer").addClass("hidden-from-dom");
    } else {
      $("#record-info").addClass("hidden-from-dom");
      if ($("#record-errors").hasClass("hidden-from-dom")) {
        $("#record-set-form-footer").removeClass("hidden-from-dom");
      }
    }
  }

  disableForm() {
    $("#record-set-form-footer").addClass("hidden-from-dom");
  }

  editRecord() {
    event.preventDefault();
    var row = $($(event.target).parents("tr")[0]);

    $("#record-name-input").val(
      row
        .children("td")[0]
        .innerText.trim()
        .replace($("#hosted-zone-name").text(), "")
    );
    $("#record-type-input").val(row.children("td")[1].innerText.trim());
    $("#record-type-values").val(row.children("td")[2].innerText.trim());
    $("#record-type-ttl").val(row.children("td")[3].innerText.trim());
    if (!$("#record-errors").hasClass("iam")) {
      $("#record-errors").addClass("hidden-from-dom");
    }
    $("#record-modal-type").html("Edit existing record set for");
    $("#dns-record-action").val("UPSERT");
    $("#record-set-submit-button").text("Update Record Set");

    var routing_policy = row.children("td")[4].innerText.trim();
    if (routing_policy == "") {
      $("#record-type-routing-policy").val("Simple");
    } else {
      if (routing_policy.indexOf("Weight") >= 0) {
        $("#record-type-routing-policy").val("Weighted");
        $("#record-weight-input").val(
          $(row.children("td")[4]).find(".weight-value")[0].innerText
        );
        $("#record-set-identifier-input").val(
          $(row.children("td")[4]).find(".set-identifier-value")[0].innerText
        );
      } else if (routing_policy.indexOf("Failover") >= 0) {
        $("#record-type-routing-policy").val("Failover");
        $("#record-type-failover").val(
          $(row.children("td")[4]).find(".failover-value")[0].innerText
        );
        $("#record-set-identifier-input").val(
          $(row.children("td")[4]).find(".set-identifier-value")[0].innerText
        );
      } else if (routing_policy.indexOf("Region:") >= 0) {
        $("#record-type-routing-policy").val("Latency");
        $("#dns_record_latency").val(
          $(row.children("td")[4]).find(".latency-value")[0].innerText
        );
        $("#record-set-identifier-input").val(
          $(row.children("td")[4]).find(".set-identifier-value")[0].innerText
        );
      } else if (routing_policy.indexOf("Alias Details:") < 0) {
        $("#record-type-routing-policy").val("Multivalue Answer");
        $("#record-set-identifier-input").val(
          $(row.children("td")[4]).find(".set-identifier-value")[0].innerText
        );
      }
    }

    if (routing_policy.indexOf("Alias Details:") >= 0) {
      $("#alias_toggle").prop("checked", true);
      $("#record-alias-dns-name").val(
        $(row.children("td")[4]).find(".alias-dns-name")[0].innerText
      );
      $("#record-alias-hosted-zone").val(
        $(row.children("td")[4]).find(".alias-hosted-zone-id")[0].innerText
      );
      $("#dns_record_alias_target_evaluate_target_health").prop(
        "checked",
        $(row.children("td")[4]).find(".alias-evaluate-target-health")[0]
          .innerText == "true"
      );
    } else {
      $("#alias_toggle").prop("checked", false);
    }

    this.toggleAlias();
    this.updateValueLabel();
    this.routingPoilicySelected();
    this.selectRecordType();
    this.potentiallyShowAWSNotice();

    $("#recordSetModal").modal("show");
  }

  toggleAlias() {
    if (document.getElementById("alias_toggle").checked) {
      $("#record-value-container").addClass("hidden-from-dom");
      $("#record-alias-container").removeClass("hidden-from-dom");
    } else {
      $("#record-alias-container").addClass("hidden-from-dom");
      $("#record-value-container").removeClass("hidden-from-dom");
    }
    this.updateAWSCLICommand();
  }

  displayDeleteRecord() {
    event.preventDefault();
    var row = $($(event.target).parents("tr")[0]);
    var record_type = row.children("td")[1].innerText.trim();
    var record_name = row.children("td")[0].innerText.trim();

    if (
      (record_type == "NS" || record_type == "SOA") &&
      "." + record_name == $("#hosted-zone-name").text()
    ) {
      $("#delete_confirm_text").html(
        "You cannot remove the " + record_type + " record that Route53 manages."
      );
      $("#delete-record-set-footer").addClass("hidden-from-dom");
    } else {
      $("#delete-record-set-footer").removeClass("hidden-from-dom");
      $("#delete_confirm_text").html(
        "Are you sure you want to destroy your <b>" +
          record_type +
          "</b> record with the value of <b>" +
          row.children("td")[2].innerText.trim() +
          "</b>?"
      );
      $("#delete_dns_record_id").val(
        $($(event.target).parents(".dropdown-menu")[0]).attr("data-resource-id")
      );
      $("#delete_record_form").attr(
        "action",
        "/services/dns_record/resources/" +
          $($(event.target).parents(".dropdown-menu")[0]).attr(
            "data-resource-id"
          )
      );
    }

    $("#deleteRecordSetModal").modal("show");
  }

  submitDeleteRecord() {
    $("#delete_record_form").submit();
  }
}
