import { ProviderServiceRule } from "./provider-service-rule";

export class SubCategoryRule extends ProviderServiceRule {
  renderSelectServiceControl() {
    super.renderSelectServiceControl("sub_category");
  }

  fetchValues() {
    return super.fetchValues(
      this.reportUrl("sub_categories", {
        provider: this.selectedProvider,
        service: this.dataService,
      })
    );
  }
}
