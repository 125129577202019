import { Controller } from "@hotwired/stimulus";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import { createApp } from "vue";

export default class extends Controller {
  connect() {
    this.initializeVue();
  }

  get vueAppName() {
    const appName = this.element.getAttribute("data-vntg-vue-app");
    if (!appName) console.error("Missing data-vntg-vue-app attribute");

    return upperFirst(camelCase(appName));
  }

  get props() {
    return JSON.parse(this.element.getAttribute("data-props") || "{}");
  }

  initializeVue() {
    import(`../vue/views/${this.vueAppName}.vue`).then((module) => {
      const app = createApp(module.default, this.props);
      app.mount(this.element);
    });
  }
}
