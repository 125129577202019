import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["delete"];

  initialize() {
    $(this.element).modal("show");
  }

  dismiss() {
    $(this.element).modal("hide");

    if (this.hasDeleteTarget) {
      this.deleteTarget.click();
    }
  }
}
