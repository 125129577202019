import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output", "headers", "table", "container", "coverage"];

  initialize() {
    import("autonumeric").then((module) => {
      this.AutoNumeric = module.default;

      this.inputTargets.forEach((input) => {
        if (input.dataset.type == "gross") {
          input.addEventListener("keypress", this.validateInput.bind(this));
          input.addEventListener("blur", this.inputChanged.bind(this));
          this.makeEditable(input);
        }
      });

      this.containerTarget.style.width = `${this.tableTarget.offsetWidth + 190}px`;
      this.updateValues();
    });
  }

  set originalchartData(val) {
    this.data.set("originalchartData", val);
  }

  get originalchartData() {
    return this.data.get("originalchartData");
  }

  validateInput(evt) {
    // Prevent new lines by stopping enter/shift+enter and then blur.
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.target.blur();
    }
  }

  stripCurrency(str) {
    return parseFloat(str.replace(/[^0-9.-]+/g, ""));
  }

  inputChanged(evt) {
    let input = evt.target;
    let newValue = this.stripCurrency(input.textContent);
    let oldValue = input.dataset.value;

    if (newValue != oldValue) {
      input.dataset.value = newValue;
      input.dispatchEvent(new Event("change"));
      this.updateValues();
    }
  }

  updateValues() {
    let values = this.calculate();
    this.setValues(values);
  }

  makeEditable(cell) {
    cell.setAttribute("contenteditable", true);
    cell.innerText = cell.dataset.value;
    new this.AutoNumeric(cell, { currencySymbol: "$" });

    // Prevent changes from automatically propagating up to any parents.
    cell.addEventListener("change", (e) => {
      e.stopPropagation();
    });
  }

  calculate() {
    let months = this.parseValues();
    let results = {};

    Object.keys(months).forEach((month) => {
      results[month] = {};

      let values = months[month];
      let { gross, commitments, potentialSavings, reserved } = values;

      let totalCoverage = commitments + potentialSavings;
      let covered = gross <= totalCoverage ? gross : totalCoverage;

      let ondemand = gross - covered;
      let net = commitments + ondemand + reserved;

      let coveredPercentage;

      if (covered === 0) {
        coveredPercentage = 0;
      } else {
        coveredPercentage = covered / totalCoverage;

        if (coveredPercentage > 1) {
          coveredPercentage = 1;
        }
      }

      let savings = coveredPercentage * potentialSavings;
      let coverage = totalCoverage / gross;

      results[month]["gross"] = gross;
      results[month]["commitments"] = commitments;
      results[month]["covered"] = covered;
      results[month]["coverage"] = coverage;
      results[month]["savings"] = savings;
      results[month]["potentialSavings"] = potentialSavings;
      results[month]["net"] = net;
      results[month]["ondemand"] = ondemand;
      results[month]["reserved"] = reserved;
    });

    return results;
  }

  parseValues() {
    let months = {};

    this.inputTargets.forEach((input) => {
      let month = input.dataset.month;
      let value = input.dataset.value;
      let valueType = input.dataset.type;

      if (value) {
        if (!months[month]) {
          months[month] = {};
        }

        value = parseFloat(value);
        months[month][valueType] = parseFloat(value);
        input.dataset.originalValue = value;
      }
    });

    return months;
  }

  setValues(values) {
    let chartData = this.inputTargets.reduce(
      (obj, input) => {
        let month = input.dataset.month;
        let valueType = input.dataset.type;
        let value = values[month][valueType];
        let formatType = input.dataset.format;

        input.dataset.value = value;

        if (formatType == "currency") {
          value = this.formatCurrency(value);
        } else if (formatType == "percentage") {
          value = this.formatPercentage(value);
        }

        input.innerText = value;

        if (valueType === "ondemand") {
          obj.uncovered.push({ x: month, y: input.dataset.value });
        } else if (valueType === "covered") {
          obj.covered.push({ x: month, y: input.dataset.value });
        }

        return obj;
      },
      { covered: [], uncovered: [] }
    );

    this.updateOutput(chartData);
    this.updateStatusStyles();
  }

  get statusClasses() {
    return {
      poor: "tw-bg-red-50",
      good: "tw-bg-gray-50",
      great: "tw-bg-green-50",
    };
  }

  updateStatusStyles() {
    this.coverageTargets.forEach((el) => this.styleStatus(el));
  }

  styleStatus(el) {
    let value = parseFloat(el.dataset.value) * 100;
    let classes = this.statusClasses;
    let newStatus;

    if (value >= 100) {
      newStatus = classes.poor;
    } else if (value >= 80) {
      newStatus = classes.great;
    } else if (value >= 20) {
      newStatus = classes.good;
    } else {
      newStatus = classes.poor;
    }

    el.classList.remove(...Object.values(classes));
    el.classList.add(newStatus);
  }

  updateOutput(chartData) {
    if (!this.originalchartData) {
      this.originalchartData = this.outputTarget.dataset.barChartInputData;
    }

    let existingData = JSON.parse(this.originalchartData);
    let covered = existingData.covered.concat(chartData.covered);
    let uncovered = existingData.uncovered.concat(chartData.uncovered);

    this.outputTarget.dataset.barChartInputData = JSON.stringify({
      covered: covered,
      uncovered: uncovered,
    });
    this.outputTarget.dispatchEvent(new Event("change"));
  }

  formatCurrency(value) {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(value);
  }

  formatPercentage(value) {
    return value.toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
  }
}
