import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";

// handles the form functionality for the issues sidebar
export default class extends Controller {
  static targets = ["details", "display", "menu", "form", "item"];
  static values = {
    autoSubmit: { type: Boolean, default: false },
    unsavedChanges: { type: Boolean, default: false },
  };

  connect() {
    this.menuTarget.addEventListener(
      "details-menu-selected",
      this.handleMenuItemSelection.bind(this),
      false
    );
    document.addEventListener("click", this.handleClick.bind(this), false);
    document.addEventListener("keydown", this.handleKeydown.bind(this), false);

    // Force a preload of the content on page load.
    this.detailsTarget.dispatchEvent(new Event("mouseover"));
  }

  disconnect() {
    this.menuTarget.removeEventListener(
      "details-menu-selected",
      this.handleMenuItemSelection.bind(this),
      false
    );
    document.removeEventListener("click", this.handleClick.bind(this), false);
    document.removeEventListener(
      "keydown",
      this.handleKeydown.bind(this),
      false
    );
  }

  registerChanges(e) {
    this.unsavedChangesValue = true;
  }

  setDisplay(e) {
    this.displayTarget.innerHTML = "";
    let newItem = document.createElement("SPAN");
    newItem.className = "IssueSidebar-group-item--label small";
    if (e.target.dataset.displayPrepend) {
      newItem.innerHTML = e.target.dataset.displayPrepend;
    }

    if (e.target.dataset.type == "decimal") {
      let value = parseFloat(e.target.value);
      if (isNaN(value)) {
        value = 0;
      }
      newItem.innerHTML += value.toFixed(2);
    } else {
      newItem.innerHTML += e.target.value;
    }

    this.displayTarget.appendChild(newItem);
  }

  // private

  closeDetailsAndUpdate() {
    if (this.detailsTarget.hasAttribute("open")) {
      this.detailsTarget.removeAttribute("open");

      if (this.autoSubmitValue && this.unsavedChangesValue) {
        this.unsavedChangesValue = false;
        // safari doesn't support this.formTarget.requestSubmit() so invoke Turbo directly
        navigator.submitForm(this.formTarget);
      }
    }
  }

  handleMenuItemSelection(e) {
    this.unsavedChangesValue = true;
    this.displayTarget.innerHTML = "";
    for (let item of this.currentSelections) {
      let newItem = document.createElement("SPAN");
      newItem.className = "IssueSidebar-group-item--label small";
      newItem.innerHTML = item.template.map((p) => p.innerHTML).join("");
      this.displayTarget.appendChild(newItem);
    }
  }

  handleClick(e) {
    if (!this.detailsTarget.contains(e.target)) {
      this.closeDetailsAndUpdate();
    }
  }

  handleKeydown(e) {
    if (e.key === "Escape") {
      this.closeDetailsAndUpdate();
    }
  }

  get currentSelections() {
    return this.itemTargets
      .filter((item) => item.getAttribute("aria-checked") === "true")
      .sort((a, b) => a.dataset.text.localeCompare(b.dataset.text))
      .map((item) => {
        return {
          id: item.dataset.id,
          template: Array.from(item.children).filter(
            (p) => p.className === "modal-list-item--label"
          ),
        };
      });
  }
}
