import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output", "form", "toggle", "submit"];

  initialize() {
    this.toggleTarget.style.cursor = "pointer";
    this.submitTarget.style.cursor = "pointer";

    this.toggleTarget.addEventListener("click", this.toggle.bind(this));
    this.submitTarget.addEventListener("click", this.submit.bind(this));
  }

  toggle() {
    this.inputTarget.hidden = true;
    this.toggleTarget.hidden = true;
    this.outputTarget.hidden = false;
    this.submitTarget.hidden = false;
  }

  submit() {
    this.formTarget.submit();
  }
}
