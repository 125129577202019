import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["container", "formItem", "template", "form", "checkbox"];

  // use data-nested-form-insert-position to specify where in the container the content is inserted
  add(e) {
    e.preventDefault();

    const position = this.containerTarget.dataset.nestedFormInsertPosition || "beforeend";
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD_INDEX/g,
      new Date().getTime()
    );

    this.containerTarget.insertAdjacentHTML(position, content);
    this.containerTarget.dispatchEvent(
      new CustomEvent("nestedFormChange", { detail: { action: "add" }, bubbles: true })
    );
  }

  remove(e) {
    e.preventDefault();
    let el = e.target.closest("[data-nested-form-target='formItem']");

    if (e.params.softDelete) {
      el.querySelector("[data-nested-form-target='delete']").value = true;
      el.classList.add("tw-hidden");
    } else if (
      e.params.skipConfirmation ||
      confirm("Are you sure you want to delete this record?")
    ) {
      el.remove();
    }
    this.containerTarget.dispatchEvent(
      new CustomEvent("nestedFormChange", { detail: { action: "remove" }, bubbles: true })
    );
  }

  removeAndSubmit(e) {
    e.preventDefault();
    const targetId = e.params.targetId;
    const target = document.getElementById(targetId);

    if (target) {
      target.checked = true;
      navigator.submitForm(this.formTarget);
    }

    this.containerTarget.dispatchEvent(
      new CustomEvent("nestedFormChange", { detail: { action: "removeAndSubmit" }, bubbles: true })
    );
  }
}
