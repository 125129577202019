import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    showIconOnly: {
      type: Boolean,
      default: false,
    },
  };
  static targets = ["link", "buttonLabel", "buttonIcon"];

  initialize() {
    this.timeoutId = null;
    this.linkText = this.linkTarget.innerText;
    if (this.hasButtonLabelTarget) {
      this.buttonLabelText = this.buttonLabelTarget.innerText;
    }
    this.buttonIconText = this.buttonIconTarget.innerText;
  }

  copyLink(event) {
    event.stopPropagation();
    event.preventDefault();
    clearTimeout(this.timeoutId);

    navigator.clipboard.writeText(this.linkText);

    if (this.hasButtonLabelTarget) {
      this.buttonLabelTarget.innerText = this.showIconOnlyValue ? "" : "Copied!";
    }
    this.buttonIconTarget.innerText = "done";

    this.timeoutId = setTimeout(() => {
      if (this.hasButtonLabelTarget) {
        this.buttonLabelTarget.innerText = this.buttonLabelText;
      }
      this.buttonIconTarget.innerText = this.buttonIconText;
    }, 1000);
  }
}
