import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "title", "dropdown"];

  connect() {
    let controller = this;
    this.dirty = false;

    this.itemTargets.forEach((item) => {
      item.addEventListener("change", (e) => {
        controller.dirty = true;
        controller.update();
        e.stopPropagation();
        e.preventDefault();
      });
    });

    // Prevents clicking on form labels from closing the dropdown.
    $(this.dropdownTarget).on("click.bs.dropdown", ".dropdown-menu", (e) => {
      e.stopPropagation();
    });

    $(this.dropdownTarget).on("hide.bs.dropdown", this.hidden.bind(this));
    this.update();
  }

  hidden(e) {
    if (this.dirty) {
      this.triggerchange();
    }
  }

  update() {
    let count = this.dropdownTarget.querySelectorAll(
      'input[type="checkbox"]:checked'
    ).length;

    if (count == 1 && this.title.endsWith("s")) {
      this.title = `${count} ${this.title.slice(0, -1)}`;
    } else if (count > 0) {
      this.title = `${count} ${this.title}`;
    } else {
      this.title = "All " + this.title;
    }
  }

  triggerchange() {
    let form = this.itemTargets[0].form;
    form.dispatchEvent(new Event("change"));
    this.dirty = false;
  }

  selectAll(event) {
    if (event) {
      event.preventDefault();
    }

    this.itemTargets.forEach((item) => {
      item.checked = false;
      item.dispatchEvent(new Event("change"));
    });

    this.title = "All " + this.title;

    $(this.dropdownTarget).find(".dropdown-toggle").dropdown("toggle");
  }

  get title() {
    return this.data.get("title");
  }

  set title(value) {
    this.titleTarget.innerHTML = value;
  }

  set dirty(value) {
    this.dropdownTarget.dataset.dirty = value;
  }

  get dirty() {
    return this.dropdownTarget.dataset.dirty === "true";
  }
}
