import { Controller } from "@hotwired/stimulus";

// DEPRECATED -- use stripe_trial_controller.js
export default class extends Controller {
  static targets = ["subscriptionForm", "addPaymentMethodButton"];

  get key() {
    return this.data.get("key");
  }

  connect() {
    this.displaySubscriptionForm();
  }

  displaySubscriptionForm() {
    let stripe = Stripe(this.key);
    let elements = stripe.elements();

    let style = {
      base: {
        color: "#495057",
        fontFamily: '"Inter", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#6C757D",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    let card = elements.create("card", { style: style });
    card.mount("#card-element");

    card.on("change", function (event) {
      let displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
        displayError.classList.remove("hidden-from-dom");
      } else {
        displayError.classList.add("hidden-from-dom");
        displayError.textContent = "";
      }
    });

    let payment_method_form = document.getElementById(
      "add-payment-method-form"
    );

    payment_method_form.addEventListener("submit", (ev) => {
      ev.preventDefault();
      this.createPaymentMethod(stripe, card);
    });
  }

  submitWithoutConfirmation() {
    let button = this.addPaymentMethodButtonTarget;
    button.innerText = "Saving...";
    button.disabled = true;
    this.subscriptionFormTarget.submit();
  }

  createPaymentMethod(stripe, card) {
    let billingName = document.querySelector("#cardholder-name").value;
    let authToken = document.querySelector("#stripe-authenticity-token").value;

    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          name: billingName,
        },
      })
      .then((result) => {
        if (result.error) {
          let displayError = document.getElementById("card-errors");
          displayError.textContent = result.error.message;
        } else {
          $("#add-payment-submit-button").text("Saving...");
          fetch("/subscriptions/add_payment_method", {
            method: "post",
            credentials: "same-origin",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              authenticity_token: authToken,
              paymentMethodId: result.paymentMethod.id,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              // The card had an error when trying to attach it to a customer. Inform them.
              if (result.error) {
                document.getElementById("payment-error").textContent =
                  result.error.message;
                $("#payment-error").removeClass("hidden-from-dom");
              } else {
                this.submitWithoutConfirmation();
              }
              return result;
            })
            .catch((error) => {
              document.getElementById("payment-error").textContent =
                error.message;
              $("#payment-error").removeClass("hidden-from-dom");
            });
        }
      });
  }
}
