import { Controller } from "@hotwired/stimulus";

import "chartjs-adapter-moment";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

export default class extends Controller {
  static targets = ["output", "legendTemplate", "legend"];

  initialize() {
    this.generateChart();
  }

  updateChart(evt) {
    this.generateChart();
  }

  generateChart() {
    let datasets = this.generateData();
    let options = this.chartOptions(datasets);

    if (this.chart) {
      this.chart.destroy();
    }

    new Chart(this.outputTarget.getContext("2d"), options);

    if (this.hasLegendTarget && this.externalLegend) {
      this.renderLegend();
    }
  }

  get legendTemplate() {
    return this.legendTemplateTarget.content.cloneNode(true);
  }

  get externalLegend() {
    return this.data.get("legend") === "external";
  }

  get chart() {
    return Chart.getChart(this.outputTarget);
  }

  get inputData() {
    return JSON.parse(this.data.get("input-data"));
  }

  get lineTarget() {
    return this.data.get("line-target") ? JSON.parse(this.data.get("line-target")) : null;
  }

  get stacked() {
    return (this.data.get("stacked") || "true") === "true";
  }

  get lineLabel() {
    return this.data.get("line-label");
  }

  get showYAxis() {
    return (this.data.get("yaxis") || "true") === "true";
  }

  get showSecondYAxis() {
    return this.secondAxisLabels.length > 0;
  }

  get showXAxis() {
    return (this.data.get("xaxis") || "true") === "true";
  }

  get showTooltip() {
    return (this.data.get("tooltip") || "true") === "true";
  }

  get startDate() {
    return moment(this.data.get("start-date"));
  }

  get endDate() {
    return moment(this.data.get("end-date"));
  }

  get order() {
    return JSON.parse(this.data.get("order") || "[]");
  }

  get bin() {
    return this.data.get("bin") || "hour";
  }

  get chartType() {
    return this.data.get("chart-type") || "line";
  }

  get currency() {
    return this.data.get("value-type") === "currency";
  }

  get labelMap() {
    let json = this.data.get("label-map");

    if (json) {
      return JSON.parse(json);
    } else {
      return {};
    }
  }

  get secondAxisLabels() {
    return JSON.parse(this.data.get("second-axis-labels") || "[]");
  }

  get colorMap() {
    let json = this.data.get("color-map");

    if (json) {
      return JSON.parse(json);
    } else {
      return {};
    }
  }

  sortByDate(data) {
    return data
      .filter((d) => d)
      .sort((a, b) => {
        return moment(a.x) - moment(b.x);
      });
  }

  mappedLabel(val) {
    return this.labelMap[val] || val;
  }

  generateData() {
    const colors = ["#70D9C6", "#32B19B", "#FFC928", "#FF9877", "#91E4FB", "#5CB1FF"];

    let data;
    if (Array.isArray(this.inputData)) {
      data = {
        "": this.inputData,
      };
    } else {
      data = this.inputData;
    }

    let orderedKeys = this.order.length > 0 ? this.order : Object.keys(data).sort();

    let dataSets = Object.keys(data).map((key) => {
      let color = this.colorMap[key] || colors[orderedKeys.indexOf(key)];
      let backgroundColors = [];

      let datum = this.sortByDate(data[key]).map((d) => {
        d.category = key;
        backgroundColors.push(color);
        return d;
      });

      const fill = this.stacked ? "origin" : null;

      let styles = {
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        fill: fill,
        pointRadius: 0,
      };

      let yAxisID = this.yAxisIdForLabel(key);

      return {
        label: key,
        data: datum,
        type: this.chartType,
        stack: "all",
        order: orderedKeys.indexOf(key),
        yAxisID: yAxisID,
        ...styles,
      };
    });

    return dataSets;
  }

  yAxisIdForLabel(label) {
    return this.secondAxisLabels.includes(label) ? "y1" : "y";
  }

  getLocaleDateFormat() {
    const formatObj = new Intl.DateTimeFormat().formatToParts(new Date());

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  renderLegend() {
    let legendLabels = this.chart.options.plugins.legend.labels.generateLabels(this.chart);
    this.legendTarget.innerHTML = "";

    legendLabels
      .filter((l) => !l.hidden)
      .sort((a, b) => a.text.localeCompare(b.text))
      .forEach((label) => {
        const labelElement = this.legendTemplate;
        const labelTitle = labelElement.querySelector(".title");
        const mappedLabel = this.mappedLabel(label.text);

        labelElement.querySelector(".color").style.backgroundColor = label.fillStyle;
        labelTitle.innerHTML = mappedLabel;
        labelTitle.setAttribute("title", mappedLabel);
        this.legendTarget.append(labelElement);
      });

    if (this.lineTarget != null && this.lineLabel) {
      let labelElement = this.legendTemplate;
      labelElement.querySelector(".title").innerHTML = this.lineLabel;
      labelElement.querySelector(".color").style.backgroundColor = "#000";
      labelElement.querySelector(".color").classList.add("line");
      this.legendTarget.append(labelElement);
    }
  }

  chartOptions(datasets) {
    let showLegend = datasets.length > 1 && !this.externalLegend;
    let _this = this;

    return {
      data: { datasets: datasets },
      plugins: [
        {
          afterDraw: (chart) => {
            if (this.lineTarget != null) {
              const ctx = chart.ctx;
              ctx.save();
              const xAxis = chart.scales["x"];
              const yAxis = chart.scales["y"];
              const y = yAxis.getPixelForValue(this.lineTarget);
              ctx.beginPath();
              ctx.moveTo(xAxis.left, y);
              ctx.lineTo(xAxis.right, y);
              ctx.lineWidth = 1;
              ctx.strokeStyle = "#000";
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ],
      options: {
        interaction: {
          mode: "nearest",
          intersect: false,
          axis: "x",
        },
        categoryPercentage: 0.25,
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: showLegend,
            position: "top",
            align: "start",
          },
          tooltip: {
            enabled: this.showTooltip,
            mode: "nearest",
            intersect: false,
            usePointStyle: true,
            padding: 20,
            bodySpacing: 10,
            itemSort: (a, b, data) => b.dataset.order - a.dataset.order,
            callbacks: {
              title: () => {
                "";
              },
              label: (context) => {
                let label = this.mappedLabel(context.dataset.label);
                if (!label) {
                  return false;
                }

                let value = context.parsed.y;
                if (this.currency) {
                  value = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(context.parsed.y);
                }

                let date = context.raw.originalX || context.raw.x;
                date = moment(date).format(this.getLocaleDateFormat());

                return ` ${label} - ${date} : ${value}`;
              },
            },
          },
        },
        scales: {
          x: {
            display: this.showXAxis,
            grid: {
              display: false,
            },
            time: {
              unit: this.bin,
              displayFormats: {
                day: this.getLocaleDateFormat(),
              },
            },
            type: "time",
            min: this.startDate,
            max: this.endDate,
          },
          y: {
            display: this.showYAxis,
            min: 0,
            grace: "5%",
            stacked: this.stacked,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              precision: 0,
              callback: function (value, index, values) {
                if (_this.currency) {
                  return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value);
                } else {
                  return value;
                }
              },
            },
          },
          y1: {
            display: this.showSecondYAxis,
            min: 0,
            grace: "5%",
            stacked: this.stacked,
            position: "right",
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              precision: 0,
              callback: function (value, index, values) {
                if (_this.currency) {
                  return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value);
                } else {
                  return value;
                }
              },
            },
          },
        },
      },
    };
  }
}
