import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["turboFrame", "frameContainer", "toggleButton"];
  static values = {
    src: {
      type: String,
      required: true,
    },
    escapeEvent: {
      type: Boolean,
      required: false,
    },
  };

  initialize() {
    this.loaded = false;
    this.initializeEscapeEvent();
  }

  toggle() {
    this.frameContainerTarget.hidden = !this.frameContainerTarget.hidden;
    if (!this.loaded && !this.frameContainerTarget.hidden) {
      this.turboFrameTarget.src = this.srcValue;
    }

    if (this.hasToggleButtonTarget) {
      this.toggleButtonTarget.classList.toggle("active");
    }
    this.loaded = true;
  }

  initializeEscapeEvent() {
    if (!this.escapeEventValue) return;

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && !this.frameContainerTarget.hidden) {
        this.toggle();
      }
    });
  }
}
