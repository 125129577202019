import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  toggleContent(e) {
    e.preventDefault();

    this.contentTargets.forEach((target) => {
      target.hidden = !target.hidden;
    });
  }
}
